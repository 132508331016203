
export const filterTerms = [
    {
      id: 1,
      name: "Last 7 days",
      value: "last7days",
    },
    {
      id: 2,
      name: "Last month",
      value: "lastmonth",
    },
    {
      id: 3,
      name: "This year",
      value: "thisyear",
    },
    {
      id: 4,
      name: "Last year",
      value: "lastyear",
    },
    {
      id: 5,
      name: "Custom",
      value: "custom",
    },
];
export const dateRangeType: any = [
  { id: 1, name: "Last 7 days", value: "last7days" },
  { id: 2, name: "Last month", value: "lastmonth" },
  { id: 3, name: "This year", value: "thisyear" },
  { id: 4, name: "Last year", value: "lastyear" },
  { id: 5, name: "Custom date range", value: "custom" },
];
export const shipmentNavTabTitle =[
  {
    id: 1,
    name: "Add",
  },
  {
    id: 2,
    name: "Upload",
  },
  {
    id: 3,
    name: "Payment",
  },
]
export const deliveryType: any = [
  { id: 1, name: "Local (within Nigeria)" },
  { id: 2, name: "International - Sending to Nigeria (Import)" },
  { id: 3, name: "International - Sending from Nigeria (Export)" },
];
export const bulkDeliveryType: any = [
  { id: 1, name: "Local" },
  { id: 2, name: "International - Sending to Nigeria (Import)" },
  { id: 3, name: "International - Sending from Nigeria (Export)" },
];
export const deliveryModes = [
  { id: 1, name: "Bike" },
  { id: 2, name: "Van" },
];

export const sortType =[
  { id: 1, name: "Name" },
  { id: 2, name: "Email" },
  { id: 3, name: "Role" },
  { id: 4, name: "User ID" },
  { id: 5, name: "Status" },
]
export const excelSample =[
  { id: 1, name: "Local" , data:"https://res.cloudinary.com/dxhgszxvl/raw/upload/v1729067677/FEZ_Business_Portal_Template_V5_2_nxfith.xlsx"},
  { id: 2, name: "International - Sending to Nigeria (Import)", data:"https://res.cloudinary.com/dxhgszxvl/raw/upload/v1730276235/FEZ_Business_Portal_Template_Import_V1_rewyak.xlsx" },
  { id: 3, name: "International - Sending from Nigeria (Export)", data:"https://res.cloudinary.com/dxhgszxvl/raw/upload/v1730538954/FEZ_Business_Portal_Template_Export_V1_1_ynhx4v.xlsx"},
]

export const filterType: any = [
  { id: 1, name: "Order status" },
  { id: 2, name: "Recipient name" },
  { id: 3, name: "Recipient phone" },
  { id: 4, name: "Recipient email" },
];



export const orderStatusList: any = [
  { id: 1, name: "Prepared For Delivery" },
  { id: 2, name: "Delivery In Progress" },
  { id: 3, name: "Delivered" },
  { id: 4, name: "Pending Pick-Up" },
  { id: 5, name: "Picked-Up" },
  { id: 6, name: "In Transit Back to you" },
  { id: 7, name: "Returned" },
];