import React from "react";

interface ModalContainerProps {
  children: React.ReactNode;
  open: boolean;
  closeModal?: () => void;
  tailwindClassName?: string;
  modalClassName?: string;
  showCloseIcon?: boolean;
  noPadding?: boolean;
  showBg?: boolean;
  modalRef?: React.RefObject<HTMLDivElement>;
}

const FezWrapModalMobile = ({
  children,
  open,
  closeModal,
  tailwindClassName,
  noPadding,
  showCloseIcon,
  modalRef,
  showBg,
}: ModalContainerProps) => {
  return open ? (
      <div className="overflow-y-auto md:hidden flex overflow-x-hidden w-[100%] justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0   right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
        <div className="flex h-screen w-screen">{children}</div>
      </div>
  ) : null;
};

export default FezWrapModalMobile;
