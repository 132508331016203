import moment from "moment";

export const getDateRange = (period: string): { startDate: string; endDate: string } => {
  let startDate = "";
  let endDate = ""
  switch (period) {
    case "last7days":
      startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
      endDate = moment().add(1, "days").format("YYYY-MM-DD");
      break;
    case "lastmonth":
      startDate = moment().subtract(1, "months").startOf("months").format("YYYY-MM-DD");
      endDate = moment().subtract(1, "months").endOf("months").format("YYYY-MM-DD");
      break;
    case "thisyear":
      startDate = moment().startOf("year").format("YYYY-MM-DD");
      endDate = moment().endOf("year").format("YYYY-MM-DD");
      break;
    case "lastyear":
      startDate = moment().subtract(1, 'year').startOf("year").format("YYYY-MM-DD");
      endDate = moment().subtract(1, 'year').endOf("year").format("YYYY-MM-DD");
      break;
    default:
      startDate = "";
      endDate = "";
  }

  return { startDate, endDate };
};
