import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface Order {
    startDate: string;
    endDate: string;
    disable: string,
    invoiceNumber: string
    page:number
    orderNo:string
  }
  interface Invoice {
    startDate: string;
    endDate: string;
    search: string,
    paymentStatus: string
    page?:number
  }
interface Props {
  id: string,
}
interface InvoiceWallet{
    "paymentReference": string,
    "totalCost": number,
    "invoiceNumber": string
}
export const invoice = createApi({
  reducerPath: "invoice",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("token");
      const secretKey = localStorage.getItem("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllInvoices: builder.query<any, Invoice>({
      query: ({ page,startDate, endDate, search, paymentStatus }) => ({
        url: `invoices?${page ? `page=${page}&` : ""}${search ? `search=${search}&` : ""}${startDate ? `startDate=${startDate}&` : ""}${endDate ? `endDate=${endDate}&` : ""}${paymentStatus ? `paymentStatus=${paymentStatus}` : ""}`,
        method: 'GET',
      }),
    }),
    getInvoicesStats: builder.query<any, Invoice>({
      query: ({ page,startDate, endDate, search, paymentStatus }) => ({
        url: `invoices?${page ? `page=${page}&` : ""}${search ? `search=${search}&` : ""}${startDate ? `startDate=${startDate}&` : ""}${endDate ? `endDate=${endDate}&` : ""}${paymentStatus ? `paymentStatus=${paymentStatus}` : ""}`,
        method: 'GET',
      }),
    }),
    getAllInvoiceTransaction: builder.query<any, Invoice>({
      query: ({ page, startDate, endDate, search, paymentStatus }) => ({
        url: `invoices/transactions?${page ? `page=${page}&` : ""}${search ? `search=${search}&` : ""}${startDate ? `startDate=${startDate}&` : ""}${endDate ? `endDate=${endDate}&` : ""}${paymentStatus ? `paymentStatus=${paymentStatus}` : ""}`,
        method: 'GET',
      }),
    }),
    getInvoiceTransactionStats: builder.query<any, Invoice>({
      query: ({ page, startDate, endDate, search, paymentStatus }) => ({
        url: `invoices/transactions?${page ? `page=${page}&` : ""}${search ? `search=${search}&` : ""}${startDate ? `startDate=${startDate}&` : ""}${endDate ? `endDate=${endDate}&` : ""}${paymentStatus ? `paymentStatus=${paymentStatus}` : ""}`,
        method: 'GET',
      }),
    }),
      getInvoiceByDocNumber: builder.query<any, Props>({
        query: ({id}) => ({
          url: `invoices/${id}`,
          method: 'GET',
        }),
      }),
      getInvoicePDF: builder.query<any, Props>({
        query: ({id}) => ({
          url: `invoices/${id}/pdf`,
          method: 'GET',
        }),
      }),
      getInvoiceOrders: builder.mutation<any, Order>({
        query: (body) => ({
          url: `/orders/clusterSearch`,
          method: "POST",
          body,
        }),
      }),
      getInvoiceWalletCheckout: builder.mutation<any, InvoiceWallet>({
        query: (body) => ({
          url: `/wallets/invoice/checkout`,
          method: "POST",
          body,
        }),
      }),
  }),
});

export const {
  useGetAllInvoicesQuery,
  useGetAllInvoiceTransactionQuery,
  useGetInvoiceByDocNumberQuery,
  useGetInvoicePDFQuery,
  useGetInvoiceOrdersMutation,
  useGetInvoiceWalletCheckoutMutation,
  useGetInvoiceTransactionStatsQuery,
  useGetInvoicesStatsQuery
} = invoice;
