import React from "react";
import ShipmentProgress from "../ShipmentProgress";
import { IoIosClose } from "react-icons/io";
import { TextInput } from "components/common/form/Input";
import Dropdown from "components/common/form/SelectDropdown";
import { formatMoney } from "helpers/formatMoney";
import { Button } from "components/Button";
import { shipmentNavTabTitle } from "constant/data";
import { FaCircle } from "react-icons/fa6";
import { TextArea } from "components/common/form/textArea";
import {
  ImportEditableState,
  ImportUser,
  User,
} from "pages/dashboard/ship-now/BulkShipment";
import getSymbolFromCurrency from "currency-symbol-map";

interface TargetEvent {
  target: {
    name: string;
    value: string;
  };
}
interface Props {
  setIsBulkUpload: React.Dispatch<React.SetStateAction<boolean>>;
  setBulkTableData: any;
  users: ImportUser[];
  result: any;
  handleGoToError: () => void;
  tableContainerRef: React.RefObject<HTMLDivElement>;
  editable: ImportEditableState;
  errorRefs: React.MutableRefObject<{
    [key: string]: HTMLTableCellElement | null;
  }>;
  handleChange: (id: number, field: keyof ImportUser, value: string) => void;
  formErrors: any;
  handleEditClick: any;
  handleBlur: any;
  searchDeliveryZone: string;
  setSearchDeliveryZone: React.Dispatch<React.SetStateAction<string>>;
  filteredDeliveryZoneSearch: any;
  setFormErrors: React.Dispatch<React.SetStateAction<any>>;
  searchPickUpZone: string;
  setSearchPickUpZone: React.Dispatch<React.SetStateAction<string>>;
  filteredPickUpZoneSearch: any;
  filteredItemSearch: any;
  isItemLoading:boolean;
  setItemName: React.Dispatch<React.SetStateAction<string>>;
  onGenerate: () => void;
  setSearchItem: React.Dispatch<React.SetStateAction<string>>;

  itemName: string;
  searchItem: string;
  setShowPayment: any;
  handleClick: () => void;
  isEditOrder: boolean;
  onEditOrder: (e: any) => void;
  handleDelete: (id: number) => void;
  setIsEditOrder: React.Dispatch<React.SetStateAction<boolean>>;
  editDetails: User | any;
  setEditDetails: React.Dispatch<React.SetStateAction<ImportUser | any>>;
  noPayment: boolean;
  loading: boolean;
  currencyValue: string;
  handleDeliveryChange: (e: TargetEvent, field: string) => void;
}

const BulkImportUpload = ({
  setIsBulkUpload,
  setBulkTableData,
  users,
  result,
  handleGoToError,
  tableContainerRef,
  editable,
  setSearchItem,
  searchItem,
  errorRefs,
  setEditDetails,
  handleChange,
  isItemLoading,
  formErrors,
  handleEditClick,
  handleBlur,
  itemName,
  searchDeliveryZone,
  setSearchDeliveryZone,
  filteredDeliveryZoneSearch,
  setFormErrors,
  filteredItemSearch,
  onGenerate,
  setShowPayment,
  handleClick,
  isEditOrder,
  onEditOrder,
  handleDelete,
  setIsEditOrder,
  editDetails,
  noPayment,
  setItemName,
  handleDeliveryChange,
  currencyValue,
  loading,
}: Props) => {
  return (
    <div>
      <div className="hidden  md:block mx-5 overflow-y-hidden">
        <div className="mt-7 relative pb-14 sm:mt-[72px] rounded-lg max-w-screen-lg mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
          <div className="w-full px-4 sm:px-10 py-6 sm:py-8 onestBold">
            <ShipmentProgress
              handleClick={() => {
                setIsBulkUpload(true);
                setBulkTableData(false);
              }}
              add={`add-gradient`}
              upload="add-gradient"
              payment="[#e0f4dc]"
            />
            <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
              Your bulk shipments
            </h1>
            <p className="text-[#4a564d] text-[13px] font-[500]">
              International - Sending to Nigeria (Import)
            </p>

            <div className="bg-[#F9F9FB] pb-4 mt-10 border-[1px] border-[#E4EBF5] rounded-lg">
              <div className="flex  bg-[#F9F9FB] justify-between  pt-4  ">
                <div className="flex text-xs text-[#4A564D] font-[600] gap-3 mb-3 ml-2">
                  <p className="">{users.length} shipments uploaded</p>
                  <div className="flex items-center gap-1">
                    <img src="/images/check-mark.svg" alt="" />
                    <p className="text-[#18AF04]">
                      {result.rowValidCount} correct shipment
                    </p>
                  </div>
                  <div
                    className="flex items-center gap-0 cursor-pointer"
                    onClick={handleGoToError}
                  >
                    <IoIosClose color={"#F64108"} size={18} />
                    <p className="text-[#F64108]">
                      {result.rowInvalidCount} incorrect shipment
                    </p>
                  </div>
                </div>

                <p className="text-xs text-[#4A564D] font-[600] flex gap-1.5 mr-[50px] ">
                  <span className="text-[#929DAE]"> Scroll right </span>
                  <div className="mt-.05">
                    {" "}
                    <img src="/images/forward-arrow.svg" alt="" />
                  </div>
                </p>
              </div>
              <div
                ref={tableContainerRef}
                className="overflow-x-auto h-[500px]  relative"
              >
                <table className="whitespace-nowrap overflow-y-auto absolute text-left  border-spacing-2 rounded-2xl w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                  <thead className="bg-white !mx-12 sticky top-0 z-10 border-b-[1px] border-[#E4EBF5]">
                    <tr className="">
                      <th className="py-3 pl-4 pr-4 border-r-[1px] border-[#E4EBF5] text-xs text-[#929DAE] font-onestRegular">
                        Recipient name
                      </th>
                      <th className="py-3 text-xs pl-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Recipient Address
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Recipient State
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Recipient Phone
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Alternate Phone No.
                      </th>
                      <th className="py-3 text-xs pl-4 pr-20 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Recipient Email
                      </th>
                      <th className="py-3 text-xs pl-4 pr-20 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Delivery City
                      </th>
                      <th className="py-3 text-xs pl-4 pr-20 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Delivery Landmark
                      </th>
                      <th className="py-3 text-xs pl-4 pr-20 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        LGA
                      </th>

                      <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Tracking ID
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Value of Item
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Item category
                      </th>
                      <th className="py-3 text-xs pl-4 pr-10 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Cost
                      </th>
                      <th className="py-3 text-xs pl-4 pr-10 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Discounted Cost
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Item weight
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Quantity
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Additional info
                      </th>
                      <th className="py-3 sticky right-0 text-xs bg-white pl-4 pr-10 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody className="overflow-y-auto max-h-[500px]">
                    {users.map((item: any, idx: number) => {
                      
                      return (
                        <tr
                          key={item.id}
                          className="text-sm text-[#161718]  w-full font-[400] border-b-[1px] border-[#E4EBF5]"
                        >
                          <td
                            ref={(el) => {
                              if (!item.nameValid) errorRefs.current.name = el;
                            }}
                            className={`${
                              item.nameValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[600]"
                            } py-5 text-xs pl-3 pr-6 border-l-[#fff]  border-[1px] border-[#E4EBF5]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "name" ? (
                              <>
                                <input
                                  name="name"
                                  value={item.name}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                  // onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.name && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.name}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {item.name}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "name")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.addressValid)
                                errorRefs.current.address = el;
                            }}
                            className={` !w-[500px] whitespace-normal ${
                              item.addressValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            } py-6 pl-4 flex gap-x-1.5 pr-4 border-r-[1px] border-[#E4EBF5]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "address" ? (
                              <>
                                <input
                                  name="address"
                                  value={item.address}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "address",
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.address && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.address}
                                  </span>
                                )}
                              </>
                            ) : (
                              <div className="flex gap-1">
                                <span className="text-xs break-all">
                                  {item.address}
                                </span>
                                <img
                                  className="w-2.5 h-2.5 cursor-pointer"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "address")
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.zoneValid) errorRefs.current.zone = el;
                            }}
                            className={` !w-[900px] ${
                              item.zoneValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[900]"
                            } py-4 pl-4 text-sx border-l-[#fff] border-[1px] border-[#E4EBF5] w-[300px] px-4`}
                          >
                            {editable.id === item.id &&
                            editable.field === "zone" ? (
                              <div className="absolute -mt-5">
                                <Dropdown
                                  dropdown={
                                    <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                      <div className="flex items-center space-x-1">
                                        <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                        <span
                                          className={` ${
                                            item.zone === ""
                                              ? "text-[#9899A1]"
                                              : "text-[#000]"
                                          }  font-onestMedium text-sm`}
                                        >
                                          {item.zone === "" ||
                                          item.zone === undefined
                                            ? "Select Drop-off state"
                                            : item.zone}
                                        </span>
                                      </div>

                                      <img
                                        src={"/images/chevronDown.svg"}
                                        alt="drop-down"
                                        className="mt-0.5 pr-2 cursor-pointer"
                                      />
                                    </div>
                                  }
                                  search={
                                    <div>
                                      <TextInput
                                        value={searchDeliveryZone}
                                        name={"search"}
                                        type={"text"}
                                        placeholder="Search"
                                        onChange={(e) => {
                                          setSearchDeliveryZone(e.target.value);
                                        }}
                                      />
                                    </div>
                                  }
                                  dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                                  dropdownOptions={[
                                    {
                                      customChild: (
                                        <div>
                                          <div>
                                            {filteredDeliveryZoneSearch?.map(
                                              (stateItem: any, idx: number) => {
                                                return (
                                                  <div
                                                    key={idx}
                                                    onClick={() => {
                                                      handleChange(
                                                        item.id,
                                                        "zone",
                                                        stateItem.state
                                                      );

                                                      setFormErrors(
                                                        (prev: any) => ({
                                                          ...prev,
                                                          zone: "",
                                                        })
                                                      );
                                                    }}
                                                  >
                                                    <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                                      <p>{stateItem?.state}</p>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            ) : (
                              <div className="flex w-[200px] items-center">
                                <div className="">{item.zone} </div>
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "zone")
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.phoneValid)
                                errorRefs.current.phone = el;
                            }}
                            className={`${
                              item.phoneValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[900]"
                            } py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] w-[300px]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "phone" ? (
                              <>
                                <input
                                type="number"

                                  value={item.phone}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "phone",
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.phone && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.phone}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {item.phone}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "phone")
                                  }
                                />
                              </>
                            )}
                          </td>

                          <td
                            ref={(el) => {
                              if (!item.altPhoneValid)
                                errorRefs.current.altPhone = el;
                            }}
                            className={`${
                              item.altPhoneValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[900]"
                            } py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] w-[300px]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "altPhone" ? (
                              <>
                                <input
                                type="number"

                                  value={item.altPhone}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "altPhone",
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.altPhone && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.altPhone}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {item.altPhone}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "altPhone")
                                  }
                                />
                              </>
                            )}
                          </td>

                          <td
                            ref={(el) => {
                              if (!item.emailValid)
                                errorRefs.current.email = el;
                            }}
                            className={`py-4 pl-4 pr-6 !w-[150px]  text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] ${
                              item.emailValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            }`}
                          >
                            {editable.id === item.id &&
                            editable.field === "email" ? (
                              <input
                                value={item.email}
                                onChange={(e) =>
                                  handleChange(item.id, "email", e.target.value)
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2"
                              />
                            ) : (
                              <>
                                {item.email}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "email")
                                  }
                                />
                              </>
                            )}
                          </td>

                          <td
                            ref={(el) => {
                              if (!item.deliveryCityValid)
                                errorRefs.current.deliveryCity = el;
                            }}
                            className={`py-4 pl-4 pr-6 !w-[150px]  text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] ${
                              item.deliveryCityValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            }`}
                          >
                            {editable.id === item.id &&
                            editable.field === "deliveryCity" ? (
                              <input
                                value={item.deliveryCity}
                                onChange={(e) =>
                                  handleChange(
                                    item.id,
                                    "deliveryCity",
                                    e.target.value
                                  )
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2"
                              />
                            ) : (
                              <>
                                {item.deliveryCity}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "deliveryCity")
                                  }
                                />
                              </>
                            )}
                          </td>

                          <td
                            ref={(el) => {
                              if (!item.landMarkValid)
                                errorRefs.current.landMark = el;
                            }}
                            className={`py-4 pl-4 pr-6 !w-[150px]  text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] ${
                              item.landMarkValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            }`}
                          >
                            {editable.id === item.id &&
                            editable.field === "landMark" ? (
                              <input
                                value={item.landMark}
                                onChange={(e) =>
                                  handleChange(
                                    item.id,
                                    "landMark",
                                    e.target.value
                                  )
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2"
                              />
                            ) : (
                              <>
                                {item.landMark}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "landMark")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.lgaValid) errorRefs.current.lga = el;
                            }}
                            className={`py-4 pl-4 pr-6 !w-[150px]  text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] ${
                              item.lgaValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            }`}
                          >
                            {editable.id === item.id &&
                            editable.field === "lga" ? (
                              <input
                                value={item.lga}
                                onChange={(e) =>
                                  handleChange(item.id, "lga", e.target.value)
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2"
                              />
                            ) : (
                              <>
                                {item.lga}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "lga")
                                  }
                                />
                              </>
                            )}
                          </td>

                          <td
                            ref={(el) => {
                              if (!item.trackingIdValid)
                                errorRefs.current.trackingId = el;
                            }}
                            className={`py-4 pl-4 pr-6 !w-[150px]  text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] ${
                              item.trackingIdValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            }`}
                          >
                            {editable.id === item.id &&
                            editable.field === "trackingId" ? (
                              <input
                                value={item.trackingId}
                                onChange={(e) =>
                                  handleChange(
                                    item.id,
                                    "trackingId",
                                    e.target.value
                                  )
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2"
                              />
                            ) : (
                              <>
                                {item.trackingId}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "trackingId")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.itemValueValid)
                                errorRefs.current.itemValue = el;
                            }}
                            className={`${
                              item.itemValueValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[600]"
                            } py-5 text-xs pl-3 pr-6 border-l-[#fff]  border-[1px] border-[#E4EBF5]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "itemValue" ? (
                              <input
                                value={item.itemValue}
                                onChange={(e) =>
                                  handleChange(
                                    item.id,
                                    "itemValue",
                                    e.target.value
                                  )
                                }
                                type="number"
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                              />
                            ) : (
                              <>
                                {item.itemValue}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "itemValue")
                                  }
                                />
                              </>
                            )}
                          </td>

                          <td
                            ref={(el) => {
                              if (!item.itemCategoryValid)
                                errorRefs.current.itemCategory = el;
                            }}
                            className={`py-4 pl-4 pr-6 !w-[150px]  text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] ${
                              item.itemCategoryValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            }`}
                          >
                            {editable.id === item.id &&
                            editable.field === "itemCategory" ? (
                              <div>
                                <Dropdown
                                loader={isItemLoading}
                                  dropdown={
                                    <div className="flex border  rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                                      <div className="flex items-center space-x-1">
                                        <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                        <span
                                          className={` ${
                                            item?.itemCategory === ""
                                              ? "text-[#9899A1]"
                                              : "text-[#000]"
                                          }  font-onestMedium text-sm`}
                                        >
                                          {item?.itemCategory === "" ||
                                          item?.itemCategory === undefined
                                            ? "Select item category"
                                            : item.itemCategory}
                                        </span>
                                      </div>

                                      <img
                                        src={"/images/chevronDown.svg"}
                                        alt="drop-down"
                                        className="mt-0.5 pr-2 cursor-pointer"
                                      />
                                    </div>
                                  }
                                  search={
                                    <div>
                                      <TextInput
                                        value={searchItem}
                                        name={"search"}
                                        type={"text"}
                                        placeholder="Search"
                                        onChange={(e) =>
                                          setSearchItem(e.target.value)
                                        }
                                      />
                                    </div>
                                  }
                                  dropdownContainerClasses={`shadow-cardShadow-4  !w-full  !right-0  border border-slate-100  rounded `}
                                  dropdownOptions={[
                                    {
                                      customChild: (
                                        <div>
                                          {filteredItemSearch?.length > 0 ? (
                                            <div>
                                              {filteredItemSearch?.map(
                                                (
                                                  itemCategory: { id: number; name: string },
                                                  idx: number
                                                ) => {
                                                  return (
                                                    <div
                                                      key={idx}
                                                      onClick={() => {
                                                        handleChange(
                                                          item.id,
                                                          "itemCategory",
                                                          itemCategory.name
                                                        );
                                                        setItemName(
                                                          itemCategory?.name
                                                        );
                                                        setFormErrors(
                                                          (prev: any) => ({
                                                            ...prev,
                                                            itemCategory: "",
                                                          })
                                                        );
                                                      }}
                                                    >
                                                      <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                                        <p>
                                                          {itemCategory?.name}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          ) : (
                                            <p>No data !!</p>
                                          )}
                                        </div>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            ) : (
                              <>
                                {item.itemCategory}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "itemCategory")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.costValid) errorRefs.current.cost = el;
                            }}
                            className={`${
                              item.cost > 0
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] h-full font-[600]"
                            } py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5]`}
                          >
                            <p>{formatMoney().format(item.cost)}</p>
                          </td>
                          <td
                            className={`bg-[#F9F9FB] py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5]`}
                          >
                            <p>{formatMoney().format(item.discountedCost)}</p>
                          </td>
                          <td className="py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5]">
                            {editable.id === item.id &&
                            editable.field === "weight" ? (
                              <input
                                value={item.weight}
                                type="number"

                                onChange={(e) =>
                                  handleChange(
                                    item.id,
                                    "weight",
                                    e.target.value
                                  )
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                              />
                            ) : (
                              <>
                                {item.weight}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "weight")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td className="py-4 sticky right-0 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5]">
                            {editable.id === item.id &&
                            editable.field === "quantity" ? (
                              <input
                                value={item.quantity}
                                type="number"

                                onChange={(e) =>
                                  handleChange(
                                    item.id,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                              />
                            ) : (
                              <>
                                {item.quantity}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "quantity")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td className="py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5]">
                            {editable.id === item.id &&
                            editable.field === "additionalInfo" ? (
                              <input
                                value={item.additionalInfo}
                                onChange={(e) =>
                                  handleChange(
                                    item.id,
                                    "additionalInfo",
                                    e.target.value
                                  )
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                              />
                            ) : (
                              <>
                                {item.additionalInfo}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "additionalInfo")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            className="py-4 sticky right-0 cursor-pointer pl-5 bg-white w-[100%] text-xs text-red-500 border-r-[#fff] border-l-[#fff] border-[1px] border-[#E4EBF5]"
                            onClick={() => {
                              handleDelete(item.id);
                              if (users.length === 1 && idx === 0) {
                                setIsBulkUpload(false);
                                setBulkTableData(false);
                                setIsBulkUpload(false);
                              }
                            }}
                          >
                            {item.action}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex-col space-y-3  items-center bmd:left-[350px] right-0 px-5 shadow-faintShadow border-t justify-center bmd:justify-between  py-5 border-slate-100 bottom-[0px] w-full fixed z-[50]   bg-white">
              <div className="flex items-center justify-center bmd:justify-start space-x-6">
                <div className="w-fit">
                  <Button
                    className="py-2.5 "
                    title="Continue Shipment"
                    onClick={() => {
                      if (!noPayment) {
                        onGenerate();
                        setShowPayment(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isEditOrder ? (
        <div className="block overflow-x-hidden md:hidden">
          <>
            <div
              onClick={() => {
                setIsBulkUpload(true);
                setBulkTableData(false);
              }}
              className="flex gap-1 mt-14 md:mt-0 ml-4 cursor-pointer w-fit md:hidden"
            >
              <div>
                {" "}
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Go back
              </p>
            </div>

            <div className="mt-2 mb-[140px] sm:mt-[72px] rounded-lg px-4 md:px-10 pt-4 pb-8 md:py-8 max-w-[38rem] mx-4 sm:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
              <div className="w-full">
                <div className="hidden md:flex items-center justify-between">
                  <div
                    onClick={handleClick}
                    className="flex gap-1 cursor-pointer w-[100px]"
                  >
                    <div>
                      <img
                        className="w-3 h-3"
                        src="/images/backArrow.svg"
                        alt=""
                      />
                    </div>
                    <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                      Go back
                    </p>
                  </div>
                  <div className="flex gap-2.5">
                    {shipmentNavTabTitle?.map((title: { name: string }) => (
                      <div className="">
                        <div
                          className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                        ></div>
                        <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                          {title.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex md:hidden items-center justify-between mt-4 mb-6 mx-4">
                  {shipmentNavTabTitle?.map(
                    (title: { name: string; id: number }) => (
                      <>
                        {title.id !== 1 && (
                          <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                        )}
                        <div className="relative">
                          <FaCircle color="#18AF04" />
                          <p className="absolute mt-1 text-[#68726B] font-[500] -left-3 text-[12px] font-onestMedium w-[100px]">
                            {title.name}
                          </p>
                        </div>
                      </>
                    )
                  )}
                </div>
                <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                  Your bulk shipments
                </h1>
                <p className="mt-3 text-[#4a564d] text-[14px] font-onestMedium font-[500]">
                  International - Sending to Nigeria (Import)
                </p>
                <div className="flex justify-between mt-8">
                  <div className="flex text-xs text-[#4A564D] font-[600] gap-3 mb-3 ml-2">
                    <p className="">{users.length} shipments uploaded</p>
                    <div className="flex items-center gap-1">
                      <img src="/images/check-mark.svg" alt="" />
                      <p className="text-[#18AF04]">{result.rowValidCount}</p>
                    </div>
                    <div className="flex items-center gap-0">
                      <IoIosClose color={"#F64108"} size={18} />
                      <p className="text-[#F64108]">{result.rowInvalidCount}</p>
                    </div>
                  </div>
                </div>
                <div>
                  {users.map((user: any, idx: number) => {
                    const valid =
                      user.nameValid &&
                      user.addressValid &&
                      user.zoneValid &&
                      user.emailValid &&
                      user.weightValid &&
                      user.trackingIdValid &&
                      user.phoneValid &&
                      user.costValid &&
                      user.itemCategoryValid &&
                      user.itemValueValid;
                    return (
                      <div
                        key={user.id}
                        className="text-sm  text-[#161718] w-full font-[400]"
                      >
                        <div
                          className={`flex justify-between w-full border-[1px] ${
                            Boolean(valid)
                              ? "border-[#E4EBF5]"
                              : "border-[#F64108]"
                          } py-3 px-4 mb-3 rounded-lg`}
                        >
                          <div>
                            <h1 className="text-[#071227] text-sm font-onestMedium font-[500] mb-1.5">
                              {user.name}
                            </h1>
                            <div className="flex items-center">
                              <div
                                className="text-[#18AF04] text-xs font-onestRegular"
                                onClick={() => onEditOrder(user)}
                              >
                                Edit order
                              </div>
                              <FaCircle
                                color="#d1d1d2"
                                size={6}
                                className="bg-[#d1d1d2] mx-2 rounded-lg"
                              />
                              <p
                                onClick={() => {
                                  handleDelete(user.id);

                                  if (users.length === 1 && idx === 0) {
                                    setIsBulkUpload(false);
                                    setBulkTableData(false);
                                    setIsBulkUpload(false);
                                  }
                                }}
                                className="ml-1 text-[#F64108] text-xs font-onestRegular"
                              >
                                Delete
                              </p>
                            </div>
                          </div>
                          <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                            {getSymbolFromCurrency(currencyValue)}{" "}
                            {formatMoney().format(user.cost)}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex-col space-y-3 items-center left-0 right-0 px-5 shadow-faintShadow border-t justify-between  py-5 border-slate-100 bottom-[0px] w-full fixed z-[50]   bg-white">
                  <Button
                    className="py-2.5 w-full"
                    title="continue shipment"
                    loading={loading}
                    disabled={loading}
                    onClick={() => {
                      if (!noPayment && !loading) {
                        onGenerate();
                        setShowPayment(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      ) : (
        <div className="block overflow-x-hidden md:hidden">
          <>
            <div
              onClick={() => setIsEditOrder(false)}
              className="flex gap-1 mt-14 md:mt-0 ml-4 cursor-pointer w-[100px] md:hidden"
            >
              <div>
                {" "}
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Cancel
              </p>
            </div>
            <div className="mt-2 mb-[140px] sm:mt-[72px] rounded-lg px-4 md:px-10 pt-4 pb-8 md:py-8 max-w-[38rem] mx-4 sm:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
              <div className="w-full">
                <h1 className="text-2xl mt-6 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                  Edit order
                </h1>
                <p className="mt-2 text-[#A0ABA3] text-[16px] font-onestMedium font-[500]">
                  Make changes to this order
                </p>

                <>
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Recipient name"
                      isRequired
                      value={editDetails.name}
                      name="name"
                      isError={editDetails.nameValid}
                      placeholder="Enter name"
                      onChange={(e: any) => handleDeliveryChange(e, "name")}
                    />
                  </div>

                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Recipient address"
                      isRequired
                      name="dropOffAddress"
                      isError={editDetails.addressValid}
                      value={editDetails.address}
                      onChange={(e: any) => handleDeliveryChange(e, "address")}
                      placeholder="Enter recipient address"
                    />
                  </div>
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Delivery city"
                      isRequired
                      name="deliveryCity"
                      isError={editDetails.deliveryCityValid}
                      value={editDetails.address}
                      onChange={(e: any) =>
                        handleDeliveryChange(e, "deliveryCity")
                      }
                      placeholder="Enter delivery city"
                    />
                  </div>
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Delivery landmark"
                      isRequired
                      name="deliverylandmark"
                      isError={editDetails.landmarkValid}
                      value={editDetails.landMark}
                      onChange={(e: any) => handleDeliveryChange(e, "landmark")}
                      placeholder="Enter delivery landmark"
                    />
                  </div>
                  <div className="mt-6">
                    <div className="flex">
                      <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                        Item category
                      </p>
                      <span className="text-red-500 text-sm">*</span>
                    </div>
                    <Dropdown
                      dropdown={
                        <div className="flex border  rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                          <div className="flex items-center space-x-1">
                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                            <span
                              className={` ${
                                editDetails?.itemCategory === ""
                                  ? "text-[#9899A1]"
                                  : "text-[#000]"
                              }  font-onestMedium text-sm`}
                            >
                              {editDetails?.itemCategory === "" ||
                              editDetails?.itemCategory === undefined
                                ? "Select item category"
                                :   editDetails?.itemCategory }
                            </span>
                          </div>

                          <img
                            src={"/images/chevronDown.svg"}
                            alt="drop-down"
                            className="mt-0.5 pr-2 cursor-pointer"
                          />
                        </div>
                      }
                      search={
                        <div>
                          <TextInput
                            value={searchItem}
                            name={"search"}
                            type={"text"}
                            placeholder="Search"
                            onChange={(e) => setSearchItem(e.target.value)}
                          />
                        </div>
                      }
                      dropdownContainerClasses={`shadow-cardShadow-4  !w-full  !right-0  border border-slate-100  rounded `}
                      dropdownOptions={[
                        {
                          customChild: (
                            <div>
                              {filteredItemSearch?.length > 0 ? (
                                <div>
                                  {filteredItemSearch?.map(
                                    (itemCategory: any, idx: number) => {
                                      return (
                                        <div
                                          key={idx}
                                          onClick={() => {
                                            handleDeliveryChange(
                                              {
                                                target: {
                                                  name: "itemCategory",
                                                  value: itemCategory.name,
                                                },
                                              },
                                              "itemCategory"
                                            );
                                            setItemName(itemCategory?.name);
                                            setFormErrors((prev: any) => ({
                                              ...prev,
                                              itemCategory: "",
                                            }));
                                          }}
                                        >
                                          <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                            <p>{itemCategory?.name}</p>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ) : (
                                <p>No data !!</p>
                              )}
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="LGA"
                      isRequired
                      name="lga"
                      isError={editDetails.lgaValid}
                      value={editDetails.lga}
                      onChange={(e: any) => handleDeliveryChange(e, "lga")}
                      placeholder="Enter LGA"
                    />
                  </div>
                  <div className="mt-6">
                    <div className="flex">
                      <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                        State
                      </p>
                      <span className="text-red-500 text-sm">*</span>
                    </div>
                    <Dropdown
                      dropdown={
                        <div
                          className={`flex border ${
                            !editDetails.zoneValid
                              ? "border-[#BA3838]"
                              : "border-[#E4EBF5]"
                          } rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between `}
                        >
                          <div className="flex items-center space-x-1">
                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                            <span
                              className={` ${
                                editDetails?.zone === ""
                                  ? "text-[#9899A1]"
                                  : "text-[#000]"
                              }  font-onestMedium text-sm`}
                            >
                              {editDetails?.zone === "" ||
                              editDetails?.zone === undefined
                                ? "Select state"
                                : editDetails?.zone}
                            </span>
                          </div>

                          <img
                            src={"/images/chevronDown.svg"}
                            alt="drop-down"
                            className="mt-0.5 pr-2 cursor-pointer"
                          />
                        </div>
                      }
                      search={
                        <div>
                          <TextInput
                            value={searchDeliveryZone}
                            name={"search"}
                            type={"text"}
                            placeholder="Search"
                            onChange={(e) =>
                              setSearchDeliveryZone(e.target.value)
                            }
                          />
                        </div>
                      }
                      dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                      dropdownOptions={[
                        {
                          customChild: (
                            <div>
                              <div>
                                {filteredDeliveryZoneSearch?.map(
                                  (item: any, idx: number) => {
                                    return (
                                      <div
                                        key={idx}
                                        onClick={() => {
                                          handleDeliveryChange(
                                            {
                                              target: {
                                                name: "zone",
                                                value: item.state,
                                              },
                                            },
                                            "zone"
                                          );

                                          setEditDetails((prev: any) => ({
                                            ...prev,
                                            zone: item.state,
                                          }));
                                          setFormErrors((prev: any) => ({
                                            ...prev,
                                            zone: "",
                                          }));
                                        }}
                                      >
                                        <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                          <p>{item?.state}</p>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>

                  <div className="grid md:grid-cols-2 gap-x-3">
                    <div className="mt-6">
                      <TextInput
                        type="number"
                        label="Recipient phone number"
                        isRequired
                        name="recipientPhoneNumber"
                        value={editDetails.phone}
                        isError={editDetails.phoneValid}
                        placeholder="Enter phone number"
                        onChange={(e: any) => handleDeliveryChange(e, "phone")}
                      />
                    </div>
                    <div className="mt-6">
                      <TextInput
                        type="number"
                        label="Alternate phone number"
                        isRequired
                        name="alternatePhoneNumber"
                        value={editDetails.altPhone}
                        isError={editDetails.altPhoneValid}
                        placeholder="Enter phone number"
                        onChange={(e: any) =>
                          handleDeliveryChange(e, "altPhone")
                        }
                      />
                    </div>
                    <div className="mt-6">
                      <TextInput
                        type="email"
                        isError={editDetails.emailValid}
                        label="Recipient email"
                        placeholder="Enter email"
                        name="recipientEmail"
                        onChange={(e: any) => handleDeliveryChange(e, "email")}
                        value={editDetails.email}
                      />
                      {formErrors.recipientEmail && (
                        <span className="text-xs text-[#BA3838] font-onestMedium">
                          {formErrors.recipientEmail}
                        </span>
                      )}
                    </div>
                    <div className="mt-6">
                      <TextInput
                        type="text"
                        isError={editDetails.trackingId}
                        label="Order number/Tracking ID"
                        placeholder="Enter email"
                        name="trackingId"
                        onChange={(e: any) =>
                          handleDeliveryChange(e, "trackingId")
                        }
                        value={editDetails.trackingId}
                      />
                      {formErrors.trackingId && (
                        <span className="text-xs text-[#BA3838] font-onestMedium">
                          {formErrors.trackingId}
                        </span>
                      )}
                    </div>

                    <div className="mt-6">
                      <TextInput
                        type="number"
                        label="Value of Item in Naira"
                        isRequired
                        name="itemValue"
                        value={editDetails.itemValue}
                        isError={editDetails.itemValueValid}
                        placeholder="Enter value"
                        onChange={(e: any) =>
                          handleDeliveryChange(e, "itemValue")
                        }
                      />
                    </div>

                    <div className="mt-6">
                      <p className="text-[#4A564D] text-sm leading-[20px] font-onestMedium">
                        Cost
                      </p>
                      <div
                        className={`flex py-2 mt-[8px]  pl-3  ${
                          editDetails.costValid
                            ? "border-[#E4EBF5]"
                            : "  border-[#BA3838]"
                        }
             border  rounded-[8px] `}
                      >
                        <p>{editDetails?.cost || 0}</p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6">
                      <div className="mt-6">
                        <TextInput
                          type="number"
                          label="Quantity"
                          placeholder="Enter quantity"
                          name="quantity"
                          isError={editDetails.quantityValid}
                          onChange={(e: any) =>
                            handleDeliveryChange(e, "quantity")
                          }
                          value={editDetails.quantity}
                        />
                      </div>
                      <div className="mt-6">
                        <TextInput
                          isError={editDetails.weightValid}
                          type="number"
                          label="Item weight"
                          placeholder="Enter weight"
                          name="weight"
                          onChange={(e: any) =>
                            handleDeliveryChange(e, "weight")
                          }
                          value={editDetails.weight}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <TextArea
                      label="Additional Information"
                      placeholder="Write a description"
                      name="additionalInfo"
                      onChange={(e: any) =>
                        handleDeliveryChange(e, "description")
                      }
                      value={editDetails.additionalInfo}
                    />
                  </div>
                </>
                <div className="mt-[32px] w-full">
                  <Button
                    className="!py-4 "
                    title="Update and save"
                    loading={loading}
                    disabled={loading}
                    onClick={() => {
                      const valid =
                        editDetails.nameValid &&
                        editDetails.addressValid &&
                        editDetails.zoneValid &&
                        editDetails.emailValid &&
                        editDetails.weightValid &&
                        editDetails.trackingIdValid &&
                        editDetails.phoneValid &&
                        editDetails.costValid &&
                        editDetails.itemCategoryValid &&
                        editDetails.itemValueValid;
                      if (Boolean(valid) && !loading) {
                        setIsEditOrder(false);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default BulkImportUpload;
