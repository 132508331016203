import { useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import { useDashboardStatsMutation } from "services/slices/dashboard";
import { DELIVERY_NUMBERS, ORDERS_CHAIN } from "../../variables/data";
import { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import html2canvas from "html2canvas";
import { useGetWalletBalanceQuery } from "services/slices/wallet";
import { formatMoney } from "helpers/formatMoney";
import { Loader } from "components/Loader";
import ModalContainer from "components/Modal";
import { Button } from "components/Button";
import moment from "moment";
import { filterTerms } from "constant/data";
import { getDateRange } from "helpers/getDateRange";
import { handleErrorToast } from "helpers/toastMessage";
import FezWrapModal from "components/FezWrapModal";
import { IoClose } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

import { FiDownload, FiUpload } from "react-icons/fi";
import { useGetFezWrapQuery } from "services/slices/shipNow";
import FezWrapModalMobile from "components/FezWrapModalMobile";
interface User {
  userID: string | null;
  "Org Full Name": string | null;
}

interface Status {
  id: number;
  image: string;
}
interface ParamValues {
  param: string;
  value: number;
}

const statusData: Status[] = [
  { id: 1, image: "/images/welcome.svg" },
  { id: 2, image: "/images/welcome2.svg" },
  {
    id: 3,
    image: "/images/welcome.svg",
  },
  {
    id: 4,
    image: "/images/welcome2.svg",
  },
  {
    id: 5,
    image: "/images/welcome.svg",
  },
  {
    id: 6,
    image: "/images/welcome.svg",
  },
  {
    id: 7,
    image: "/images/welcome-safe-locker.svg",
  },
  {
    id: 8,
    image: "/images/welcome2.svg",
  },
  {
    id: 9,
    image: "/images/welcome-star.svg",
  },
  {
    id: 10,
    image: "/images/welcome.svg",
  },
];
function DashboardHome() {
  const orgCode: any = localStorage.getItem("fez-org-code");
  const fezWraps = useRef<(HTMLDivElement | null)[]>([]);
  const fezWrapMobile = useRef<(HTMLDivElement | null)[]>([]);
  const [dashboardStats, { isLoading }] = useDashboardStatsMutation();
  const [statistics, setStatistics] = useState<any>({});
  const [total, setTotal] = useState<number>(0);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const usersDataString = localStorage.getItem("fez-org-details");
  const [filterDate, setFilterDate] = useState<boolean>(false);
  const [openWrap, setOpenWrap] = useState<boolean>(false);
  const [selectedPeriod, setSelectedPeriod] = useState("last7days");
  const [audioPlayed, setAudioPlayed] = useState<boolean>(false);
  const [selectedPeriodName, setSelectedPeriodName] = useState("Last 7 days");
  const [homeEndDate, setHomeEndDate] = useState<string>(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [homeStartDate, setHomeStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const userData: User = usersDataString && JSON.parse(usersDataString);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const {
    data,
    error,
    isLoading: isWrapLoading,
  } = useGetFezWrapQuery(orgCode || "");

  const { data: walletData, isLoading: isWalletLoading } =
    useGetWalletBalanceQuery(undefined, {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const handlePeriodChange = (value: string) => {
    setSelectedPeriod(value);
  };

  useEffect(() => {
    if (selectedPeriod && selectedPeriod.toLowerCase() !== "custom") {
      const { startDate, endDate } = getDateRange(selectedPeriod);
      setHomeStartDate(startDate);
      setHomeEndDate(endDate);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod === "custom") {
      setShowDateModal(true);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (homeEndDate !== "" && homeStartDate !== "") {
      dashboardStats({
        startDate: homeStartDate,
        endDate: homeEndDate,
      })
        .unwrap()
        .then((data) => {
          setStatistics({
            returned: data?.orderStats[0].Returned,
            deliveryInProgress: data?.orderStats[0]["Delivery In Progress"],
            pickedUp: data?.orderStats[0]["Picked-Up"],
            pendingPickedUp: data?.orderStats[0]["Pending Pick-Up"],
            preparedForDelivery: data?.orderStats[0]["Prepared For Delivery "],
            paid: data?.orderStats[0]["paid"],
            unpaid: data?.orderStats[0]["unpaid"],
            inTransit: data?.orderStats[0]["In Transit Back to you"],
            delivered: data?.orderStats[0]["Delivered"],
          });
          setTotal(
            Number(data?.orderStats[0]["export"] || 0) +
              Number(data?.orderStats[0]["import"] || 0) +
              Number(data?.orderStats[0]["Prepared For Delivery "] || 0) +
              Number(data?.orderStats[0]["safeLocker"] || 0) +
              Number(data?.orderStats[0]["local"] || 0)
          );
        })
        .catch((error) => {
          handleErrorToast(error);
        });
    }
  }, [homeStartDate, homeEndDate]);

  const navigate = useNavigate();

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("YYYY-MM-DD");
      setFormattedDate(dateStart);
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("YYYY-MM-DD");
      setFormattedEndDate(dateStart);
      setEndDate(date);
    }
  };

  const onClearDate = () => {
    setSelectedPeriodName("Last 7 days");
    setSelectedPeriod("last7days");
    setShowDateModal(false);
  };
  const onApplyDate = () => {
    setSelectedPeriodName(`${formattedDate} - ${formattedEndDate}`);
    setHomeStartDate(formattedDate);
    setHomeEndDate(formattedEndDate);
    setShowDateModal(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          setCurrentSlide((prevSlide) => (prevSlide + 1) % statusData.length);
          return 0;
        }
        return prev + 1;
      });
    }, 150);
    return () => clearInterval(interval);
  }, []);

  const handlePlayAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setAudioPlayed(true);
    }
  };

  const getMonthMessage = (month: string) => {
    switch (month) {
      case "january":
        return (
          <p className="text-white text-sm mb-3">
            You started the year off with a bang! <br />
            Way to hit the ground running
          </p>
        );
      case "february":
        return (
          <p className="text-white text-sm mb-3">
            Nothing says love like gift-giving! You made
            <br /> the most shipments in the month of love ❤️
          </p>
        );
      case "november":
        return (
          <p className="text-white text-sm mb-3">
            You took advantage of our discounts and <br /> mastered Black Friday
            this year. Three gbosa for you!!!
          </p>
        );
      case "july":
      case "august":
        return (
          <p className="text-white text-sm mb-3">
            All through summer you were shipping,
            <br /> Summer's heat couldn't slow you down!
          </p>
        );
      case "june":
        return (
          <p className="text-white text-sm mb-3">
            Mid-year magic! You were busy sowing seeds <br /> for future success
            with every shipment.
          </p>
        );
      default:
        return (
          <p className="text-white text-sm mb-3">
            You got the best out of this month <br /> and made magic happen!
          </p>
        );
    }
  };

  const getPersonaImage = (persona: string) => {
    switch (persona) {
      case "express elite":
        return <img src="/images/express-elite.svg" alt="bike-svg" />;
      case "odogwu the importer":
        return <img src="/images/odogwu.svg" alt="bike-svg" />;
      case "the export explorer":
        return <img src="/images/export-explorer.svg" alt="bike-svg" />;
      case "sabi shipper":
        return <img src="/images/sabi-shipper.svg" alt="bike-svg" />;
      default:
        <img src="/images/consistent-cruise.svg" alt="bike-svg" />;
    }
  };

  const getPersonaName = (persona: string) => {
    switch (persona) {
      case "express elite":
        return (
          <div>
            <p
              style={{
                color: "#95F189",
                fontSize: "1.125rem",
              }}
              className="text-[#95F189] text-lg mb-1.5"
            >
              Express Elite
            </p>
            <p
              style={{
                color: "white",
                fontSize: "0.875rem",
              }}
              className="text-white text-sm font-onestMedium"
            >
              The mover and shaker! Setting the pace and leading the race in
              shipping volumes
            </p>
          </div>
        );
      case "odogwu the importer":
        return (
          <div>
            <p
              style={{
                color: "#95F189",
                fontSize: "1.125rem",
              }}
              className="text-[#95F189] text-lg mb-1.5"
            >
              Odogwu The Importer
            </p>
            <p className="text-white text-sm font-onestMedium">
              Bringing the world to your doorstep is an effortless thing for
              you!
            </p>
          </div>
        );
      case "the export explorer":
        return (
          <div>
            <p
              style={{
                color: "#95F189",
                fontSize: "1.125rem",
              }}
              className="text-[#95F189] text-lg mb-1.5"
            >
              Export Explorer
            </p>
            <p className="text-white text-sm font-onestMedium">
              For you, abroad is a stone's throw away and every export is an
              adventure 
            </p>
          </div>
        );
      case "sabi shipper":
        return (
          <div>
            <p
              style={{
                color: "#95F189",
                fontSize: "1.125rem",
              }}
              className="text-[#95F189] text-lg mb-1.5"
            >
              Sabi Shipper
            </p>
            <p className="text-white text-sm font-onestMedium">
              Starting small, but dreaming big – we're here for your growth
              every step of the way
            </p>
          </div>
        );

      default:
        <div>
          <p
            style={{
              color: "#95F189",
              fontSize: "1.125rem",
            }}
            className="text-[#95F189] text-lg mb-1.5"
          >
            Consistent Cruiser
          </p>
          <p className="text-white text-sm font-onestMedium">
            You’re a reliable partner, we are cruising towards success together
          </p>
        </div>;
    }
  };

  const getHighestValue = (
    Import: number,
    Export: number,
    Local: number
  ): ParamValues => {
    const values: Record<string, number> = {
      Import,
      Export,
      Local,
    };

    const highestKey = Object.keys(values).reduce((highest, key) =>
      values[key] > values[highest] ? key : highest
    );

    return { param: highestKey, value: values[highestKey] };
  };
  const result = getHighestValue(
    data?.fezWrap?.imported_orders,
    data?.fezWrap?.exported_orders,
    data?.fezWrap?.local_orders
  );

  const handleDownload = async (index: number) => {
    const element = fezWraps.current[index];
    if (element && navigator.canShare) {
      const canvas = await html2canvas(element);
      const blob = await (await fetch(canvas.toDataURL("image/png"))).blob();
      const file = new File([blob], `myFezWrap${index}.png`, {
        type: "image/png",
      });
      const data = { files: [file] };

      if (navigator.canShare(data)) {
        const fileURL = URL.createObjectURL(file);

        const link = document.createElement("a");
        link.href = fileURL;
        link.download = file.name;

        // Append the link to the document, click it, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(fileURL);
      } else {
        console.error("Sharing is not supported on this device.");
      }
    }
  };
  const handleShare = async (index: number) => {
    const element = fezWrapMobile.current[index];
    if (element && navigator.canShare) {
      const canvas = await html2canvas(element);

      const blob = await (await fetch(canvas.toDataURL("image/png"))).blob();

      const file = new File([blob], `myFezWrap${index}.png`, {
        type: "image/png",
      });
      const data = { files: [file] };

      if (navigator.canShare(data)) {
        await navigator
          .share(data)
          .catch((err) => console.error("Share failed:", err));
      } else {
        console.error("Sharing is not supported on this device.");
      }
    }
  };

  return (
    <>
      <div className="pl-5  pr-5 md:pl-[40px] md:pr-[55px] overflow-auto ">
        <div className="flex flex-col md:flex-row justify-between pt-[60px] font-onestRegular">
          <div className="">
            <h1 className="text-[#0A5001] font-[900] text-xl md:text-2xl font-onestBold">
              Hello{" "}
              {userData &&
              userData?.["Org Full Name"] &&
              userData?.["Org Full Name"]
                ? userData?.["Org Full Name"].charAt(0).toUpperCase() +
                  userData?.["Org Full Name"].slice(1)
                : ""}
            </h1>
            <p className="text-[#A0ABA3] md:text-lg font-onestRegular">
              Get Started: Create Orders and Ship Your Products Today!
            </p>
          </div>
          <div className="mt-6 md:mt-0">
            {isWalletLoading ? (
              <Loader />
            ) : (
              <h1 className="text-[#0A5001] font-[500] text-xl md:text-2xl font-onestRegular">
                ₦{formatMoney().format(walletData?.balance?.business_wallet)}
              </h1>
            )}
            <p className="text-[#A0ABA3] md:text-lg flex md:justify-end font-onestRegular">
              Wallet balance
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 bmd:grid-cols-4 gap-3 md:gap-6 mt-12">
          {ORDERS_CHAIN.map((item) => (
            <div
              onClick={() => navigate(`/dashboard/${item.link}`)}
              key={item.id}
              className={` flex justify-between cursor-pointer items-center border-[1px] border-[#E4EBF5] py-3 rounded-lg pl-[18px] pr-2.5`}
            >
              <h1
                className={`text-[13px] font-[600] font-onestMedium w-[90px]`}
              >
                {item.title}
              </h1>
              <img src={item.image} alt={item.title} />
            </div>
          ))}
        </div>
        <div>
          {isWrapLoading ? (
            <Loader />
          ) : (
            <>
              {Object.keys(data?.fezWrap ?? {}).length > 0 && (
                <div className="flex space-x-4 items-center justify-center w-full p-3 mt-7 rounded-[8px]  bg-hero-banner bg-cover bg-no-repeat">
                  <div>
                    <img
                      src="/images/Gift.png"
                      alt="gift-box"
                      className="w-full object-cover"
                    />
                  </div>
                  <div className="flex space-y-1 text-white flex-col items-center text-center">
                    <p className=" ">Your 2024 recap is here </p>
                    <p className=" flex items-center ">
                      <img
                        src="/images/Fez Logo.svg"
                        alt="gift-box"
                        className="mr-1.5 h-[25px] mb-1  w-[66px]"
                      />
                      <span className="text-[32px]  font-onestBold text-[#8AFE7B] ">
                        Unboxed!
                      </span>
                    </p>
                    <div
                      className="bg-bannerGreen py-[4px] px-4 cursor-pointer rounded-[45px]"
                      onClick={() => {
                        handlePlayAudio();
                        setOpenWrap(true);
                      }}
                    >
                      <p className="text-[12px] font-onestMedium">
                        Click To View
                      </p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/images/Gift.png"
                      alt="gift-box"
                      className="w-full object-cover"
                    />
                  </div>
                </div>
              )}
              <div>
                <div className="md:block hidden">
                  <FezWrapModal
                    open={openWrap}
                    showCloseIcon={false}
                    tailwindClassName="xl:w-[1064px]   md:w-[720px] lg:w-[800px] "
                  >
                    <div className="w-full p-6 h-full rounded-[10px] min-h-[600px] flex justify-center bg-[#202020] relative overflow-hidden">
                      <audio ref={audioRef} autoPlay loop>
                        <source src="/music/song.mp3" type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                      <AnimatePresence>
                        {statusData.map((status, index) =>
                          index === currentSlide ? (
                            <motion.div
                              key={status.id}
                              className="absolute inset-0 flex flex-col items-center justify-center rounded-[10px] overflow-hidden"
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              exit={{ opacity: 0, scale: 0.8 }}
                              transition={{
                                duration: 2,
                                ease: "easeInOut",
                                bounce: 0.25,
                              }}
                            >
                              <img
                                src={status.image}
                                alt={`Slide ${status.id}`}
                                className="w-full h-full object-cover"
                              />
                            </motion.div>
                          ) : null
                        )}
                      </AnimatePresence>
                      <div className="absolute pb-4 top-4 left-4 right-4 flex items-center justify-between">
                        <div className="flex-1    md:hidden flex   gap-1">
                          {statusData.map((_, index) => (
                            <div
                              key={index}
                              className={`h-1 flex-1 bg-gray-700 rounded-full`}
                              onClick={() => {
                                setCurrentSlide(index);
                              }}
                            >
                              {index === currentSlide && (
                                <motion.div
                                  className="h-full bg-white rounded-full"
                                  initial={{ width: 0 }}
                                  animate={{ width: `${progress}%` }}
                                  transition={{ duration: 0 }}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                        <img
                          src="/images/Fez Logo.svg"
                          alt="fez-logo"
                          className=""
                        />

                        <div className="flex-1 md:flex hidden md:mx-48  gap-1">
                          {statusData.map((_, index) => (
                            <div
                              key={index}
                              className={`h-1 flex-1 bg-gray-700 rounded-full`}
                              onClick={() => {
                                setCurrentSlide(index);
                              }}
                            >
                              {index === currentSlide && (
                                <motion.div
                                  className="h-full bg-white rounded-full"
                                  initial={{ width: 0 }}
                                  animate={{ width: `${progress}%` }}
                                  transition={{ duration: 0 }}
                                />
                              )}
                            </div>
                          ))}
                        </div>

                        <div
                          className="h-5 w-5 rounded-full bg-[#363636] cursor-pointer flex justify-center items-center"
                          onClick={() => {
                            setCurrentSlide(0);
                            setOpenWrap(false);
                          }}
                        >
                          <IoClose className="text-[#FFFFFF] text-sm" />
                        </div>
                      </div>
                      <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                        {currentSlide === 0 && (
                          <div>
                            <p className="text-white text-center mb-3">
                              Hey{" "}
                              <span className="font-onestBold">
                                {data?.fezWrap?.clientsFullName},
                              </span>
                            </p>
                            <p className="text-white">
                              Here’s your shipping highlights for 2024
                            </p>
                          </div>
                        )}
                        {currentSlide === 1 && (
                          <div className="flex items-center  flex-col  justify-center ">
                            <div className="flex md:flex-row flex-col items-center md:space-y-0 space-y-3 justify-center md:space-x-3">
                              <div className="text-center">
                                <p className="text-[#131313] text-lg font-onestBold mb-3">
                                  You’ve kept us busy this year
                                  <br /> and we loved every moment
                                </p>
                                <p className="text-[#131313] text-sm mb-3">
                                  You sent out{" "}
                                  <span className="font-onestBold">
                                    {formatMoney().format(
                                      data?.fezWrap?.delivered_orders
                                    )}
                                  </span>{" "}
                                  deliveries...
                                </p>
                              </div>
                              <div>
                                <img src="/images/bike.svg" alt="bike-svg" />
                              </div>
                            </div>
                          </div>
                        )}
                        {currentSlide === 2 && (
                          <div className="flex items-center  flex-col  justify-center ">
                            <div className="flex md:flex-row flex-col md:space-y-0 space-y-3 items-center justify-center md:space-x-6">
                              <div className="text-center">
                                <p className="text-white text-lg font-onestBold mb-3">
                                  ... and most of these
                                  <br /> were {result?.param} deliveries
                                </p>
                                <p className="text-white text-sm mb-3">
                                  You made{" "}
                                  <span className="font-onestBold">
                                    {formatMoney().format(result?.value)}
                                  </span>{" "}
                                  {result?.param?.toLowerCase()} shipments
                                </p>
                              </div>
                              <div>
                                {result?.param === "Local" && (
                                  <img src="/images/bus.svg" alt="bike-svg" />
                                )}
                                {result?.param === "Import" && (
                                  <img
                                    src="/images/international.svg"
                                    alt="bike-svg"
                                  />
                                )}
                                {result?.param === "Export" && (
                                  <img
                                    src="/images/export.svg"
                                    alt="bike-svg"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {currentSlide === 3 && (
                          <div className="">
                            <div className="sm:text-start text-center text-[#131313]">
                              <p className="text-lg  font-onestBold mb-2">
                                Where did your local packages go?
                              </p>
                              <p className="text-sm mb-3">
                                Here are your top states this year
                              </p>
                            </div>
                            <div className=" space-y-2">
                              {data?.fezWrap?.topRecipientState1 && (
                                <div className="border-[2px] bg-[#FFB401] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[290px] sm:w-[327px] border-[#131313]">
                                  <p className="text-lg font-onestBold">
                                    1. {data?.fezWrap?.topRecipientState1}
                                  </p>
                                  <p className="font-onestRegular text-sm">
                                    {formatMoney().format(
                                      data?.fezWrap?.topRecipientStateOrder1
                                    )}{" "}
                                    deliveries
                                  </p>
                                </div>
                              )}
                              {data?.fezWrap?.topRecipientState2 && (
                                <div className="border-[2px] bg-[#46C245] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[270px] sm:w-[287px] border-[#131313]">
                                  <p className="text-lg font-onestBold">
                                    2. {data?.fezWrap?.topRecipientState2}
                                  </p>
                                  <p className="font-onestRegular text-sm">
                                    {formatMoney().format(
                                      data?.fezWrap?.topRecipientStateOrder2
                                    )}{" "}
                                    deliveries
                                  </p>
                                </div>
                              )}
                              {data?.fezWrap?.topRecipientState3 && (
                                <div className="border-[2px] bg-[#A8DE45] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[240px] sm:w-[247px] border-[#131313]">
                                  <p className="text-lg font-onestBold">
                                    3. {data?.fezWrap?.topRecipientState3}
                                  </p>
                                  <p className="font-onestRegular text-sm">
                                    {formatMoney().format(
                                      data?.fezWrap?.topRecipientStateOrder3
                                    )}{" "}
                                    deliveries
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {currentSlide === 4 && (
                          <div>
                            <p className="text-white text-lg text-center mb-3">
                              <span className="font-onestBold">
                                We all have our peak seasons ...
                              </span>
                            </p>
                          </div>
                        )}
                        {currentSlide === 5 && (
                          <div className="flex items-center  flex-col  justify-center ">
                            <div className="flex items-center  md:flex-row flex-col md:space-y-0 space-y-3 justify-center md:space-x-6">
                              <div className="text-center">
                                <p className="text-white text-lg font-onestBold mb-3">
                                  ... and yours was in{" "}
                                  {data?.fezWrap?.month_with_highest_orders}
                                </p>
                                {getMonthMessage(
                                  data?.fezWrap?.month_with_highest_orders?.toLowerCase()
                                )}
                              </div>
                              <div>
                                {data?.fezWrap?.month_with_highest_orders.toLowerCase() ===
                                  "february" && (
                                  <img
                                    src="/images/feb-gift.svg"
                                    alt="bike-svg"
                                  />
                                )}
                                {data?.fezWrap?.month_with_highest_orders.toLowerCase() !==
                                  "february" && (
                                  <img
                                    src="/images/jan-gift.svg"
                                    alt="bike-svg"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {currentSlide === 6 && (
                          <div className="flex flex-col justify-center items-center w-full">
                            <p className="text-white text-center flex flex-col justify-center w-[90%] sm:w-[50%] md:w-[29%] mb-3">
                              <span className="font-onestBold text-lg">
                                We launched SafeLockers to help slash delivery
                                costs this year- did you take advantage of it?
                              </span>
                            </p>
                          </div>
                        )}
                        {currentSlide === 7 && (
                          <div className="flex items-center  flex-col  justify-center ">
                            <div className="flex items-center  md:flex-row flex-col md:space-y-0 space-y-3 justify-center  md:space-x-3">
                              <div>
                                <img src="/images/money.svg" alt="money-svg" />
                              </div>
                              <div className="text-center  flex flex-col justify-center w-[90%] sm:w-[70%] md:w-[40%]">
                                {data?.fezWrap?.total_safe_locker_orders > 0 ? (
                                  <p className="text-[#131313] text-xl font-onestBold mb-3">
                                    You made{" "}
                                    {formatMoney().format(
                                      data?.fezWrap?.total_safe_locker_orders
                                    )}{" "}
                                    SafeLocker deliveries! You’re well on your
                                    way!
                                  </p>
                                ) : (
                                  <p className="text-[#131313] text-xl font-onestBold mb-3">
                                    You haven’t tried the SafeLocker yet
                                  </p>
                                )}

                                {data?.fezWrap?.total_safe_locker_orders > 0 ? (
                                  <p className="text-[#131313] font-onestRegular  mb-3">
                                    And guess what? - We’re expanding our
                                    locations to better serve you in 2025!
                                  </p>
                                ) : (
                                  <p className="text-[#131313] font-onestRegular mb-3">
                                    Did you know you can enjoy secure deliveries
                                    and customer pick-ups for as low as ₦1,500?
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {currentSlide === 8 && (
                          <div className="w-full items-center flex flex-col justify-center">
                            <p className="text-[#131313] text-center mb-3">
                              <span className="font-onestBold text-xl">
                                We’ve had such a great time together this year
                              </span>
                            </p>
                            <div className="md:w-[30%] sm:w-[70%] w-[90%] items-center">
                              <p className="font-onestRegular items-center flex justify-center text-center">
                                And for customers like you, we have a special
                                name! Should we tell you? 🥁🥁
                              </p>
                            </div>
                          </div>
                        )}

                        {currentSlide === 9 && (
                          <div className="w-full flex items-center  flex-col md:space-y-0 space-y-3 justify-center md:space-x-6">
                            <div className="">
                              <div className="flex items-center  justify-center space-x-6">
                                <div>
                                  {getPersonaImage(
                                    data?.fezWrap?.persona?.toLowerCase()
                                  )}
                                </div>
                                <div className="w-[40%] flex flex-col justify-start  text-start  items-start">
                                  <p className="text-white block text-[13px] font-onestRegular mb-1">
                                    You are our
                                  </p>

                                  {getPersonaName(
                                    data?.fezWrap?.persona?.toLowerCase()
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {currentSlide !== 0 && (
                        <button
                          onClick={() =>
                            setCurrentSlide((prev) =>
                              prev === 0 ? statusData.length - 1 : prev - 1
                            )
                          }
                          className="absolute md:block hidden left-4 top-1/2 transform -translate-y-1/2 bg-[#D9D9D9]  p-1 rounded-full shadow-md"
                        >
                          <GrFormPrevious className="text-white text-xl" />
                        </button>
                      )}

                      {currentSlide !== 9 && (
                        <button
                          onClick={() =>
                            setCurrentSlide((prev) =>
                              prev === statusData.length - 1 ? 0 : prev + 1
                            )
                          }
                          className="absolute md:block hidden right-4 top-1/2 transform -translate-y-1/2 bg-[#D9D9D9]   p-1 rounded-full shadow-md"
                        >
                          <GrFormNext className="text-white text-xl" />
                        </button>
                      )}
                      <div className="absolute bottom-10 left-4 right-4 flex items-center justify-between">
                        <div className="relative group md:flex w-full justify-center mt-5 hidden">
                          <div className="absolute whitespace-nowrap top-[3.5rem] left-1/2 -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded-md shadow-md hidden group-hover:block">
                            Music by Niki N. Phaser
                          </div>
                          <Button
                            iconLeft
                            icon={<FiDownload />}
                            onClick={() => handleDownload(currentSlide)}
                            title="Download"
                            className="!rounded-full mt-4 !py-1.5 !w-fit"
                          />
                        </div>
                      </div>
                    </div>
                  </FezWrapModal>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="flex relative flex-col md:flex-row md:justify-between gap-[14px] md:gap-0 md:items-center mt-14">
          <h1 className="text-[#071227] font-[500]">Delivery numbers</h1>
          <div
            onClick={() => setFilterDate(!filterDate)}
            className="flex min-w-[110px] items-center gap-1 px-3 py-2 border-[1px] border-[#E4EBF5] rounded-lg cursor-pointer"
          >
            <img
              className="w-3 h-3"
              src="/images/calendar.svg"
              alt="calendar-icon"
            />
            <p className="text-[#4A564D] whitespace-nowrap text-xs font-[600] font-onestMedium">
              {selectedPeriodName}
            </p>
            {filterDate && (
              <div className="p-4 border-[1px] w-[190px] md:right-0 top-20 md:top-11 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                <h1 className="text-[#929DAE] pb-3 font-[900] text-xs font-onestBold">
                  PERIOD
                </h1>
                <form className="">
                  {filterTerms.map((terms) => (
                    <div
                      className="group flex my-3 gap-2.5 hover:bg-[#D4F3D5] text-[#289D17] hover:py-2 rounded-lg pl-2"
                      onClick={() => {
                        setSelectedPeriodName(terms.name);
                        handlePeriodChange(terms.value);
                      }}
                    >
                      <p className="flex items-center mb-0 cursor-pointer ">
                        <span
                          className={`${
                            selectedPeriod === terms.value && "bg-[#289D17]"
                          } w-4 h-4 border border-gray-300 rounded-full flex items-center justify-center group-hover:bg-[#289D17]`}
                        >
                          <span className="w-2 h-2 bg-white rounded-full"></span>
                        </span>
                        <span
                          className={`${
                            selectedPeriod === terms.value
                              ? "text-[#289D17]"
                              : "text-[#464748] group-hover:text-[#289D17]"
                          } ml-3 text-sm  `}
                        >
                          {terms.name}
                        </span>
                      </p>
                    </div>
                  ))}
                </form>
              </div>
            )}
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="grid md:grid-cols-2 bmd:grid-cols-3 gap-x-[31px] gap-y-5 mt-8">
            {DELIVERY_NUMBERS.map((item: any, idx) => (
              <div
                onClick={() =>
                  navigate(
                    `/dashboard/shipments?status=${encodeURIComponent(
                      item.name
                    )}${
                      selectedPeriod !== "custom"
                        ? `&date=${encodeURIComponent(selectedPeriod)}`
                        : `&startDate=${encodeURIComponent(
                            homeStartDate
                          )}&endDate=${encodeURIComponent(homeEndDate)}`
                    }`
                  )
                }
                key={idx}
                className="flex gap-3 rounded-lg items-center cursor-pointer border-[1px] border-[#E4EBF5]"
              >
                <div
                  style={{
                    background: `linear-gradient(to bottom, #ffffff, ${item.color})`,
                  }}
                  className={`rounded-bl-lg rounded-tl-lg px-4   w-[60px] py-6 flex justify-center items-center`}
                >
                  <img src={item.image} alt={item.title} />
                </div>
                <div>
                  <h1 className="text-[#4A564D] text-sm">{item.title}</h1>
                  {item.title.toLowerCase() === "pending pickup" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(statistics.pendingPickedUp &&
                        formatMoney().format(statistics.pendingPickedUp)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "picked up items" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(statistics.pickedUp &&
                        formatMoney().format(statistics.pickedUp)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "delivery in progress" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(statistics.deliveryInProgress &&
                        formatMoney().format(statistics.deliveryInProgress)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "delivered items" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(statistics.delivered &&
                        formatMoney().format(statistics.delivered)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "in transit back to you" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(statistics.intransit &&
                        formatMoney().format(statistics.intransit)) ||
                        0}
                    </p>
                  )}
                  {/* {item.title.toLowerCase() === "total paid" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        statistics.paid &&
                        formatMoney().format(statistics.paid)) ||
                        0}
                    </p>
                  )} */}
                  {/* {item.title.toLowerCase() === "total unpaid" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        statistics.unpaid &&
                        formatMoney().format(statistics.unpaid)) ||
                        0}
                    </p>
                  )} */}
                  {item.title.toLowerCase() === "returned items" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(statistics.returned &&
                        formatMoney().format(statistics.returned)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "prepared for delivery" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(statistics.preparedForDelivery &&
                        formatMoney().format(statistics.preparedForDelivery)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "total shipment" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {formatMoney().format(total) || 0}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <ModalContainer
        open={showDateModal}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px] "
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg text-center pb-4 font-onestBold text-[#0A5001] ">
            Custom date range
          </p>
          <div className="flex gap-3 justify-between">
            <div className="">
              <DatePicker
                placeholderText={"Start date"}
                selected={startDate}
                onChange={handleStartDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
            <div className="">
              <DatePicker
                placeholderText={"End date"}
                selected={endDate}
                onChange={handleEndDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
          </div>
          <div className="flex justify-between gap-3 items-center pt-[30px]">
            <Button
              title="Clear"
              onClick={onClearDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#289D17]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12 !bg-[#127E04] !text-[#fff]"
              onClick={onApplyDate}
            />
          </div>
        </div>
      </ModalContainer>

      <div>
        <div className="md:hidden block">
          <FezWrapModalMobile
            open={openWrap}
            showCloseIcon={false}
            tailwindClassName=" w-screen h-screen"
          >
            <div className="w-full p-6 h-full flex justify-center bg-[#202020] relative overflow-hidden">
              <audio ref={audioRef} autoPlay loop>
                <source src="/music/song.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              <AnimatePresence>
                {statusData.map((status, index) =>
                  index === currentSlide ? (
                    <motion.div
                      key={status.id}
                      className="absolute inset-0 flex flex-col items-center justify-center rounded-[10px] overflow-hidden"
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      transition={{
                        duration: 2,
                        ease: "easeInOut",
                        bounce: 0.25,
                      }}
                    >
                      <img
                        src={status.image}
                        alt={`Slide ${status.id}`}
                        className="w-full h-full object-cover"
                      />
                    </motion.div>
                  ) : null
                )}
              </AnimatePresence>
              <div className="absolute pb-4 top-4 left-4 right-4 flex items-center justify-between">
                <div className="flex-1    md:hidden flex   gap-1">
                  {statusData.map((_, index) => (
                    <div
                      key={index}
                      className={`h-1 flex-1 bg-gray-700 rounded-full`}
                      onClick={() => {
                        setCurrentSlide(index);
                      }}
                    >
                      {index === currentSlide && (
                        <motion.div
                          className="h-full bg-white rounded-full"
                          initial={{ width: 0 }}
                          animate={{ width: `${progress}%` }}
                          transition={{ duration: 0 }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />

                <div className="flex-1 md:flex hidden md:mx-48  gap-1">
                  {statusData.map((_, index) => (
                    <div
                      key={index}
                      className={`h-1 flex-1 bg-gray-700 rounded-full`}
                      onClick={() => {
                        setCurrentSlide(index);
                      }}
                    >
                      {index === currentSlide && (
                        <motion.div
                          className="h-full bg-white rounded-full"
                          initial={{ width: 0 }}
                          animate={{ width: `${progress}%` }}
                          transition={{ duration: 0 }}
                        />
                      )}
                    </div>
                  ))}
                </div>

                <div
                  className="h-5 w-5 rounded-full bg-[#363636] cursor-pointer flex justify-center items-center"
                  onClick={() => {
                    setCurrentSlide(0);
                    setOpenWrap(false);
                  }}
                >
                  <IoClose className="text-[#FFFFFF] text-sm" />
                </div>
              </div>
              <div className="absolute w-full flex flex-col   justify-center items-center  top-[50%] bottom-[50%]  ">
                {currentSlide === 0 && (
                  <div>
                    <p className="text-white text-center mb-3">
                      Hey{" "}
                      <span className="font-onestBold">
                        {data?.fezWrap?.clientsFullName},
                      </span>
                    </p>
                    <p className="text-white">
                      Here’s your shipping highlights for 2024
                    </p>
                  </div>
                )}
                {currentSlide === 1 && (
                  <div className="flex md:flex-row flex-col items-center md:space-y-0 space-y-3 justify-center md:space-x-3">
                    <div className="text-center">
                      <p className="text-[#131313] text-lg font-onestBold mb-3">
                        You’ve kept us busy this year
                        <br /> and we loved every moment
                      </p>
                      <p className="text-[#131313] text-sm mb-3">
                        You sent out{" "}
                        <span className="font-onestBold">
                          {formatMoney().format(
                            data?.fezWrap?.delivered_orders
                          )}
                        </span>{" "}
                        deliveries...
                      </p>
                    </div>
                    <div>
                      <img src="/images/bike.svg" alt="bike-svg" />
                    </div>
                  </div>
                )}
                {currentSlide === 2 && (
                  <div className="flex md:flex-row flex-col md:space-y-0 space-y-3 items-center justify-center md:space-x-6">
                    <div className="text-center">
                      <p className="text-white text-lg font-onestBold mb-3">
                        ... and most of these
                        <br /> were {result?.param} deliveries
                      </p>
                      <p className="text-white text-sm mb-3">
                        You made{" "}
                        <span className="font-onestBold">
                          {formatMoney().format(result?.value)}
                        </span>{" "}
                        {result?.param?.toLowerCase()} shipments
                      </p>
                    </div>
                    <div>
                      {result?.param === "Local" && (
                        <img src="/images/bus.svg" alt="bike-svg" />
                      )}
                      {result?.param === "Import" && (
                        <img src="/images/international.svg" alt="bike-svg" />
                      )}
                      {result?.param === "Export" && (
                        <img src="/images/export.svg" alt="bike-svg" />
                      )}
                    </div>
                  </div>
                )}
                {currentSlide === 3 && (
                  <div className="">
                    <div className="sm:text-start text-center text-[#131313]">
                      <p className="text-lg  font-onestBold mb-2">
                        Where did your local packages go?
                      </p>
                      <p className="text-sm mb-3">
                        Here are your top states this year
                      </p>
                    </div>
                    <div className=" space-y-2">
                      {data?.fezWrap?.topRecipientState1 && (
                        <div className="border-[2px] bg-[#FFB401] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[290px] sm:w-[327px] border-[#131313]">
                          <p className="text-lg font-onestBold">
                            1. {data?.fezWrap?.topRecipientState1}
                          </p>
                          <p className="font-onestRegular text-sm">
                            {formatMoney().format(
                              data?.fezWrap?.topRecipientStateOrder1
                            )}{" "}
                            deliveries
                          </p>
                        </div>
                      )}
                      {data?.fezWrap?.topRecipientState2 && (
                        <div className="border-[2px] bg-[#46C245] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[270px] sm:w-[287px] border-[#131313]">
                          <p className="text-lg font-onestBold">
                            2. {data?.fezWrap?.topRecipientState2}
                          </p>
                          <p className="font-onestRegular text-sm">
                            {formatMoney().format(
                              data?.fezWrap?.topRecipientStateOrder2
                            )}{" "}
                            deliveries
                          </p>
                        </div>
                      )}
                      {data?.fezWrap?.topRecipientState3 && (
                        <div className="border-[2px] bg-[#A8DE45] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[240px] sm:w-[247px] border-[#131313]">
                          <p className="text-lg font-onestBold">
                            3. {data?.fezWrap?.topRecipientState3}
                          </p>
                          <p className="font-onestRegular text-sm">
                            {formatMoney().format(
                              data?.fezWrap?.topRecipientStateOrder3
                            )}{" "}
                            deliveries
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {currentSlide === 4 && (
                  <div>
                    <p className="text-white text-lg text-center mb-3">
                      <span className="font-onestBold">
                        We all have our peak seasons ...
                      </span>
                    </p>
                  </div>
                )}
                {currentSlide === 5 && (
                  <div className="flex items-center  md:flex-row flex-col md:space-y-0 space-y-3 justify-center md:space-x-6">
                    <div className="text-center">
                      <p className="text-white text-lg font-onestBold mb-3">
                        ... and yours was in{" "}
                        {data?.fezWrap?.month_with_highest_orders}
                      </p>
                      {getMonthMessage(
                        data?.fezWrap?.month_with_highest_orders?.toLowerCase()
                      )}
                    </div>
                    <div>
                      {data?.fezWrap?.month_with_highest_orders.toLowerCase() ===
                        "february" && (
                        <img src="/images/feb-gift.svg" alt="bike-svg" />
                      )}
                      {data?.fezWrap?.month_with_highest_orders.toLowerCase() !==
                        "february" && (
                        <img src="/images/jan-gift.svg" alt="bike-svg" />
                      )}
                    </div>
                  </div>
                )}
                {currentSlide === 6 && (
                  <div className="flex flex-col justify-center items-center w-full">
                    <p className="text-white text-center flex flex-col justify-center w-[90%] sm:w-[50%] md:w-[29%] mb-3">
                      <span className="font-onestBold text-lg">
                        We launched SafeLockers to help slash delivery costs
                        this year- did you take advantage of it?
                      </span>
                    </p>
                  </div>
                )}
                {currentSlide === 7 && (
                  <div className="flex items-center  md:flex-row flex-col md:space-y-0 space-y-3 justify-center  md:space-x-3">
                    <div>
                      <img src="/images/money.svg" alt="money-svg" />
                    </div>
                    <div className="text-center  flex flex-col justify-center w-[90%] sm:w-[70%] md:w-[40%]">
                      {data?.fezWrap?.total_safe_locker_orders > 0 ? (
                        <p className="text-[#131313] text-xl font-onestBold mb-3">
                          You made{" "}
                          {formatMoney().format(
                            data?.fezWrap?.total_safe_locker_orders
                          )}{" "}
                          SafeLocker deliveries! You’re well on your way!
                        </p>
                      ) : (
                        <p className="text-[#131313] text-xl font-onestBold mb-3">
                          You haven’t tried the SafeLocker yet
                        </p>
                      )}

                      {data?.fezWrap?.total_safe_locker_orders > 0 ? (
                        <p className="text-[#131313] font-onestRegular  mb-3">
                          And guess what? - We’re expanding our locations to
                          better serve you in 2025!
                        </p>
                      ) : (
                        <p className="text-[#131313] font-onestRegular mb-3">
                          Did you know you can enjoy secure deliveries and
                          customer pick-ups for as low as ₦1,500?
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {currentSlide === 8 && (
                  <div className="w-full items-center flex flex-col justify-center">
                    <p className="text-[#131313] text-center mb-3">
                      <span className="font-onestBold text-xl">
                        We’ve had such a great time together this year
                      </span>
                    </p>
                    <div className="md:w-[30%] sm:w-[70%] w-[90%] items-center">
                      <p className="font-onestRegular items-center flex justify-center text-center">
                        And for customers like you, we have a special name!
                        Should we tell you? 🥁🥁
                      </p>
                    </div>
                  </div>
                )}
                {currentSlide === 9 && (
                  <div className="flex  w-full items-center  flex-col md:space-y-0 space-y-3 justify-center md:space-x-6">
                    <div className="">
                      <div className="flex w-full items-center px-5 py-10  flex-col  space-y-3 justify-center">
                        <p className="text-white text-center block text-[13px] font-onestRegular mb-1">
                          You are our
                        </p>
                        <div>
                          {getPersonaImage(
                            data?.fezWrap?.persona?.toLowerCase()
                          )}
                        </div>
                        <div className="w-[80%]  text-center  item-center">
                          {getPersonaName(
                            data?.fezWrap?.persona?.toLowerCase()
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {currentSlide !== 0 && (
                <button
                  onClick={() =>
                    setCurrentSlide((prev) =>
                      prev === 0 ? statusData.length - 1 : prev - 1
                    )
                  }
                  className="absolute md:block hidden left-4 top-1/2 transform -translate-y-1/2 bg-[#D9D9D9]  p-1 rounded-full shadow-md"
                >
                  <GrFormPrevious className="text-white text-xl" />
                </button>
              )}

              {currentSlide !== 9 && (
                <button
                  onClick={() =>
                    setCurrentSlide((prev) =>
                      prev === statusData.length - 1 ? 0 : prev + 1
                    )
                  }
                  className="absolute md:block hidden right-4 top-1/2 transform -translate-y-1/2 bg-[#D9D9D9]   p-1 rounded-full shadow-md"
                >
                  <GrFormNext className="text-white text-xl" />
                </button>
              )}
              <div className="absolute bottom-32 left-4 right-4 flex flex-col items-center justify-between">
                <div className="w-full px-14 mt-5">
                  <Button
                    iconLeft
                    icon={<FiUpload />}
                    title="Share"
                    onClick={() => handleShare(currentSlide)}
                    className="!rounded-full mt-4 block !py-1.5"
                  />
                </div>
                <div className=" whitespace-nowrap mt-0.5 left-0 right-0  w-fit  text-white text-xs px-2 py-1 rounded-md shadow-md">
                  Music by Niki N. Phaser
                </div>
              </div>
            </div>
          </FezWrapModalMobile>
        </div>
        {/* The unseen desktop slide  to share or download */}
        <div className="">
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWraps.current[0] = el)}
              style={{
                backgroundImage: `url('/images/welcome.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] md:rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div>
                  <p className="text-white text-center mb-3">
                    Hey{" "}
                    <span className="font-onestBold">
                      {data?.fezWrap?.clientsFullName},
                    </span>
                  </p>
                  <p className="text-white">
                    Here’s your shipping highlights for 2024
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWraps.current[1] = el)}
              style={{
                backgroundImage: `url('/images/welcome2.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="flex md:flex-row flex-col items-center md:space-y-0 space-y-3 justify-center md:space-x-3">
                  <div className="text-center">
                    <p className="text-[#131313] text-lg font-onestBold mb-3">
                      You’ve kept us busy this year
                      <br /> and we loved every moment
                    </p>
                    <p className="text-[#131313] text-sm mb-3">
                      You sent out{" "}
                      <span className="font-onestBold">
                        {formatMoney().format(data?.fezWrap?.delivered_orders)}
                      </span>{" "}
                      deliveries...
                    </p>
                  </div>
                  <div>
                    <img src="/images/bike.svg" alt="bike-svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWraps.current[2] = el)}
              style={{
                backgroundImage: `url('/images/welcome.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center  relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="flex md:flex-row flex-col md:space-y-0 space-y-3 items-center justify-center md:space-x-6">
                  <div className="text-center">
                    <p className="text-white text-lg font-onestBold mb-3">
                      ... and most of these
                      <br /> were {result?.param} deliveries
                    </p>
                    <p className="text-white text-sm mb-3">
                      You made{" "}
                      <span className="font-onestBold">
                        {formatMoney().format(result?.value)}
                      </span>{" "}
                      {result?.param?.toLowerCase()} shipments
                    </p>
                  </div>
                  <div>
                    {result?.param === "Local" && (
                      <img src="/images/bus.svg" alt="bike-svg" />
                    )}
                    {result?.param === "Import" && (
                      <img src="/images/international.svg" alt="bike-svg" />
                    )}
                    {result?.param === "Export" && (
                      <img src="/images/export.svg" alt="bike-svg" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWraps.current[3] = el)}
              style={{
                backgroundImage: `url('/images/welcome2.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="">
                  <div className="sm:text-start text-center text-[#131313]">
                    <p className="text-lg  font-onestBold mb-2">
                      Where did your local packages go?
                    </p>
                    <p className="text-sm mb-3">
                      Here are your top states this year
                    </p>
                  </div>
                  <div className=" space-y-2">
                    {data?.fezWrap?.topRecipientState1 && (
                      <div className="border-[2px] bg-[#FFB401] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[290px] sm:w-[327px] border-[#131313]">
                        <p className="text-lg font-onestBold">
                          1. {data?.fezWrap?.topRecipientState1}
                        </p>
                        <p className="font-onestRegular text-sm">
                          {formatMoney().format(
                            data?.fezWrap?.topRecipientStateOrder1
                          )}{" "}
                          deliveries
                        </p>
                      </div>
                    )}
                    {data?.fezWrap?.topRecipientState2 && (
                      <div className="border-[2px] bg-[#46C245] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[270px] sm:w-[287px] border-[#131313]">
                        <p className="text-lg font-onestBold">
                          2. {data?.fezWrap?.topRecipientState2}
                        </p>
                        <p className="font-onestRegular text-sm">
                          {formatMoney().format(
                            data?.fezWrap?.topRecipientStateOrder2
                          )}{" "}
                          deliveries
                        </p>
                      </div>
                    )}
                    {data?.fezWrap?.topRecipientState3 && (
                      <div className="border-[2px] bg-[#A8DE45] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[240px] sm:w-[247px] border-[#131313]">
                        <p className="text-lg font-onestBold">
                          3. {data?.fezWrap?.topRecipientState3}
                        </p>
                        <p className="font-onestRegular text-sm">
                          {formatMoney().format(
                            data?.fezWrap?.topRecipientStateOrder3
                          )}{" "}
                          deliveries
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWraps.current[4] = el)}
              style={{
                backgroundImage: `url('/images/welcome.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div>
                  <p className="text-white text-lg text-center mb-3">
                    <span className="font-onestBold">
                      We all have our peak seasons ...
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWraps.current[5] = el)}
              style={{
                backgroundImage: `url('/images/welcome.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center bg-[#202020] relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="flex items-center  md:flex-row flex-col md:space-y-0 space-y-3 justify-center md:space-x-6">
                  <div className="text-center">
                    <p className="text-white text-lg font-onestBold mb-3">
                      ... and yours was in{" "}
                      {data?.fezWrap?.month_with_highest_orders}
                    </p>
                    {getMonthMessage(
                      data?.fezWrap?.month_with_highest_orders?.toLowerCase()
                    )}
                  </div>
                  <div>
                    {data?.fezWrap?.month_with_highest_orders.toLowerCase() ===
                      "february" && (
                      <img src="/images/feb-gift.svg" alt="bike-svg" />
                    )}
                    {data?.fezWrap?.month_with_highest_orders.toLowerCase() !==
                      "february" && (
                      <img src="/images/jan-gift.svg" alt="bike-svg" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWraps.current[6] = el)}
              style={{
                backgroundImage: `url('/images/welcome-safe-locker.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="flex flex-col justify-center items-center w-full">
                  <p className="text-white text-center flex flex-col justify-center w-[90%] sm:w-[50%] md:w-[29%] mb-3">
                    <span className="font-onestBold text-lg">
                      We launched SafeLockers to help slash delivery costs this
                      year- did you take advantage of it?
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWraps.current[7] = el)}
              style={{
                backgroundImage: `url('/images/welcome2.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="flex items-center  md:flex-row flex-col md:space-y-0 space-y-3 justify-center  md:space-x-3">
                  <div>
                    <img src="/images/money.svg" alt="money-svg" />
                  </div>
                  <div className="text-center  flex flex-col justify-center w-[90%] sm:w-[70%] md:w-[40%]">
                    {data?.fezWrap?.total_safe_locker_orders > 0 ? (
                      <p className="text-[#131313] text-xl font-onestBold mb-3">
                        You made{" "}
                        {formatMoney().format(
                          data?.fezWrap?.total_safe_locker_orders
                        )}{" "}
                        SafeLocker deliveries! You’re well on your way!
                      </p>
                    ) : (
                      <p className="text-[#131313] text-xl font-onestBold mb-3">
                        You haven’t tried the SafeLocker yet
                      </p>
                    )}

                    {data?.fezWrap?.total_safe_locker_orders > 0 ? (
                      <p className="text-[#131313] font-onestRegular  mb-3">
                        And guess what? - We’re expanding our locations to
                        better serve you in 2025!
                      </p>
                    ) : (
                      <p className="text-[#131313] font-onestRegular mb-3">
                        Did you know you can enjoy secure deliveries and
                        customer pick-ups for as low as ₦1,500?
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWraps.current[8] = el)}
              style={{
                backgroundImage: `url('/images/welcome-star.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="w-full items-center flex flex-col justify-center">
                  <p className="text-[#131313] text-center mb-3">
                    <span className="font-onestBold text-xl">
                      We’ve had such a great time together this year
                    </span>
                  </p>
                  <div className="md:w-[30%] sm:w-[70%] w-[90%] items-center">
                    <p className="font-onestRegular items-center flex justify-center text-center">
                      And for customers like you, we have a special name! Should
                      we tell you? 🥁🥁
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWraps.current[9] = el)}
              style={{
                backgroundImage: `url('/images/welcome.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]   p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="w-full flex items-center  flex-col md:space-y-0 space-y-3 justify-center md:space-x-6">
                  <div className="">
                    <div className="flex items-center  justify-center space-x-6">
                      <div>
                        {getPersonaImage(data?.fezWrap?.persona?.toLowerCase())}
                      </div>
                      <div className="w-[40%] flex flex-col justify-start  text-start  items-start">
                        <p className="text-white block text-[13px] font-onestRegular mb-1">
                          You are our
                        </p>

                        {getPersonaName(data?.fezWrap?.persona?.toLowerCase())}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* The unseen mobile slide  to share or download */}
        <div className="">
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWrapMobile.current[0] = el)}
              style={{
                backgroundImage: `url('/images/welcome.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] md:rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div>
                  <p className="text-white text-center mb-3">
                    Hey{" "}
                    <span className="font-onestBold">
                      {data?.fezWrap?.clientsFullName},
                    </span>
                  </p>
                  <p className="text-white">
                    Here’s your shipping highlights for 2024
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWrapMobile.current[1] = el)}
              style={{
                backgroundImage: `url('/images/welcome2.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="flex md:flex-row flex-col items-center md:space-y-0 space-y-3 justify-center md:space-x-3">
                  <div className="text-center">
                    <p className="text-[#131313] text-lg font-onestBold mb-3">
                      You’ve kept us busy this year
                      <br /> and we loved every moment
                    </p>
                    <p className="text-[#131313] text-sm mb-3">
                      You sent out{" "}
                      <span className="font-onestBold">
                        {formatMoney().format(data?.fezWrap?.delivered_orders)}
                      </span>{" "}
                      deliveries...
                    </p>
                  </div>
                  <div>
                    <img src="/images/bike.svg" alt="bike-svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWrapMobile.current[2] = el)}
              style={{
                backgroundImage: `url('/images/welcome.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center  relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="flex md:flex-row flex-col md:space-y-0 space-y-3 items-center justify-center md:space-x-6">
                  <div className="text-center">
                    <p className="text-white text-lg font-onestBold mb-3">
                      ... and most of these
                      <br /> were {result?.param} deliveries
                    </p>
                    <p className="text-white text-sm mb-3">
                      You made{" "}
                      <span className="font-onestBold">
                        {formatMoney().format(result?.value)}
                      </span>{" "}
                      {result?.param?.toLowerCase()} shipments
                    </p>
                  </div>
                  <div>
                    {result?.param === "Local" && (
                      <img src="/images/bus.svg" alt="bike-svg" />
                    )}
                    {result?.param === "Import" && (
                      <img src="/images/international.svg" alt="bike-svg" />
                    )}
                    {result?.param === "Export" && (
                      <img src="/images/export.svg" alt="bike-svg" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWrapMobile.current[3] = el)}
              style={{
                backgroundImage: `url('/images/welcome2.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="">
                  <div className="sm:text-start text-center text-[#131313]">
                    <p className="text-lg  font-onestBold mb-2">
                      Where did your local packages go?
                    </p>
                    <p className="text-sm mb-3">
                      Here are your top states this year
                    </p>
                  </div>
                  <div className=" space-y-2">
                    {data?.fezWrap?.topRecipientState1 && (
                      <div className="border-[2px] bg-[#FFB401] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[290px] sm:w-[327px] border-[#131313]">
                        <p className="text-lg font-onestBold">
                          1. {data?.fezWrap?.topRecipientState1}
                        </p>
                        <p className="font-onestRegular text-sm">
                          {formatMoney().format(
                            data?.fezWrap?.topRecipientStateOrder1
                          )}{" "}
                          deliveries
                        </p>
                      </div>
                    )}
                    {data?.fezWrap?.topRecipientState2 && (
                      <div className="border-[2px] bg-[#46C245] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[270px] sm:w-[287px] border-[#131313]">
                        <p className="text-lg font-onestBold">
                          2. {data?.fezWrap?.topRecipientState2}
                        </p>
                        <p className="font-onestRegular text-sm">
                          {formatMoney().format(
                            data?.fezWrap?.topRecipientStateOrder2
                          )}{" "}
                          deliveries
                        </p>
                      </div>
                    )}
                    {data?.fezWrap?.topRecipientState3 && (
                      <div className="border-[2px] bg-[#A8DE45] rounded-tl-[4px] rounded-bl-[4px] rounded-tr-[40px] rounded-br-[40px] py-1.5 px-3 w-[240px] sm:w-[247px] border-[#131313]">
                        <p className="text-lg font-onestBold">
                          3. {data?.fezWrap?.topRecipientState3}
                        </p>
                        <p className="font-onestRegular text-sm">
                          {formatMoney().format(
                            data?.fezWrap?.topRecipientStateOrder3
                          )}{" "}
                          deliveries
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWrapMobile.current[4] = el)}
              style={{
                backgroundImage: `url('/images/welcome.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div>
                  <p className="text-white text-lg text-center mb-3">
                    <span className="font-onestBold">
                      We all have our peak seasons ...
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWrapMobile.current[5] = el)}
              style={{
                backgroundImage: `url('/images/welcome.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center bg-[#202020] relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="flex items-center  md:flex-row flex-col md:space-y-0 space-y-3 justify-center md:space-x-6">
                  <div className="text-center">
                    <p className="text-white text-lg font-onestBold mb-3">
                      ... and yours was in{" "}
                      {data?.fezWrap?.month_with_highest_orders}
                    </p>
                    {getMonthMessage(
                      data?.fezWrap?.month_with_highest_orders?.toLowerCase()
                    )}
                  </div>
                  <div>
                    {data?.fezWrap?.month_with_highest_orders.toLowerCase() ===
                      "february" && (
                      <img src="/images/feb-gift.svg" alt="bike-svg" />
                    )}
                    {data?.fezWrap?.month_with_highest_orders.toLowerCase() !==
                      "february" && (
                      <img src="/images/jan-gift.svg" alt="bike-svg" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWrapMobile.current[6] = el)}
              style={{
                backgroundImage: `url('/images/welcome-safe-locker.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="flex flex-col justify-center items-center w-full">
                  <p className="text-white text-center flex flex-col justify-center w-[90%] sm:w-[50%] md:w-[29%] mb-3">
                    <span className="font-onestBold text-lg">
                      We launched SafeLockers to help slash delivery costs this
                      year- did you take advantage of it?
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWrapMobile.current[7] = el)}
              style={{
                backgroundImage: `url('/images/welcome2.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="flex items-center  md:flex-row flex-col md:space-y-0 space-y-3 justify-center  md:space-x-3">
                  <div>
                    <img src="/images/money.svg" alt="money-svg" />
                  </div>
                  <div className="text-center  flex flex-col justify-center w-[90%] sm:w-[70%] md:w-[40%]">
                    {data?.fezWrap?.total_safe_locker_orders > 0 ? (
                      <p className="text-[#131313] text-xl font-onestBold mb-3">
                        You made{" "}
                        {formatMoney().format(
                          data?.fezWrap?.total_safe_locker_orders
                        )}{" "}
                        SafeLocker deliveries! You’re well on your way!
                      </p>
                    ) : (
                      <p className="text-[#131313] text-xl font-onestBold mb-3">
                        You haven’t tried the SafeLocker yet
                      </p>
                    )}

                    {data?.fezWrap?.total_safe_locker_orders > 0 ? (
                      <p className="text-[#131313] font-onestRegular  mb-3">
                        And guess what? - We’re expanding our locations to
                        better serve you in 2025!
                      </p>
                    ) : (
                      <p className="text-[#131313] font-onestRegular mb-3">
                        Did you know you can enjoy secure deliveries and
                        customer pick-ups for as low as ₦1,500?
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWrapMobile.current[8] = el)}
              style={{
                backgroundImage: `url('/images/welcome-star.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]  p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="w-full items-center flex flex-col justify-center">
                  <p className="text-[#131313] text-center mb-3">
                    <span className="font-onestBold text-xl">
                      We’ve had such a great time together this year
                    </span>
                  </p>
                  <div className="md:w-[30%] sm:w-[70%] w-[90%] items-center">
                    <p className="font-onestRegular items-center flex justify-center text-center">
                      And for customers like you, we have a special name! Should
                      we tell you? 🥁🥁
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              width: "100%",
            }}
          >
            <div
              ref={(el) => (fezWrapMobile.current[9] = el)}
              style={{
                backgroundImage: `url('/images/welcome.svg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              className="max-w-[1064px]   p-6 min-h-[600px] rounded-[10px] flex justify-center relative overflow-hidden"
            >
              <div className="absolute top-10 md:top-4 left-4 right-4 flex items-center justify-between">
                <img src="/images/Fez Logo.svg" alt="fez-logo" className="" />
              </div>
              <div className="absolute w-full flex flex-col  justify-center items-center  top-[50%] bottom-[50%]  ">
                <div className="flex  w-full items-center  flex-col md:space-y-0 space-y-3 justify-center md:space-x-6">
                  <div className="">
                    <div className="flex w-full items-center px-5 py-10  flex-col  space-y-3 justify-center">
                      <p className="text-white text-center block text-[13px] font-onestRegular mb-1">
                        You are our
                      </p>
                      <div>
                        {getPersonaImage(data?.fezWrap?.persona?.toLowerCase())}
                      </div>
                      <div className="w-[80%]  text-center  item-center">
                        {getPersonaName(data?.fezWrap?.persona?.toLowerCase())}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardHome;
