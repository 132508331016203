import { Button } from "components/Button";
import ModalContainer from "components/Modal";
import MenuField from "components/dashboard/MenuField";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetAllUsersQuery } from "services/slices/getAllUserSlice";
import PagePagination from "components/Pagination";
import {
  useDeleteInviteMutation,
  useDeleteUserMutation,
  useResendInviteMutation,
} from "services/slices/userSlices";
import { TableLoader } from "components/TableLoader";
import { Loader } from "components/Loader";
import { sortType } from "constant/data";
import { handleErrorToast, handleSuccessToast } from "helpers/toastMessage";
import { debouncedFunction } from "helpers/debouncedSearch";

const UserTable = () => {
  const [isDeleteInviteModal, setIsDeleteInviteModal] = useState(false);
  const [isDeactivateUser, setIsDeactivateUser] = useState(false);
  const [deleterId, setDeleterId] = useState("");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [debounceSearch, setDebounceSearch] = useState("");
  const [sortParams, setSortParams] = useState<any>([]);
  const [saveParams, setSaveParams] = useState<any>();
  const [isSortUsers, setIsSortUsers] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const {
    data: userData,
    isLoading,
    refetch: refetchUsers,
  } = useGetAllUsersQuery({
    find: search,
    page: page + 1,
    size: 15,
    sortBy: saveParams,
  });

 
  const debouncedSetSearch = useCallback((value:string) => {
    debouncedFunction(value, setSearch);
  }, []);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    debouncedSetSearch(e.target.value);
    setDebounceSearch(e.target.value);
  };

  useEffect(() => {
    setIsUserLoading(true);
    refetchUsers().finally(() => {
      setIsUserLoading(false);
    });
  }, [saveParams, search, page, refetchUsers]);

  const navigate = useNavigate();

  const handleSortUsers = () => {
    setIsSortUsers(!isSortUsers);
  };

  const handleSortChange = (event: any) => {
    const { value, checked } = event.target;
    setSortParams((prev: any) =>
      checked
        ? [...prev, value.toLowerCase()]
        : prev.filter((param: any) => param !== value)
    );
  };

  const [resendInvite, { isLoading: isResendInviteLoading }] =
    useResendInviteMutation();

  const [deleteInvite, { isLoading: isDeleteInviteLoading }] =
    useDeleteInviteMutation();
  const [deleteUser, { isLoading: isDeleteUserLoading }] =
    useDeleteUserMutation();

  const onResendInvite = async (id: any) => {
    const body: any = {
      userId: id,
    };
    await resendInvite(body)
      .unwrap()
      .then((payload) => {
        handleSuccessToast(payload.description);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      })
      .catch((e: { data: { description: string } }) => {
        handleErrorToast(e);
      });
  };

  const onDeleteInvite = async (id: any) => {
    const body: any = {
      userId: id,
    };

    await deleteInvite(body)
      .unwrap()
      .then((payload) => {
        setIsDeleteInviteModal(false);
        refetchUsers();
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  const onDeactiveUser = async (id: any) => {
    const body: any = {
      id: id,
    };

    await deleteUser(body)
      .unwrap()
      .then((payload) => {
        setIsDeactivateUser(false);
        refetchUsers();
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  const getOptions = (status: any) => {
    switch (status) {
      case "Invited":
        return [
          {
            label: "Resend invite",
            value: "RESEND_INVITE",
            icon: "/images/resend-invite.svg",
          },
          {
            label: "Delete invite",
            value: "DELETE_INVITE",
            icon: "/images/delete.svg",
          },
        ];
      case "Active":
        return [
          { label: "Edit user", value: "EDIT_USER", icon: "/images/edit.svg" },
          {
            label: "Reset password",
            value: "PASSWORD_RESET",
            icon: "/images/lock.svg",
          },
          {
            label: "Deactivate user",
            value: "DEACTIVATE_USER",
            icon: "/images/delete.svg",
          },
        ];
      default:
        return [];
    }
  };

  return (
    <>
      <div className=" mx-[20px] md:mx-[72px] mt-12 md:mt-[72px] mb-5">
        <div className="flex justify-between item-center">
          <div>
            <h1 className="text-[#0A5001] font-[900] text-xl md:text-2xl font-onestBold">
              Users
            </h1>
            <p className="text-[#A0ABA3] md:text-lg font-onestRegular">
              View and manage users
            </p>
          </div>
          <div>
            <Link
              to={"/dashboard/users/create-user"}
              className="bg-[#289D17] px-4 py-3 text-white rounded-lg flex gap-2 items-center"
            >
              <span className="text-sm"> Create User </span>
              <img
                className="hidden md:block"
                src="/images/create-user.svg"
                alt=""
              />
            </Link>
          </div>
        </div>
        <div className=" md:overflow-x-auto bg-[#F9F9FB] pb-[50px] h-fit p-4 mt-10">
          <div className="flex gap-3 justify-between mb-3">
            <div className="w-[60%] md:w-[289px] bg-[#fff] flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5]">
              <img
                className="w-[14px] h-[14px]"
                src="/images/search-icon.svg"
                alt="search-icon"
              />
              <input
                className="text-[13px] py-[10px] w-[279px] outline-none"
                type="text"
                placeholder="Search"
                onChange={handleInputChange}
                value={debounceSearch}
              />
            </div>
            <div className="relative">
              <div
                onClick={handleSortUsers}
                className="flex items-center gap-3 bg-white border-[1px] rounded-[9px] px-4 py-3 cursor-pointer border-[#E4EBF5]"
              >
                <img className="h-[10px]" src="/images/sort-menu.svg" alt="" />
                <span className="font-onestRegular text-[12px] text-[#9899A1]">
                  {" "}
                  Sort by
                </span>
              </div>
              {isSortUsers && (
                <div className="p-4 border-[1px] w-[240px] right-0 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                  <h1 className="text-[#0A5001] border-b-[1px] pb-3  border-[#E4EBF5] font-[900] text-base font-onestBold">
                    Sort Users by
                  </h1>
                  <form>
                    {sortType.map((data) => (
                      <div className="mt-7 flex gap-2.5" key={data.id}>
                        <input
                          type="checkbox"
                          value={data.name}
                          onChange={handleSortChange}
                          className="checked:bg-[#0A5001] focus:ring-[#0A5001]"
                        />
                        <label className="text-[#464748] font-onestRegular text-sm">
                          {data.name}
                        </label>
                      </div>
                    ))}

                    <div className="flex items-center gap-3 pt-[30px]">
                      <Button
                        onClick={() => {
                          setIsSortUsers(false);
                          setSortParams([]);
                          setSaveParams([]);
                        }}
                        title="Clear"
                        className="!py-[10px] !w-fit !border-[#BEC0BE] !px-6 !rounded-[100px] !bg-transparent !border !text-[#464748]"
                      />
                      <Button
                        onClick={() => {
                          setSaveParams(sortParams.join(","));
                        }}
                        loading={isUserLoading}
                        title="Save"
                        className={`!py-[10px] !w-fit !rounded-[100px] !px-8 ${
                          sortParams.length > 0
                            ? "!bg-[#289D17]"
                            : "!bg-[#A4AFA2]"
                        }  !text-[#fff]`}
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
          <div className="block md:hidden">
            {isLoading || isUserLoading ? (
              <Loader />
            ) : (
              <>
                {userData?.users?.data?.length > 0 ? (
                  <>
                    {userData?.users?.data?.map((item: any) => {
                      return (
                        <div className="bg-[#fff] p-3 " key={item.id}>
                          <div className=" border-b-[1px] flex justify-between pb-5 border-[#E4EBF5]">
                            <div>
                              <p className="text-xs mt-2">{item.fullname}</p>
                              <p className="pt-2 text-xs">{item.email}</p>
                              <p className="text-xs mt-2">{item.role}</p>
                              <div className="text-xs my-2">{item.user_id}</div>
                              <div
                                className={`inline px-2.5 py-1.5 text-xs mb-3 rounded-md font-onestMedium font-[600] ${
                                  item.status === "Active"
                                    ? "bg-[#D4F3D5] text-[#289D17]"
                                    : item.status === "Invited"
                                    ? "bg-[#FAEFE5] text-[#F59A49]"
                                    : "bg-[#FCE0DF] text-[#BA3838]"
                                }`}
                              >
                                {item.status}
                              </div>
                            </div>
                            <div className="">
                              <MenuField
                                onClick={(x: string) => {
                                  if (x === "RESEND_INVITE") {
                                    onResendInvite(item.user_id);
                                    return;
                                  }
                                  if (x === "DELETE_INVITE") {
                                    setIsDeleteInviteModal(true);
                                    setDeleterId(item.user_id);
                                    return;
                                  }
                                  if (x === "DEACTIVATE_USER") {
                                    setDeleterId(item.id);

                                    setIsDeactivateUser(true);
                                    return;
                                  }
                                  if (x === "EDIT_USER") {
                                    navigate(
                                      `/dashboard/users/edit-user/${item.user_id}`
                                    );
                                    return;
                                  }
                                }}
                                options={getOptions(item.status)}
                              >
                                <div className="align-bottom mt-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 18L12 18.01M12 12L12 12.01M12 6L12 6.01M12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13ZM12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19ZM12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7Z"
                                      stroke="#5C6881"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              </MenuField>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className=" flex justify-center mt-10">
                      {userData?.users?.total > 0 && (
                        <PagePagination
                          totalPages={userData?.users.last_page}
                          page={page}
                          handlePageClick={(event) => setPage(event.selected)}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <div>
                    <p>No User</p>
                  </div>
                )}
              </>
            )}
          </div>
          {isLoading || isUserLoading ? (
            <TableLoader rowLength={17} />
          ) : (
            <>
              {userData?.users?.data?.length > 0 ? (
                <>
                  <table className="hidden md:table w-full whitespace-nowrap text-left p-4 border-spacing-2 rounded-2xl md:w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                    <thead className="bg-[#Fff]  border-[#E4EBF5] !mx-12 border-b-[1px]">
                      <tr className="px-4">
                        <th className="py-3 pl-4 pr-4 text-xs text-[#929DAE] font-onestRegular">
                          Name
                        </th>
                        <th className="py-3 text-xs text-[#929DAE] font-onestRegular">
                          Email
                        </th>
                        <th className="py-3 text-xs text-[#929DAE] font-onestRegular">
                          Role
                        </th>
                        <th className="py-3 text-xs text-[#929DAE] font-onestRegular">
                          User ID
                        </th>
                        <th className="py-3 text-xs text-[#929DAE] font-onestRegular">
                          Status
                        </th>
                        <th className="py-3 text-xs text-[#929DAE] font-onestRegular"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {userData?.users?.data?.map((item: any) => {
                        return (
                          <tr
                            key={item.id}
                            className="text-sm text-[#161718] font-[400] bg-[#fff] border-b-[1px] border-[#E4EBF5]"
                          >
                            <td className="py-3 pl-3 b">
                              {item.fullname !== " "
                                ? item.fullname
                                : "*********"}
                            </td>
                            <td className="py-4 px-2 w-[15%]"> {item.email}</td>
                            <td className="py-4 w-[10%]">{item.role}</td>
                            <td className="py-4">{item.user_id}</td>
                            <td>
                              <div
                                className={`inline px-2.5 py-1.5 rounded-md font-onestMedium font-[600] ${
                                  item.status === "Active"
                                    ? "bg-[#D4F3D5] text-[#289D17]"
                                    : item.status === "Invited"
                                    ? "bg-[#FAEFE5] text-[#F59A49]"
                                    : "bg-[#FCE0DF] text-[#BA3838]"
                                }`}
                              >
                                {item.status}
                              </div>
                            </td>
                            <td className="py-4">
                              <MenuField
                                onClick={(x: string) => {
                                  // setDeleterId(item.user_id);
                                  if (x === "RESEND_INVITE") {
                                    if (!isResendInviteLoading) {
                                      onResendInvite(item.user_id);
                                    }
                                    return;
                                  }
                                  if (x === "DELETE_INVITE") {
                                    setIsDeleteInviteModal(true);
                                    setDeleterId(item.user_id);
                                    return;
                                  }
                                  if (x === "DEACTIVATE_USER") {
                                    setDeleterId(item.id);
                                    setIsDeactivateUser(true);
                                    return;
                                  }
                                  if (x === "EDIT_USER") {
                                    navigate(
                                      `/dashboard/users/edit-user/${item.user_id}`
                                    );
                                    return;
                                  }
                                }}
                                options={getOptions(item.status)}
                              >
                                <div className="align-bottom mt-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 18L12 18.01M12 12L12 12.01M12 6L12 6.01M12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13ZM12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19ZM12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7Z"
                                      stroke="#5C6881"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              </MenuField>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="hidden  md:flex mt-10">
                    {userData?.users?.total > 0 && (
                      <PagePagination
                        totalPages={userData?.users.last_page}
                        page={page}
                        handlePageClick={(event) => setPage(event.selected)}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="text-center my-10">
                  <p>No user</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ModalContainer
        open={isDeleteInviteModal}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px]"
        closeModal={() => setIsDeleteInviteModal(false)}
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg pb-4 border-b border-[#E4EBF5] font-onestBold text-[#0A5001] ">
            Delete invitation
          </p>

          <p className="pt-8 text-sm text-[#0E1728] font-onestRegular">
            Are you sure you want to delete this invitation? They will not be
            able to register until you add them again.
          </p>

          <div className="flex justify-between items-center pt-[30px]">
            <Button
              disabled={isDeleteInviteLoading}
              onClick={() => setIsDeleteInviteModal(false)}
              title="Cancel"
              className="!py-[10px] !border-[#BEC0BE] !px-6 !w-fit !rounded-[100px] !bg-transparent !border !text-[#289D17]"
            />
            <Button
              loading={isDeleteInviteLoading}
              disabled={isDeleteInviteLoading}
              onClick={() => onDeleteInvite(deleterId)}
              title="Delete invite"
              className="!py-[10px] !w-fit !rounded-[100px] !px-6 !bg-[#BA3838] !text-[#fff]"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={isDeactivateUser}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px]"
        closeModal={() => setIsDeactivateUser(false)}
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg pb-4 border-b border-[#E4EBF5] font-onestBold text-[#0A5001] ">
            Deactivate user
          </p>

          <p className="pt-8 text-sm text-[#0E1728] font-onestRegular">
            Are you sure you want to deactivate this user? They will not be able
            to register until you add them again.
          </p>

          <div className="flex justify-between items-center pt-[30px]">
            <Button
              disabled={isDeleteUserLoading}
              title="Cancel"
              onClick={() => setIsDeactivateUser(false)}
              className="!py-[10px] !border-[#BEC0BE] !px-6 !w-fit !rounded-[100px] !bg-transparent !border !text-[#289D17]"
            />
            <Button
              loading={isDeleteUserLoading}
              disabled={isDeleteUserLoading}
              onClick={() => onDeactiveUser(deleterId)}
              title="Deactivate"
              className="!py-[10px] !w-fit !rounded-[100px] !px-6 !bg-[#BA3838] !text-[#fff]"
            />
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default UserTable;
