import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoEyeOutline, IoHelpCircleOutline } from "react-icons/io5";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import EditShipmentModal from "./EditShipmentModal";
import {
  useEditShipmentMutation,
  useGetSingleOrderQuery,
} from "services/slices/shipNow";
import { formatMoney } from "helpers/formatMoney";
import { handleErrorToast } from "helpers/toastMessage";
import getSymbolFromCurrency from "currency-symbol-map";
import EmptyShipmentTable from "./EmptyShipmentTable";

interface Props {
  data: any;
  handleSelectAll?: () => void;
  handleSelectOne?: (e: any) => void;
  isAllSelected?: any;
  selectedItems?: any;
  showInvoice?: boolean;
  setReloadOrders: React.Dispatch<React.SetStateAction<boolean>>;
}

function ShipmentTable({
  data,
  handleSelectAll,
  handleSelectOne,
  isAllSelected,
  selectedItems,
  setReloadOrders,
  showInvoice,
}: Props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [formErrors, setFormErrors] = useState<any>({});
  const [editDetails, setEditDetails] = useState({
    recipientEmail: "",
    recipientPhone: "",
  });

  const {
    data: singleData,
    isLoading,
    refetch,
  } = useGetSingleOrderQuery(id || "");

  const [editShipment, { isLoading: editLoading }] = useEditShipmentMutation();

  const validateStep = (values: any) => {
    let errors: any = {};
    if (!values.recipientPhone || values.recipientPhone === "") {
      errors.recipientPhone = "Recipient phone number is required";
    } else if (
      values.exportPickUpPhoneNumber?.length < 9 ||
      values.exportPickUpPhoneNumber?.length > 13
    ) {
      errors.recipientPhone =
        "Recipient phone number must be between 9 - 13 digits";
    }
    return errors;
  };

  const handleEditSave = async () => {
    setFormErrors(validateStep(editDetails));
    const body = [
      {
        recipientEmail: editDetails.recipientEmail,
        recipientPhone: editDetails.recipientPhone,
        orderNo: id,
      },
    ];
    if (
      (editDetails.recipientEmail !==
        singleData?.orderDetails?.[0]?.recipientEmail ||
        editDetails.recipientPhone !==
          singleData?.orderDetails?.[0]?.recipientPhone) &&
      editDetails.recipientPhone !== "" &&
      (editDetails.recipientPhone.length >= 9 ||
        editDetails.recipientPhone.length <= 13)
    ) {
      await editShipment(body)
        .unwrap()
        .then((data) => {
          setOpen(false);
          refetch();
          setReloadOrders(true);
        })
        .catch((e: any) => {
          handleErrorToast(e);
        });
    }
  };
  useEffect(() => {
    setEditDetails({
      recipientEmail: singleData?.orderDetails?.[0]?.recipientEmail,
      recipientPhone: singleData?.orderDetails?.[0]?.recipientPhone,
    });
  }, [singleData]);

  return (
    <>
      {data?.data?.length > 0 ? (
        <table className=" w-full bg-white text-left border-spacing-2 whitespace-nowrap">
          <thead className="bg-white !mx-12">
            <tr className="border-b-[1px] py-5  pl-4 pr-4 border-[#E4EBF5]">
              {handleSelectAll && (
                <th className="pl-3 py-8 ">
                  <input
                    type="checkbox"
                    className="accent-[#289D17]"
                    checked={isAllSelected}
                    onChange={handleSelectAll}
                  />
                </th>
              )}

              <th className="py-3 text-left text-xs pl-5 pr-4 text-[#929DAE] font-onestRegular">
                Order date
              </th>
              <th className="py-3 text-left text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                Order ID
              </th>
              <th className="py-3 text-left text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                Status
              </th>
              <th className="py-3 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                Recipient Name
              </th>
              <th className="py-3 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                Recipient Address
              </th>
              <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                Recipient Phone
              </th>
              <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                Recipient Email
              </th>
              <th className="py-3 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                Cost
              </th>
              <th className="py-3 text-left  text-xs  pl-4 pr-4 text-[#929DAE] font-onestRegular">
                Return Reason
              </th>
              <th className="py-3 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                Created By
              </th>
              <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                Pick Up Date
              </th>
              <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                Dispatch Date
              </th>
              <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                Delivery Date
              </th>
              <th className="py-3 text-left  text-xs  pl-4 pr-8 text-[#929DAE] font-onestRegular">
                Additional Note
              </th>
              <th className=" sticky right-0   text-left text-xs text-[#929DAE] font-onestRegular ">
              <div className="pl-2 border-l border-[#E4EBF5] py-4 bg-white">
              Actions
                        </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((item: any) => {
              const isSelected = selectedItems?.some(
                (selectedItem: any) => selectedItem.id === item.id
              );
              return (
                <tr
                  className="py-5 border-b-[1px] border-[#E4EBF5]"
                  key={item.orderNo}
                >
                  {handleSelectOne && (
                    <td className="pl-3 pr-5 ">
                      <input
                        type="checkbox"
                        className="accent-[#289D17]"
                        checked={isSelected}
                        onChange={() => handleSelectOne(item)}
                      />
                    </td>
                  )}

                  <td className="text-left pl-5 pr-12 ">
                    <span className="text-sm font-onestRegular ">
                      {item.orderDate
                        ? moment(item.orderDate).format("DD MMM, YYYY")
                        : "N/A"}
                    </span>
                  </td>
                  <td className="pl-4 pr-10">
                    <span className="text-sm font-onestRegular ">
                      {item.orderNo}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-10">
                    <span
                      className={`text-xs text-[#65513F] ${
                        item.orderStatus === "Pending Payment"
                          ? "bg-[#D4F3D5] text-[#289D17]"
                          : item.orderStatus === "Pending Pick-Up"
                          ? "bg-[#F8E4D1] text-[#65513F]"
                          : "bg-[#FAEFE5] text-[#F59A49]"
                      } px-2 py-1 rounded-md font-[500] font-onestMedium`}
                    >
                      {item.orderStatus}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-10">
                    <span className="text-sm text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onesRegular">
                      {item.recipientName}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-10">
                    <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                      {item.recipientAddress || "N/A"}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-10">
                    <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                      {item.recipientPhone}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-10">
                    <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                      {item.recipientEmail || "N/A"}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-10">
                    <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                      {getSymbolFromCurrency(item.currency)}{" "}
                      {formatMoney().format(item.cost)}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-10">
                    <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                      {item.returnReason}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-10">
                    <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                      {item.createdBy}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-10">
                    <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                      {item.pickUpDate
                        ? moment(item.pickUpDate).format("DD MMM, YYYY")
                        : "N/A"}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-10">
                    <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                      {item.dispatchDate
                        ? moment(item.dispatchDate).format("DD MMM, YYYY")
                        : "N/A"}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-10">
                    <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                      {item.deliveryDate
                        ? moment(item.deliveryDate).format("DD MMM, YYYY")
                        : "N/A"}
                    </span>
                  </td>
                  <td className="text-xs pl-4 pr-12">
                    <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                      {item.additionalNote || "N/A"}
                    </span>
                  </td>
                  <td className="text-xs sticky z-[100]  right-0 pr-3  bg-white">
                    <div className="flex border-l py-6 pl-2 border-[#E4EBF5] ">
                      <Link
                        to={`/dashboard/shipments/${item.orderNo}`}
                        className="text-sm text-[#45C734] flex items-center gap-1 px-2 py-1 rounded-md font-[400] font-onestRegular"
                      >
                        <IoEyeOutline />
                        View
                      </Link>
                      {!showInvoice && (
                         <>
                          <span
                            className="text-sm text-[#A32596] flex items-center gap-1  cursor-pointer px-2 py-1 rounded-md font-[400] font-onestRegular"
                            onClick={() =>
                              navigate(
                                `/dashboard/tickets/create-ticket?id=${item.orderNo}`
                              )
                            }
                          >
                            <IoHelpCircleOutline />
                            Help
                          </span>
                          {item.orderStatus.toLowerCase() !== "pickedup" &&
                            item.orderStatus.toLowerCase() !== "returned" &&
                            item.orderStatus.toLowerCase() !== "delivered" && (
                              <div
                                onClick={() => {
                                  setId(item.orderNo);
                                  refetch();
                                  setOpen(true);
                                }}
                                className="text-sm cursor-pointer flex items-center gap-1 text-[#1D4CC4] px-2 py-1 rounded-md font-[400] font-onestRegular"
                              >
                                <MdOutlineModeEditOutline />
                                Edit
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <>
          <EmptyShipmentTable
          
          />
        </>
      )}

      <div className="my-10">
        <EditShipmentModal
          data={singleData}
          open={open}
          isLoading={isLoading}
          setOpen={setOpen}
          handleEditSave={handleEditSave}
          editLoading={editLoading}
          setEditDetails={setEditDetails}
          editDetails={editDetails}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
        />
      </div>
    </>
  );
}

export default ShipmentTable;
