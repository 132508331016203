import React from "react";

interface ModalContainerProps {
  children: React.ReactNode;
  open: boolean;
  closeModal?: () => void;
  tailwindClassName?: string;
  modalClassName?: string;
  showCloseIcon?: boolean;
  noPadding?: boolean;
  showBg?: boolean;
  modalRef?: React.RefObject<HTMLDivElement>;
}

const FezWrapModal = ({
  children,
  open,
  closeModal,
  tailwindClassName,
  noPadding,
  showCloseIcon,
  modalRef,
  showBg,
}: ModalContainerProps) => {
  return open ? (
      <div className="overflow-y-auto flex overflow-x-hidden w-[100%] justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0   right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
        <div className="relative  p-4 w-full hidden md:flex items-center  max-w-[1064px]  h-[100vh] md:h-auto mx-auto">
          <div
            className={`relative bg-white flex justify-center items-center mx-auto  rounded-[10px]   drop-shadow-md shadow-modalShadow`}
          >
            <div className=" flex justify-center flex-col  items-center ">
              <div
                ref={modalRef}
                className={` ${tailwindClassName} min-h-[300px] overflow-y-scroll`}
                onClick={(evt) => evt.stopPropagation()}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
      
  ) : null;
};

export default FezWrapModal;
