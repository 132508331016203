import { useNavigate } from "react-router";
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { DropDownSelect } from "components/common/form/Dropdown";
import MenuField from "components/dashboard/MenuField";
import { Button } from "components/Button";
import ShipmentTable from "./ShipmentTable";
import { shareOnMobile } from "react-mobile-share";
import { TextInput } from "components/common/form/Input";
import ModalContainer from "components/Modal";
import { MdOutlineCancel } from "react-icons/md";
import {
  useDownloadCreateMutation,
  useDownloadStatusMutation,
  useGetAllExportStatsMutation,
  useGetAllImportStatsMutation,
  useGetAllLocalStatsMutation,
  useGetAllOrderStatsMutation,
  useGetAllOrdersMutation,
} from "services/slices/shipNow";
import moment from "moment";
import PagePagination from "components/Pagination";
import { TableLoader } from "components/TableLoader";
import { useSearchParams } from "react-router-dom";
import { formatMoney } from "helpers/formatMoney";
import { Loader } from "components/Loader";
import getSymbolFromCurrency from "currency-symbol-map";
import { handleErrorToast } from "helpers/toastMessage";
import { copyToClipboard } from "helpers/handleCopy";
import { IoShareSocial } from "react-icons/io5";
import { PiCopySimpleFill, PiCopySimpleLight } from "react-icons/pi";
import { getDateRange } from "helpers/getDateRange";
import { dateRangeType, filterType, orderStatusList } from "constant/data";
import { debouncedFunction } from "helpers/debouncedSearch";

export type Ilist = {
  id: number;
  name: string;
  value: string;
};

interface User {
  orderDate?: string;
  orderNo: string;
  orderStatus: string;
  recipientName: string;
  recipientAddress: string;
  recipientPhone: string;
  recipientEmail: string;
  cost: string;
  returnReason: string;
  createdBy: string;
  pickUpDate?: string;
  dispatchDate?: string;
  deliveryDate?: string;
  additionalNote: string;
}

const Shipments = () => {
  const [param] = useSearchParams();
  const urlStartDate = param.get("startDate");
  const urlEndDate = param.get("endDate");
  const date = param.get("date");
  const status = param.get("status");
  const paramSearch = param.get("search");
  const [orderTab, setOrderTab] = useState<number>(0);
  const [query, setQuery] = useState<string>("");
  const [fiterQuery, setFilterQuery] = useState<string>("");
  const [orderStatusQuery, SetOrderStatusQuery] = useState<string>("");
  const [requestId, setRequestId] = useState<string>("");
  const [isFilterType, setIsFilterType] = useState(false);
  const [isOrderStatusType, setIsOrderStatusType] = useState(false);
  const [isDateRangeType, setIsDateRangeType] = useState(false);
  const [totalStats, setTotalStats] = useState<string>("");
  const [viewData, setViewData] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const [paginate, setPaginate] = useState<boolean>(false);
  const [isShipmentsType, setIsShipmentsType] = useState<boolean>(false);
  const [applyDate, setApplyDate] = useState<boolean>(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState<Ilist>();
  const [search, setSearch] = useState<string>(paramSearch || "");
  const [debounceSearch, setDebounceSearch] = useState<string>(
    paramSearch || ""
  );
  const [isAddressVisible, setIsAddressVisible] = useState<boolean>(false);
  const [copyKeySuccess, setCopyKeySuccess] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [showFilterMobileModal, setShowFilterMobileModal] =
    useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [orderEndDate, setOrderEndDate] = useState<string>("");
  const [orderStartDate, setOrderStartDate] = useState<string>("");
  const [tempEndDate, setTempEndDate] = useState<string>(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [tempStartDate, setTempStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [selectedPeriod, setSelectedPeriod] = useState(date || "Last 7 days");
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const [recipientName, setRecipientName] = useState<string>("");
  const [orderStatus, setOrderStatus] = useState<string>("");
  const [reloadOrders, setReloadOrders] = useState<boolean>(false);
  const [recipientEmail, setRecipientEmail] = useState<string>("");
  const [recipientPhone, setRecipientPhone] = useState<string>("");
  const [requestType, setRequestType] = useState<string>("");
  const [viewShipment, setViewShipment] = useState(false);
  const [getAllOrders, { data, isLoading }] = useGetAllOrdersMutation();
  const [
    downloadCreate,
    { isLoading: isDownloadCreating, data: downloadCreateData },
  ] = useDownloadCreateMutation();
  const [downloadStatus, { isLoading: isDownloadStatus }] =
    useDownloadStatusMutation();
  const [getAllOrderStats, { data: statsData }] = useGetAllOrderStatsMutation();
  const [getAllLocalStats, { data: localData }] = useGetAllLocalStatsMutation();
  const [getAllImportStats, { data: importData }] =
    useGetAllImportStatsMutation();
  const [getAllExportStats, { data: exportData }] =
    useGetAllExportStatsMutation();

  const toggleFilterType = () => {
    setIsFilterType((prev) => !prev);
    setIsOrderStatusType(false);
    setIsDateRangeType(false);
  };

  const toggleOrderStatusType = () => {
    setIsOrderStatusType((prev) => !prev);
    setIsDateRangeType(false);
    setIsFilterType(false);
  };

  const toggleDateRangeType = () => {
    setIsDateRangeType((prev) => !prev);
    setIsFilterType(false);
    setIsOrderStatusType(false);
  };

  const handleFilterType = (option: any) => {
    setSelectedFilterOption(option);
    setFilterQuery(option.name);
    setIsFilterType(false);
  };

  const handleOrderStatusType = (option: any) => {
    SetOrderStatusQuery(option.name);
    setIsOrderStatusType(false);
  };

  const handleDateRangeType = (option: any) => {
    setSelectedPeriod(option.name);
    setIsDateRangeType(false);
  };

  useEffect(() => {
    if (date) {
      const dateName = dateRangeType.filter((term: any) => term.value === date);
      setSelectedPeriod(dateName?.[0]?.name);
      setOrderStatus(status || "");
    }
    if (urlStartDate) {
      setSelectedPeriod("Custom date range");
      setOrderStatus(status || "");
    }
  }, [urlStartDate, date, urlEndDate, status]);

  const onClearFilterDate = () => {
    if (status) {
      if (date) {
        const dateName = dateRangeType.filter(
          (term: any) => term.value === date
        );
        setSelectedPeriod(dateName?.[0]?.name);
        setOrderStatus(status || "");
      }
      if (urlStartDate) {
        setSelectedPeriod("Custom date range");
        setOrderStatus(status || "");
      }
      setShowFilterModal(false);
      setShowFilterMobileModal(false);
    } else {
      // setSelectedPeriod("Last 7 days");
      // setOrderEndDate(moment().add(1, "days").format("YYYY-MM-DD"));
      // setOrderStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
      setShowFilterModal(false);
      setShowFilterMobileModal(false);
      setRecipientEmail("");
      setRecipientName("");
      setRecipientPhone("");
      SetOrderStatusQuery("");
      setFilterQuery("");
      setPaginate(false);
      setApplyDate(false);
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      moment(date).format("YYYY-MM-DD");
      setStartDate(date);
      setOrderStartDate(moment(date).format("YYYY-MM-DD"));
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      moment(date).format("YYYY-MM-DD");
      setEndDate(date);
      setOrderEndDate(moment(date).format("YYYY-MM-DD"));
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    // if (selectedPeriod) {
    //   let startDate;
    //   let endDate;
    //   switch (selectedPeriod) {
    //     case "Last 7 days":
    //       startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
    //       endDate = moment().add(1, "days").format("YYYY-MM-DD");
    //       break;
    //     case "Last month":
    //       startDate = moment()
    //         .subtract(1, "months")
    //         .startOf("months")
    //         .format("YYYY-MM-DD");
    //       endDate = moment()
    //         .subtract(1, "months")
    //         .endOf("months")
    //         .format("YYYY-MM-DD");
    //       break;
    //     case "This year":
    //       startDate = moment().startOf("year").format("YYYY-MM-DD");
    //       endDate = moment().endOf("year").format("YYYY-MM-DD");
    //       break;
    //     case "Last year":
    //       startDate = moment()
    //         .subtract(1, "year")
    //         .startOf("year")
    //         .format("YYYY-MM-DD");
    //       endDate = moment()
    //         .subtract(1, "year")
    //         .endOf("year")
    //         .format("YYYY-MM-DD");
    //       break;
    //     default:
    //       startDate = "";
    //       endDate = "";
    //   }
    //   setOrderStartDate(startDate);
    //   setOrderEndDate(endDate);
    // }
    const selectedData = dateRangeType.find(
      (data: Ilist) => data.name === selectedPeriod
    );
    if (selectedPeriod && selectedPeriod !== "Custom date range") {
      const { startDate, endDate } = getDateRange(selectedData?.value);
      setOrderStartDate(startDate);
      setOrderEndDate(endDate);
    }
  }, [selectedPeriod]);

  const onApplyDate = () => {
    const getOrders = async () => {
      const filters = {
        startDate: orderStartDate,
        endDate: orderEndDate,
        page: 1,
        orderStatus: "",
        recipientPhone: "",
        recipientEmail: "",
        recipientName: "",
        orderNo: search,
        requestType,
      };

      if (selectedFilterOption) {
        switch (selectedFilterOption.name) {
          case "Recipient phone":
            filters.recipientPhone = recipientPhone;
            break;
          case "Order status":
            filters.orderStatus = orderStatusQuery;
            break;
          case "Recipient name":
            filters.recipientName = recipientName;
            break;
          case "Recipient email":
            filters.recipientEmail = recipientEmail;
            break;
          default:
            break;
        }
      }
      setTempStartDate(orderStartDate);
      setTempEndDate(orderEndDate);
      setOrderStatus(orderStatusQuery);
      await getAllOrders(filters);
      await getAllOrderStats({
        ...filters,
        requestType: "",
      });
      await getAllLocalStats({
        ...filters,
        requestType: "local",
      });
      await getAllImportStats({
        ...filters,
        requestType: "import",
      });
      await getAllExportStats({
        ...filters,
        requestType: "export",
      });
    };
    getOrders();

    setShowFilterModal(false);
    setShowFilterMobileModal(false);
    navigate("/dashboard/shipments");
  };
  const handleCopyKey = () => {
    copyToClipboard(viewData?.orderNo, setCopyKeySuccess);
  };

  useEffect(() => {
    const getOrders = async () => {
      const filters: any = {
        startDate: orderStartDate,
        endDate: orderEndDate,
        orderNo: search,
        orderStatus,
        page: page + 1,
        requestType,
      };
      const applyFilters: any = {
        startDate: orderStartDate,
        endDate: orderEndDate,
        orderNo: search,
        orderStatus,
        page: page + 1,
        requestType,
        recipientPhone,
        recipientEmail,
        recipientName,
      };
      if (
        !applyDate &&
        urlStartDate &&
        urlEndDate &&
        !date &&
        !Boolean(showFilterMobileModal) &&
        !Boolean(showFilterModal)
      ) {
        await getAllOrders({
          ...filters,
          startDate: urlStartDate,
          endDate: urlEndDate,
        });
      }
      if (
        orderEndDate &&
        orderStartDate &&
        !urlStartDate &&
        !urlEndDate &&
        !applyDate &&
        !Boolean(showFilterMobileModal) &&
        !Boolean(showFilterModal)
      ) {
        await getAllOrders(filters);
      }

      if (
        orderEndDate &&
        orderStartDate &&
        !urlStartDate &&
        !urlEndDate &&
        applyDate &&
        paginate &&
        !Boolean(showFilterMobileModal) &&
        !Boolean(showFilterModal)
      ) {
        await getAllOrders(applyFilters);
      }
    };

    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    urlStartDate,
    date,
    orderStartDate,
    orderEndDate,
    urlEndDate,
    search,
    requestType,
    applyDate,
    showFilterMobileModal,
    showFilterModal,
    recipientEmail,
    recipientName,
    recipientPhone,
    paginate,
  ]);

  useEffect(() => {
    const getStatsOrders = async () => {
      const urlFilters = {
        startDate: urlStartDate || "",
        endDate: urlEndDate || "",
        orderNo: search,
        orderStatus: orderStatus,
        requestType: "",
      };
      const filters = {
        startDate: orderStartDate,
        endDate: orderEndDate,
        orderNo: search,
        orderStatus: orderStatus,
        requestType: "",
      };
      if (
        !applyDate &&
        !date &&
        urlStartDate &&
        urlEndDate &&
        !Boolean(showFilterMobileModal) &&
        !Boolean(showFilterModal)
      ) {
        await getAllOrderStats({
          ...urlFilters,
          requestType: "",
        });
        await getAllLocalStats({
          ...urlFilters,
          requestType: "local",
        });
        await getAllImportStats({
          ...urlFilters,
          requestType: "import",
        });
        await getAllExportStats({
          ...urlFilters,
          requestType: "export",
        });
      }
      if (
        date &&
        !applyDate &&
        !urlStartDate &&
        !urlEndDate &&
        orderStartDate &&
        orderEndDate &&
        !Boolean(showFilterMobileModal) &&
        !Boolean(showFilterModal)
      ) {
        await getAllOrderStats({
          ...filters,
          requestType: "",
        });
        await getAllLocalStats({
          ...filters,
          requestType: "local",
        });
        await getAllImportStats({
          ...filters,
          requestType: "import",
        });
        await getAllExportStats({
          ...filters,
          requestType: "export",
        });
      }
    };
    getStatsOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlStartDate,
    date,
    urlEndDate,
    orderEndDate,
    orderStartDate,
    search,
    requestType,
    showFilterMobileModal,
    showFilterModal,
  ]);

  useEffect(() => {
    const searchFilters: any = {
      startDate: orderStartDate,
      endDate: orderEndDate,
      orderNo: search,
      orderStatus,
      page: page + 1,
      requestType,
    };
    const getOrders = async () => {
      if (!applyDate && !urlStartDate && !urlEndDate && !date) {
        await getAllOrders(searchFilters);
      }
    };
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, requestType, applyDate, urlEndDate, urlStartDate, date]);

  const searchFiltersTwo = {
    startDate: tempStartDate,
    endDate: tempEndDate,
    orderNo: search,
    orderStatus,
    requestType: "",
  };

  useEffect(() => {
    const getOrders = async () => {
      if (reloadOrders) {
        await getAllOrders(searchFiltersTwo)
          .unwrap()
          .then((data) => {
            setReloadOrders(false);
          })
          .catch((e: { data: { description: string } }) => {
            setReloadOrders(false);
          });
      }
    };

    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadOrders]);

  useEffect(() => {
    const getOrders = async () => {
      if (!applyDate && !urlStartDate && !urlEndDate && !date) {
        await getAllOrderStats({ ...searchFiltersTwo, requestType: "" });
        await getAllLocalStats({
          ...searchFiltersTwo,
          requestType: "local",
        });
        await getAllImportStats({
          ...searchFiltersTwo,
          requestType: "import",
        });
        await getAllExportStats({
          ...searchFiltersTwo,
          requestType: "export",
        });
      }
    };

    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, applyDate, urlStartDate, urlEndDate, date]);

  const shipmentTypes = [
    {
      id: 0,
      type: "",
      label: `All Shipments - ${statsData?.orders?.meta?.total || 0}`,
      name: `All Shipments - ${statsData?.orders?.meta?.total || 0}`,
    },
    {
      id: 1,
      type: "local",
      label: `Local (within Nigeria) - ${localData?.orders?.meta?.total || 0}`,
      name: `Local (within Nigeria) - ${localData?.orders?.meta?.total || 0}`,
    },
    {
      id: 2,
      type: "import",
      label: `International (Import) - ${importData?.orders?.meta?.total || 0}`,
      name: `International (Import) - ${importData?.orders?.meta?.total || 0}`,
    },
    {
      id: 3,
      type: "export",
      label: `International (Export) - ${exportData?.orders?.meta?.total || 0}`,
      name: `International (Export) - ${exportData?.orders?.meta?.total || 0}`,
    },
  ];

  const getOptions = (status: any) => {
    switch (status) {
      case "Pending Pick-Up":
        return [
          {
            label: "View",
            value: "VIEW_SHIPMENT",
            icon: "/images/Lock.svg",
          },
          { label: "Edit", value: "EDIT_SHIPMENT", icon: "/images/edit.svg" },
          {
            label: "Help",
            value: "Help",
            icon: "/images/help.svg",
          },
        ];
      case "Pending Payment":
        return [
          {
            label: "View",
            value: "VIEW_SHIPMENT",
            icon: "/images/Lock.svg",
          },
          { label: "Edit", value: "EDIT_SHIPMENT", icon: "/images/edit.svg" },
          {
            label: "Help",
            value: "Help",
            icon: "/images/help.svg",
          },
        ];
      default:
        return [
          {
            label: "View",
            value: "VIEW_SHIPMENT",
            icon: "/images/Lock.svg",
          },
          {
            label: "Help",
            value: "Help",
            icon: "/images/help.svg",
          },
        ];
    }
  };
  const toggleDeliveryType = () => {
    setIsShipmentsType((prev) => !prev);
  };

  const handleDeliveryType = (option: any) => {
    setQuery(option.name);
    setRequestType(option.type);
    setPaginate(true);
    setIsShipmentsType(false);
  };

  const isAllSelected =
    data?.orders?.data.length > 0 &&
    data?.orders?.data.every((pageItem: any) =>
      selectedUsers.some((selectedItem: any) => selectedItem.id === pageItem.id)
    );

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedUsers(
        selectedUsers.filter(
          (selectedItem) =>
            !data?.orders?.data.some(
              (pageItem: any) => pageItem.id === selectedItem.id
            )
        )
      );
    } else {
      // Add all items on the current page to the selected list
      const newSelectedUsers = data?.orders?.data;
      setSelectedUsers([
        ...selectedUsers,
        ...newSelectedUsers.filter(
          (item: any) =>
            !selectedUsers.some(
              (selectedItem: any) => selectedItem.id === item.id
            )
        ),
      ]);
    }
  };

  const handleSelectOne = (user: any) => {
    const isAlreadySelected = selectedUsers.some(
      (selectedUser) => selectedUser.orderNo === user.orderNo
    );

    if (isAlreadySelected) {
      setSelectedUsers(
        selectedUsers.filter(
          (selectedUser) => selectedUser.orderNo !== user.orderNo
        )
      );
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  function downloadCSV(shipments: User[]) {
    const headers = [
      "Order date",
      "Order ID",
      "Status",
      "Recipient Name",
      "Recipient Address",
      "Recipient Phone",
      "Recipient Email",
      "Cost",
      "Return Reason",
      "Created By",
      "Pick Up Date",
      "Dispatch Date",
      "Delivery Date",
      "Additional Note",
    ];

    if (shipments.length > 0) {
      setDownloadingCSV(true);

      const rows = shipments.map((shipment) => [
        `"${
          shipment.orderDate
            ? moment(shipment.orderDate).format("DD MMM, YYYY")
            : "N/A"
        }"`,
        `"${shipment.orderNo}"`,
        `"${shipment.orderStatus}"`,
        `"${shipment.recipientName}"`,
        `"${shipment.recipientAddress}"`,
        `"${shipment.recipientPhone}"`,
        `"${shipment.recipientEmail}"`,
        `"${shipment.cost}"`,
        `"${shipment.returnReason}"`,
        `"${shipment.createdBy}"`,
        `"${
          shipment.pickUpDate
            ? moment(shipment.pickUpDate).format("DD MMM, YYYY")
            : "N/A"
        }"`,
        `"${
          shipment.dispatchDate
            ? moment(shipment.dispatchDate).format("DD MMM, YYYY")
            : "N/A"
        }"`,
        `"${
          shipment.deliveryDate
            ? moment(shipment.deliveryDate).format("DD MMM, YYYY")
            : "N/A"
        }"`,
        `"${shipment.additionalNote}"`,
      ]);

      let csvContent =
        "data:text/csv;charset=utf-8," +
        headers.join(",") +
        "\n" +
        rows.map((e) => e.join(",")).join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "shipments.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadingCSV(false);
    }
  }

  const onClearAllFilters = () => {
    const filters = {
      startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      orderNo: "",
      orderStatus: "",
      requestType: "",
    };
    setSelectedPeriod("Last 7 days");
    setTempStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
    setTempEndDate(moment().format("YYYY-MM-DD"));
    setRecipientEmail("");
    setRecipientName("");
    setRecipientPhone("");
    setOrderStatus("");
    SetOrderStatusQuery("");
    setStartDate(null);
    setEndDate(null);
    setApplyDate(false);
    setPaginate(false);
    getAllOrderStats({
      ...filters,
      requestType: "",
    });
    getAllLocalStats({
      ...filters,
      requestType: "local",
    });
    getAllImportStats({
      ...filters,
      requestType: "import",
    });
    getAllExportStats({
      ...filters,
      requestType: "export",
    });
    setSearch("");
    setDebounceSearch("");
    getAllOrders({
      ...filters,
      page: 1,
      requestType,
    });
    navigate("/dashboard/shipments");
  };

  const debouncedSetSearch = useCallback((value: string) => {
    debouncedFunction(value, setSearch);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearch(e.target.value);
    setDebounceSearch(e.target.value);
    setPage(0);
  };

  const fetchDownloadStatus = async () => {
    const maxTime = 15 * 60 * 1000;
    const startTime = Date.now();
    const intervalTime = 5000;

    const checkDownloadStatus = async () => {
      try {
        const data = await downloadStatus({ requestid: requestId }).unwrap();

        if (data?.["Details"]?.url) {
          window.location.href = data["Details"].url;
          return;
        }

        if (Date.now() - startTime >= maxTime) {
          handleErrorToast("Download error. please try again!!");
          return;
        }

        setTimeout(checkDownloadStatus, intervalTime);
      } catch (error) {
        if (Date.now() - startTime < maxTime) {
          setTimeout(checkDownloadStatus, intervalTime);
        } else {
          handleErrorToast("Download error. please try again!!");
        }
      }
    };

    checkDownloadStatus();
  };

  const generateUniqueRequestId = () => {
    return crypto.randomUUID();
  };

  const onDownloadAll = async () => {
    const uniqueRequestId = generateUniqueRequestId();
    setRequestId(uniqueRequestId);

    const filters: any = {
      startDate: orderStartDate,
      endDate: orderEndDate,
      requestid: uniqueRequestId,
      orderStatus: orderStatus,
      requestType,
    };

    if (
      !applyDate &&
      urlStartDate &&
      urlEndDate &&
      !Boolean(showFilterMobileModal) &&
      !Boolean(showFilterModal)
    ) {
      await downloadCreate({
        startDate: urlStartDate,
        endDate: urlEndDate,
        requestid: uniqueRequestId,
        orderStatus: orderStatus,
        requestType,
      })
        .unwrap()
        .then((data) => {})
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
    if (
      date &&
      !applyDate &&
      !Boolean(showFilterMobileModal) &&
      !Boolean(showFilterModal)
    ) {
      await downloadCreate(filters)
        .unwrap()
        .then((data) => {})
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
    if (!applyDate && !urlStartDate && !urlEndDate) {
      await downloadCreate(filters)
        .unwrap()
        .then((data) => {})
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
    if (
      orderEndDate &&
      orderStartDate &&
      !urlStartDate &&
      !urlEndDate &&
      applyDate &&
      !Boolean(showFilterMobileModal) &&
      !Boolean(showFilterModal)
    ) {
      await downloadCreate(filters)
        .unwrap()
        .then((data) => {})
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
  };


  useEffect(() => {
    if (requestId && downloadCreateData) {
      fetchDownloadStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, downloadCreateData]);

  useEffect(() => {
    if (data) {
      setTotalStats(data?.orders?.total);
    }
  }, [data]);

  return (
    <>
      <div className="hidden md:block px-3 md:px-[42px] mt-[24px] mb-5">
        <div className="flex justify-between items-center">
          <div className="flex space-x-3">
            <Button
              onClick={() => downloadCSV(selectedUsers)}
              className={`!px-8 !py-2.5 ${
                selectedUsers.length > 0 ? "!bg-[#45C734]" : "!bg-[#8C9D8A]"
              }`}
              disabled={downloadingCSV}
              loading={downloadingCSV}
              title={`Download ${
                selectedUsers && selectedUsers.length > 0
                  ? `(${selectedUsers.length})`
                  : ""
              }`}
            />
            {isAllSelected && (
              <Button
                onClick={async () => {
                  await onDownloadAll();
                }}
                className={`!px-8 !py-2.5 !bg-[#45C734] `}
                disabled={isDownloadCreating || isDownloadStatus}
                loading={isDownloadCreating || isDownloadStatus}
                title={`Download (${totalStats})`}
              />
            )}
          </div>
        </div>

        <div className="flex w-[90%] flex-wrap gap-5 xl:gap-0 xl:justify-between mt-10">
          {shipmentTypes.map((data, idx) => (
            <span
              key={idx}
              onClick={() => {
                setRequestType(data.type);
                setPaginate(true);
                setOrderTab(data.id);
              }}
              className={`cursor-pointer  pr-5 ${
                orderTab === data.id
                  ? "border-b-[2px] pb-1.5 border-[#45C734] font-[700]"
                  : ""
              } text-[#4A564D] text-[13px] `}
            >
              {data.label}
            </span>
          ))}
        </div>
        <div className="md:block bg-[#F9F9FB] pb-[0px] h-fit p-4 mt-10">
          <div className="flex gap-6 mb-3 items-center justify-between">
            <div className="flex gap-2 items-center">
              <div className="w-[60%] md:w-[289px] bg-[#fff] flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5]">
                <img
                  className="w-[14px] h-[14px]"
                  src="/images/search-icon.svg"
                  alt="search-icon"
                />
                <input
                  className="text-[13px] py-[10px] w-[279px] outline-none"
                  type="text"
                  placeholder="Keyword Search by Order ID"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={debounceSearch}
                />
              </div>
              <div className="flex items-center gap-1 bg-white border-[1px] rounded-[9px] px-4 py-2.5  border-[#E4EBF5]">
                <div
                  className="relative"
                  onClick={() => {
                    setShowFilterModal(true);
                  }}
                >
                  <div className="items-center flex gap-1  rounded-[9px]  cursor-pointer">
                    <img className="" src="/images/filter.svg" alt="" />
                    <p className="text-[13px] text-[#929DAE] w-fit px-2 outline-none">
                      Filter by:{" "}
                      {[
                        urlStartDate != null
                          ? `${urlStartDate}${" "} - ${" "}${urlEndDate}`
                          : "",
                        selectedPeriod !== "Custom date range" &&
                          urlStartDate === null &&
                          `${selectedPeriod}`,
                        selectedPeriod === "Custom date range" &&
                          urlStartDate === null &&
                          `${orderStartDate}${" "} - ${" "} ${orderEndDate}`,
                        selectedFilterOption?.name === "Order status" &&
                          (orderStatus === null
                            ? orderStatusQuery
                            : orderStatus),
                        status != null ? status : "",
                        selectedFilterOption?.name === "Recipient name" &&
                          recipientName !== null &&
                          recipientName,
                        selectedFilterOption?.name === "Recipient phone" &&
                          recipientPhone !== null &&
                          recipientPhone,
                        selectedFilterOption?.name === "Recipient email" &&
                          recipientEmail !== null &&
                          recipientEmail,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                  </div>
                </div>
                <span onClick={onClearAllFilters} className="cursor-pointer">
                  <MdOutlineCancel className="text-[#7e7f80]" />
                </span>
              </div>
            </div>
            <p className="text-xs text-[#4A564D] font-[500] flex gap-1.5 mr-[50px]">
              <span className="text-[#929DAE]"> Scroll right </span>
              <div className="mt-.05">
                {" "}
                <img src="/images/forward-arrow.svg" alt="" />
              </div>
            </p>
          </div>
          {isLoading ? (
            <TableLoader rowLength={17} />
          ) : (
            <div className="overflow-x-auto">
              <ShipmentTable
                data={data?.orders}
                handleSelectAll={handleSelectAll}
                handleSelectOne={handleSelectOne}
                isAllSelected={isAllSelected}
                selectedItems={selectedUsers}
                setReloadOrders={setReloadOrders}
              />
            </div>
          )}
        </div>
        {data?.orders?.data?.length > 0 &&
          data?.orders?.meta?.total > data?.orders?.meta?.per_page && (
            <div className="mt-3">
              <PagePagination
                totalPages={data?.orders?.last_page}
                page={page}
                handlePageClick={(event) => {
                  setPaginate(true);
                  setPage(event.selected);
                }}
              />
            </div>
          )}
      </div>

      {!viewShipment ? (
        <div className="mt-7 sm:mt-[72px] rounded-lg max-w-[38rem] block px-5 py-6 mb-5 md:hidden mx-4 sm:mx-auto border-[1px] border-[#E1F0DF] bg-dashboard-gradient">
          <div className="flex justify-between items-center">
            <h1 className="text-[#0A5001] font-[900] text-xl md:text-2xl font-onestBold">
              Shipments
            </h1>
            <Button
              onClick={onDownloadAll}
              className={`!px-8 !py-2.5 !bg-[#45C734] `}
              disabled={isDownloadCreating || isDownloadStatus}
              loading={isDownloadCreating || isDownloadStatus}
              title="Download all"
            />
          </div>
          <div className="mt-6 pb-6">
            <DropDownSelect
              title=""
              lists={shipmentTypes}
              value={query}
              setQuery={setQuery}
              isOpen={isShipmentsType}
              toggleDropdown={toggleDeliveryType}
              handleOptionClick={handleDeliveryType}
              placeholder="Select delivery type"
              name="deliveryType"
            />
          </div>
          <div className="flex gap-3 mt-3 mb-3">
            <div className="w-[80%] bg-[#fff] flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5]">
              <img
                className="w-[14px] h-[14px]"
                src="/images/search-icon.svg"
                alt="search-icon"
              />
              <input
                className="text-[13px] py-[10px] w-[279px] outline-none"
                type="text"
                placeholder="Keyword Search by Order ID"
                onChange={(e) => {
                  handleInputChange(e);
                }}
                value={debounceSearch}
              />
            </div>
            <div
              className="relative"
              onClick={() => {
                setShowFilterMobileModal(true);
              }}
            >
              <div className="flex items-center gap-3 bg-white border-[1px] rounded-[9px] px-4 py-3 cursor-pointer border-[#E4EBF5]">
                <img
                  className="w-full"
                  src="/images/filter-mobile.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="">
            {isLoading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <div>
                {data?.orders?.data?.length > 0 ? (
                  <>
                    {data?.orders?.data.map((item: any) => {
                      return (
                        <div className="bg-[#fff] my-3" key={item.id}>
                          <div className=" border-[1px] flex rounded-lg p-3 justify-between pb-5 border-[#E4EBF5]">
                            <div>
                              <p className="text-sm text-[#071227] mt-2 font-[700]">
                                Order: {item.orderNo}
                              </p>
                              <p className="pt-1 text-[13px] text-[#071227] mb-2">
                                {item.recipientName}
                              </p>
                              <p className="text-xs text-[#9899A1] mb-2">
                                {item.orderDate}
                              </p>

                              <div
                                className={`inline w-fit px-2.5 py-1.5 text-xs mb-3 rounded-md font-onestMedium font-[600] ${
                                  item.orderStatus === "Pending Payment"
                                    ? "bg-[#D4F3D5] text-[#289D17]"
                                    : item.orderStatus === "Pending Pick-Up"
                                    ? "bg-[#F8E4D1] text-[#65513F]"
                                    : "bg-[#FAEFE5] text-[#F59A49]"
                                }`}
                              >
                                {item.orderStatus}
                              </div>
                            </div>
                            <div className="">
                              <MenuField
                                onClick={(x: string) => {
                                  if (x === "VIEW_SHIPMENT") {
                                    setViewShipment(true);
                                    setViewData(item);
                                    return;
                                  }
                                  if (x === "EDIT_SHIPMENT") {
                                    navigate(
                                      `/dashboard/shipments/${item.orderNo}`
                                    );
                                    return;
                                  }
                                  if (x === "Help") {
                                    navigate(
                                      `/dashboard/tickets/create-ticket?id=${item.orderNo}`
                                    );
                                    return;
                                  }
                                }}
                                options={getOptions(item.orderStatus)}
                              >
                                <div className="align-bottom mt-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 18L12 18.01M12 12L12 12.01M12 6L12 6.01M12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13ZM12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19ZM12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7Z"
                                      stroke="#5C6881"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              </MenuField>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {data?.orders?.data?.length > 0 &&
                      data?.orders?.meta?.total >
                        data?.orders?.meta?.per_page && (
                        <div className="mt-3">
                          <PagePagination
                            totalPages={data?.orders?.last_page}
                            page={page}
                            handlePageClick={(event) => {
                              setPaginate(true);
                              setPage(event.selected);
                            }}
                          />
                        </div>
                      )}
                  </>
                ) : (
                  <div className="flex justify-center py-[32px] w-full">
                    <div className="">
                      <div className="flex justify-center pb-5 w-full">
                        {" "}
                        <img src="/images/new-request.svg" alt="" />
                      </div>
                      <p className="font-onestRegular mb-2 bmd:w-full text-sm text-[#4A564D]">
                        You do not have any shipments yet
                      </p>
                      <p className="font-onestRegular w-[100%] bmd:w-full text-xs text-center text-[#A0ABA3]">
                        When you make a shipment, it will show here
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="md:hidden">
          <div
            onClick={() => setViewShipment(!viewShipment)}
            className="mx-5 mt-9 flex mb-2 cursor-pointer"
          >
            <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
            <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
              Go back
            </p>
          </div>
          <div className="mt-3 sm:mt-[72px] rounded-lg max-w-[38rem] block px-5 py-6 mb-[150px] md:hidden mx-4 sm:mx-auto border-[1px] border-[#E1F0DF] bg-dashboard-gradient">
            <div className="flex items-center  justify-between">
              <h1 className="text-[#0A5001]  font-[900] text-xl md:text-2xl mb-2 font-onestBold">
                Order - {viewData?.orderNo}
              </h1>
              <div className="flex items-center space-x-3">
                <span
                  className="flex items-center gap-2 px-4 py-2.5  cursor-pointer"
                  onClick={handleCopyKey}
                >
                  <span className="text-[#494a49] text-sm">
                    {copyKeySuccess ? (
                      <PiCopySimpleFill size={24} />
                    ) : (
                      <PiCopySimpleLight size={24} />
                    )}
                  </span>
                </span>
                <button
                  onClick={() =>
                    shareOnMobile({
                      text: `Hello! Your order no is ${
                        viewData?.orderNo
                      }, You can track using this link https://web.fezdelivery.co/track-delivery/${
                        viewData?.orderNo
                      }  ${
                        viewData?.orderStatus.toLowerCase() === "dispatched"
                          ? `and your delivery OTP is ${viewData?.delivery_OTP}`
                          : ""
                      }, Thank you.`,
                      title: `Share Order No: ${viewData?.orderNo}`,
                    })
                  }
                >
                  <IoShareSocial size={22} />
                </button>
              </div>
            </div>
            <span className="text-xs text-[#65513F] bg-[#F8E4D1] px-2 py-1 rounded-md font-[500] font-onestMedium">
              Pending Pickup
            </span>
            <div className="border-[1px] rounded-lg mt-8 bg-white border-[#E4EBF5]">
              <div className="px-3 py-4 mt-[30px]">
                <h1 className="font-onestRegular">Recipient Name</h1>
                <p className="font-onestMedium "> {viewData?.recipientName}</p>
              </div>
              <div className=" py-4 px-3">
                <h1 className="font-onestRegular">Recipient Address</h1>
                <p className="font-onestMedium">
                  {" "}
                  {viewData?.recipientAddress || "N/A"}
                </p>
              </div>
              <div className="py-4  px-3">
                <h1 className="font-onestRegular ">Recipient Phone</h1>
                <p className="font-onestMedium ">
                  {" "}
                  {viewData?.recipient_phone || "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular">Recipient Email</h1>
                <p className="font-onestMedium ">
                  {" "}
                  {viewData?.recipientEmail || "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular">Status</h1>
                <span
                  className={`text-xs ${
                    viewData.orderStatus === "Pending Payment"
                      ? "bg-[#D4F3D5] text-[#289D17]"
                      : viewData.orderStatus === "Pending Pick-Up"
                      ? "bg-[#F8E4D1] text-[#65513F]"
                      : "bg-[#FAEFE5] text-[#F59A49]"
                  } px-2 py-1 rounded-md font-[500] font-onestMedium`}
                >
                  {viewData?.orderStatus}
                </span>
              </div>
              <div className="font-onestRegular  py-4 px-3">
                <div className="flex items-center space-x-2 bg-white pr-4">
                  <h1 className="font-onestRegular ">Pick up address</h1>
                  <img
                    src={
                      isAddressVisible
                        ? "/images/show-password.svg"
                        : "/images/hide-password.png"
                    }
                    alt={
                      isAddressVisible
                        ? "show-password-icon"
                        : "hide-password-icon"
                    }
                    className="h-5 w-5 cursor-pointer"
                    onClick={() => setIsAddressVisible(!isAddressVisible)}
                  />
                </div>
                <p className="font-onestMedium ">
                  {isAddressVisible ? viewData?.clientAddress : "*".repeat(20)}
                </p>
              </div>
              <div className=" py-4  px-3">
                <h1 className="font-onestRegular ">Status Description</h1>
                <p className="font-onestMedium ">
                  {" "}
                  {viewData?.statusDescription || "N/A"}
                </p>
              </div>
              <div className=" py-4  px-3">
                <h1 className="font-onestRegular ">Reason for Return</h1>
                <p className="font-onestMedium ">
                  {" "}
                  {viewData?.returnReason || "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular">Cost</h1>
                <p className="font-onestMedium ">
                  {getSymbolFromCurrency(viewData?.currency)}
                  {formatMoney().format(viewData.cost)}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular ">Created By</h1>
                <p className="font-onestMedium "> {viewData.createdBy}</p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular ">Pick Up Date</h1>
                <p className="font-onestMedium ">
                  {" "}
                  {viewData.pickUpDate
                    ? moment(viewData.pickUpDate).format("DD MMM, YYYY")
                    : "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular ">Dispatch Date</h1>
                <p className="font-onestMedium ">
                  {" "}
                  {viewData.dispatchDate
                    ? moment(viewData.dispatchDate).format("DD MMM, YYYY")
                    : "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular ">Delivery Date</h1>
                <p className="font-onestMedium ">
                  {viewData.deliveryDate
                    ? moment(viewData.deliveryDate).format("DD MMM, YYYY")
                    : "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular ">Additional Note</h1>
                <p className="font-onestMedium">
                  {" "}
                  {viewData.additionalNote || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <ModalContainer
        open={showFilterModal}
        showCloseIcon={false}
        tailwindClassName={`${
          selectedFilterOption?.id === 1 ? "h-[520px]" : "h-[520px]"
        } w-[300px] sm:w-[350px] rounded-md`}
      >
        <div className="p-6 w-full pb-4 h-full">
          <DropDownSelect
            title="Filter"
            lists={filterType}
            value={fiterQuery}
            setQuery={setFilterQuery}
            isOpen={isFilterType}
            toggleDropdown={toggleFilterType}
            handleOptionClick={handleFilterType}
            placeholder="-Select filter-"
            name="deliveryType"
          />

          {selectedFilterOption?.name === "Order status" && (
            <div className="mt-10 max-h-full">
              <DropDownSelect
                title="Order status"
                lists={orderStatusList}
                value={orderStatusQuery}
                setQuery={SetOrderStatusQuery}
                isOpen={isOrderStatusType}
                toggleDropdown={toggleOrderStatusType}
                handleOptionClick={handleOrderStatusType}
                placeholder="-Select a status-"
                name="deliveryType"
              />
            </div>
          )}
          {selectedFilterOption?.name === "Recipient name" && (
            <div className="mt-10">
              <TextInput
                type="text"
                label="Recipient name"
                value={recipientName}
                name="recipientName"
                placeholder="Enter name"
                onChange={(e) => setRecipientName(e.target.value)}
              />
            </div>
          )}
          {selectedFilterOption?.name === "Recipient phone" && (
            <div className="mt-10">
              <TextInput
                type="number"
                label="Recipient phone"
                value={recipientPhone}
                name="recipientPhoe"
                placeholder="Enter phone"
                onChange={(e) => setRecipientPhone(e.target.value)}
              />
            </div>
          )}
          {selectedFilterOption?.name === "Recipient email" && (
            <div className="mt-10">
              <TextInput
                type="text"
                label="Recipient email"
                value={recipientEmail}
                name="recipientEmail"
                placeholder="Enter email"
                onChange={(e) => setRecipientEmail(e.target.value)}
              />
            </div>
          )}
          <div className="mt-10">
            <DropDownSelect
              title="Date range"
              lists={dateRangeType}
              value={selectedPeriod}
              setQuery={setSelectedPeriod}
              isOpen={isDateRangeType}
              toggleDropdown={toggleDateRangeType}
              handleOptionClick={handleDateRangeType}
              placeholder="-Select a date range-"
              name="deliveryType"
            />
          </div>

          {selectedPeriod === "Custom date range" && (
            <div className="mt-10">
              <p className="text-sm pb-4 font-onestBold text-[#929DAE] ">
                Custom date range
              </p>
              <div className="grid grid-cols-2 gap-6">
                <div className="">
                  <DatePicker
                    placeholderText={"Start date"}
                    selected={startDate}
                    onChange={handleDateChange}
                    className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
                  />
                </div>
                <div className="">
                  <DatePicker
                    placeholderText={"End date"}
                    selected={endDate}
                    onChange={handleEndDateChange}
                    className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className={`${
              selectedFilterOption?.id === 1
                ? "pt-[110px]"
                : selectedFilterOption?.id === 2 ||
                  selectedFilterOption?.id === 3 ||
                  selectedFilterOption?.id === 4
                ? "pt-[100px]"
                : "pt-[230px]"
            }  flex justify-between gap-3 items-center pb-8 `}
          >
            <Button
              title="Clear"
              onClick={onClearFilterDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#289D17]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12 !bg-[#127E04] !text-[#fff]"
              onClick={() => {
                setApplyDate(true);
                setPaginate(false);
                onApplyDate();
              }}
            />
          </div>
        </div>
      </ModalContainer>

      <ModalContainer
        open={showFilterMobileModal}
        showCloseIcon={false}
        tailwindClassName={`${
          selectedFilterOption?.id === 1 ? "h-[100vh]" : "h-[100vh]"
        } w-[100vw] bg-white mt-[130px] rounded-tl-[24px] rounded-tr-[24px] md:hidden`}
      >
        <div className="p-6 w-full bg-white  rounded-[12px] pb-4 h-full ">
          <p className="text-[#929DAE] text-[10px]">FILTERS</p>

          <DropDownSelect
            title="Filter"
            lists={filterType}
            value={fiterQuery}
            setQuery={setFilterQuery}
            isOpen={isFilterType}
            toggleDropdown={toggleFilterType}
            handleOptionClick={handleFilterType}
            placeholder="-Select filter-"
            name="deliveryType"
          />

          {selectedFilterOption?.name === "Order status" && (
            <div className="mt-7 max-h-full">
              <DropDownSelect
                title="Order status"
                lists={orderStatusList}
                value={orderStatusQuery}
                setQuery={SetOrderStatusQuery}
                isOpen={isOrderStatusType}
                toggleDropdown={toggleOrderStatusType}
                handleOptionClick={handleOrderStatusType}
                placeholder="-Select a status-"
                name="deliveryType"
              />
            </div>
          )}
          {selectedFilterOption?.name === "Recipient name" && (
            <div className="mt-7">
              <TextInput
                type="text"
                label="Recipient name"
                value={recipientName}
                name="recipientName"
                placeholder="Enter name"
                onChange={(e) => setRecipientName(e.target.value)}
              />
            </div>
          )}
          <div className="mt-7">
            <TextInput
              type="number"
              label="Recipient phone"
              value={recipientPhone}
              name="recipientPhoe"
              placeholder="Enter phone"
              onChange={(e) => setRecipientPhone(e.target.value)}
            />
          </div>

          <div className="mt-7">
            <TextInput
              type="text"
              label="Recipient email"
              value={recipientEmail}
              name="recipientEmail"
              placeholder="Enter email"
              onChange={(e) => setRecipientEmail(e.target.value)}
            />
          </div>
          <div className="mt-7">
            <DropDownSelect
              title="Date range"
              lists={dateRangeType}
              value={selectedPeriod}
              setQuery={setSelectedPeriod}
              isOpen={isDateRangeType}
              toggleDropdown={toggleDateRangeType}
              handleOptionClick={handleDateRangeType}
              placeholder="-Select a date range-"
              name="deliveryType"
            />
          </div>

          {selectedPeriod === "Custom date range" && (
            <div className="mt-10">
              <p className="text-sm pb-4 font-onestBold text-[#929DAE] ">
                Custom date range
              </p>
              <div className="grid grid-cols-2 gap-6">
                <div className="">
                  <DatePicker
                    placeholderText={"Start date"}
                    selected={startDate}
                    onChange={handleDateChange}
                    className="border-[1px] border-[#E4EBF5] p-2.5 w-full rounded-lg outline-none"
                  />
                </div>
                <div className="">
                  <DatePicker
                    placeholderText={"End date"}
                    selected={endDate}
                    onChange={handleEndDateChange}
                    className="border-[1px] border-[#E4EBF5] p-2.5 w-full rounded-lg outline-none"
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className={`${
              selectedFilterOption?.id === 1 ? "pt-[120px]" : "pt-[40px]"
            }   pb-8 space-y-4`}
          >
            <Button
              title="Apply filter(s)"
              className="!py-[10px] !w-[full] !rounded-lg !px-12 !bg-[#18AF04] !text-[#fff]"
              onClick={() => {
                setApplyDate(true);
                onApplyDate();
              }}
            />
            <Button
              title="Cancel"
              onClick={onClearFilterDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-[full] !rounded-lg !bg-transparent !border !text-[#464748]"
            />
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default Shipments;
