import React from "react";

interface Props {
  image?: string;
  title?: string;
  content?: string;
}

function EmptyShipmentTable({
  image = "/images/new-request.svg",
  title = "You do not have any shipments yet",
  content = "When you make a shipment, it will show here",
}: Props) {
  return (
    <div className="flex  w-full text-center items-center justify-center py-[72px] ">
      <div className=" flex flex-col justify-center">
        <div className="flex justify-center">
          <img src={image} alt="empty-images" width={60} height={50} />
        </div>
        <p className="font-onestRegular mb-2  text-sm text-[#4A564D]">
          {title}
        </p>
        <p className="font-onestRegular   text-xs text-center text-[#A0ABA3]">
          {content}
        </p>
      </div>
    </div>
  );
}

export default EmptyShipmentTable;
