import { Loader } from "components/Loader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import MenuField from "../MenuField";
import { useNavigate } from "react-router-dom";
import { DropDownSelect } from "components/common/form/Dropdown";
import { Button } from "components/Button";
import { FiEye } from "react-icons/fi";
import { truncate } from "lodash";
import { useGetInvoicePDFQuery } from "services/slices/invoice";

interface Props {
  isInvoiceLoading: boolean;
  handleInputChange: (e: any) => void;
  onToggleInvoiceType: () => void;
  handleInvoiceType: (e: any) => void;
  debounceSearch: string;
  invoiceData: any;
  showInvoice: boolean;
  isInvoiceType: boolean;
  invoiceTitles: any;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setShowFilterMobileModal: React.Dispatch<React.SetStateAction<boolean>>;
  query: string;
  filter: string;
}
const MobileInvoice = ({
  isInvoiceLoading,
  handleInputChange,
  debounceSearch,
  invoiceData,
  showInvoice,
  invoiceTitles,
  onToggleInvoiceType,
  handleInvoiceType,
  setQuery,
  query,
  isInvoiceType,
  filter,
  setShowFilterMobileModal,
}: Props) => {
  const navigate = useNavigate();
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const { data: invoiceDownloadData, isLoading: isDownloadingInvoice } =
  useGetInvoicePDFQuery(
    {
      id: id || "",
    },
    {
      skip: !isDownload,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );
  const triggerDownload = () => {
    if (!isDownloadingInvoice) {
      setIsDownload(true);
      setTimeout(() => {
        setIsDownload(false);
      }, 2000);
    }
  };
  useEffect(() => {
    if (invoiceDownloadData?.data?.pdf) {
      const blob = new Blob(
        [
          Uint8Array.from(atob(invoiceDownloadData.data.pdf), (c) =>
            c.charCodeAt(0)
          ),
        ],
        { type: "application/pdf" }
      );
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "invoice.pdf";
      link.click();
      URL.revokeObjectURL(link.href);
    }
  }, [invoiceDownloadData]);
  const getOptions = (status: string) => {
    switch (status) {
      case "paid":
        return [
          {
            label: "View details",
            value: "VIEW_DETAILS",
            icon: "/images/eye.svg",
          },
          {
            label: "Download",
            value: "DOWNLOAD",
            icon: "/images/Download.svg",
          },
        ];
      default:
        return [
          {
            label: "Pay",
            value: "PAY",
            icon: "/images/pay-icon.svg",
          },
          {
            label: "View details",
            value: "VIEW_DETAILS",
            icon: "/images/eye.svg",
          },
          {
            label: "Download",
            value: "DOWNLOAD",
            icon: "/images/Download.svg",
          },
        ];
    }
  };

  return (
    <div className="mt-7 sm:mt-[72px] rounded-lg max-w-[38rem] block px-5 py-6 mb-5 md:hidden  sm:mx-auto border-[1px] border-[#E1F0DF] bg-dashboard-gradient">
      <div className="flex justify-between items-center">
        <h1 className="text-[#0A5001] font-[900] text-xl md:text-2xl font-onestBold">
          Invoices
        </h1>
      </div>
      <div className="mt-6 pb-6">
        <DropDownSelect
          title=""
          lists={invoiceTitles}
          value={query}
          setQuery={setQuery}
          isOpen={isInvoiceType}
          toggleDropdown={onToggleInvoiceType}
          handleOptionClick={handleInvoiceType}
          placeholder="Select invoice"
          name="invoiceType"
        />
      </div>
      <div className="flex gap-3 mt-3 mb-3">
        <div className="w-[80%] bg-[#fff] flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5]">
          <img
            className="w-[14px] h-[14px]"
            src="/images/search-icon.svg"
            alt="search-icon"
          />
          <input
            className="text-[13px] py-[10px] w-[279px] outline-none"
            type="text"
            placeholder="Search by invoice ID"
            onChange={(e) => {
              handleInputChange(e);
            }}
            value={debounceSearch}
          />
        </div>
        <div
          className="relative"
          onClick={() => {
            setShowFilterMobileModal(true);
          }}
        >
          <div className="flex items-center gap-3 bg-white border-[1px] rounded-[9px] px-4 py-3 cursor-pointer border-[#E4EBF5]">
            <img
              className=" w-full flex shrink-0 "
              src="/images/filter-mobile.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="">
        {isInvoiceLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div>
            {invoiceData?.data?.data?.length > 0 ? (
              <>
                {invoiceData?.data?.data?.map((item: any) => {
                  return (
                    <div className="bg-[#fff] my-3" key={item?.id}>
                      <div className=" border-[1px] flex rounded-lg p-3 justify-between pb-5 border-[#E4EBF5]">
                        <div>
                          <p className="text-sm xs:block hidden text-[#071227] mt-2 font-[700]">
                            Invoice: {item?.invoiceNumber}
                          </p>
                          <p className="text-sm xs:hidden block text-[#071227] mt-2 font-[700]">
                            Invoice:{" "}
                            {truncate(item?.invoiceNumber, { length: 11 })}
                          </p>
                          <p className="pt-1 text-[13px] xs:block hidden text-[#071227] mb-2">
                            {moment(item?.startDate).format("DD MMM, YYYY")} -
                            {moment(item?.endDate).format("DD MMM, YYYY")}
                          </p>
                          <p className="pt-1 text-[13px] xs:hidden block text-[#071227] mb-2">
                            {moment(item?.startDate).format("DD MMM, YYYY")}
                          </p>
                          <div
                            className={`text-sm w-fit text-[#65513F] ${
                              item.status === "paid"
                                ? "bg-[#D4F3D5] text-[#289D17]"
                                : item.status === "partly_paid"
                                ? "bg-[#F8E4D1] text-[#65513F]"
                                : "bg-[#F8D1D1] text-[#E64500]"
                            } px-2 py-1 rounded-md font-[500] font-onestMedium`}
                          >
                            {item.status === "partly_paid"
                              ? "Partially Paid"
                              : item.status === "paid"
                              ? "Paid"
                              : "Unpaid"}
                          </div>
                        </div>

                        {showInvoice ? (
                          <div className="">
                            <MenuField
                              showInvoice
                              onClick={(x: string) => {
                                if (x === "VIEW_DETAILS") {
                                  navigate(
                                    `/dashboard/invoice/${item?.invoiceNumber}`
                                  );
                                  return;
                                }
                                if (x === "DOWNLOAD") {
                                  setId(item?.invoiceNumber);
                                  triggerDownload();
                                  return;
                                }
                                if (x === "PAY") {
                                  navigate(
                                    `/dashboard/invoice/${item?.invoiceNumber}/pay`
                                  );
                                  return;
                                }
                              }}
                              options={getOptions(item.status)}
                            >
                              <div className="align-bottom mt-1 ">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 18L12 18.01M12 12L12 12.01M12 6L12 6.01M12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13ZM12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19ZM12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7Z"
                                    stroke="#5C6881"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </MenuField>
                          </div>
                        ) : (
                          <Button
                            icon={<FiEye className="text-[#45C734]" />}
                            title={"View"}
                            iconLeft={true}
                            onClick={() =>
                              navigate(
                                `/dashboard/invoice/${item?.invoiceNumber}`
                              )
                            }
                            className="!pl-2 !bg-transparent !text-[#45C734] !pr-2 !pt-1 !pb-1 !rounded-[4px]"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex justify-center py-[32px] w-full">
                <div className="">
                  <div className="flex justify-center pb-5 w-full">
                    {" "}
                    <img src="/images/new-request.svg" alt="" />
                  </div>
                  <p className="font-onestRegular mb-2 bmd:w-full text-sm text-[#4A564D]">
                    You do not have any invoice for {filter}
                  </p>
                  <p className="font-onestRegular w-[100%] bmd:w-full text-xs text-center text-[#A0ABA3]">
                    When there's invoice, it will show here
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileInvoice;
