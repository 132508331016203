import { Button } from "components/Button";
import NotificationModal from "components/NotificationModal";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetNotificationsQuery,
  useGetUnReadNotificationsQuery,
  useNotificationViewedMutation,
} from "services/slices/notification";
import { navTitle } from "variables/sidebarLinks";
import { TbMathGreater } from "react-icons/tb";
import moment from "moment";
import { Loader } from "components/Loader";
import { useGetAllOrdersMutation } from "services/slices/shipNow";
import { handleErrorToast } from "helpers/toastMessage";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import ModalContainer from "components/Modal";
import { debouncedFunction } from "helpers/debouncedSearch";
interface INav {
  onClick: any;
}
interface User {
  userID: string | null;
  "Full Name": string | null;
  Username: string | null;
}

const TopNav = ({ onClick }: INav) => {
  const now = moment();
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);
  const [routeId, setRouteId] = useState<string>("");
  const [selectedPart, setSelectedPart] = useState<string>("");
  const location = useLocation();
  const [fetchNotifications, setFetchNotifications] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [debounceSearch, setDebounceSearch] = useState<string>("");
  const [modal, setModal] = useState<string>("");

  const pathParts = location.pathname.split("/").filter(Boolean);

  useEffect(() => {
    if (pathParts.length === 1) {
      setSelectedPart(pathParts[0]);
      setRouteId("");
    } else if (pathParts.length === 2) {
      setSelectedPart(pathParts[1]);
      setRouteId("");
    } else {
      setSelectedPart(pathParts[1]);
      setRouteId(pathParts[2]);
    }
  },[pathParts]);

  // Find the matched title
  const matchedTitle = navTitle.find((item) => {
    if (item.link.includes(":")) {
      const [basePath] = item.link.split(":");
      return selectedPart === basePath;
    }
    return selectedPart === item.link;
  });

  const usersDataString = localStorage.getItem("fez-user");
  const token = localStorage.getItem("token");
  const userData: User | null =
    usersDataString && usersDataString !== "undefined"
      ? JSON.parse(usersDataString)
      : null;

  const id = userData?.userID;
  const [getAllOrders] = useGetAllOrdersMutation();
  const {
    data: notificationData,
    isLoading,
    refetch: refetchNotification,
  } = useGetNotificationsQuery({ id }, { skip: !fetchNotifications });

  const { data: notificationCount, refetch } = useGetUnReadNotificationsQuery({
    id,
  });
  const [notificationViewed, ] =
    useNotificationViewedMutation();

  const unReadCount = notificationCount?.unReadCount;
  const checkNotificationData = notificationData?.notifications?.data;

  const handleNavigation = (item: any) => {
    switch (item.action) {
      case "ticket.message":
        navigate(`/dashboard/ticket`);
        break;
      case "request.accept":
        navigate(`/dashboard/shipments`);
        break;
      case "wallet.topup":
        navigate(`/dashboard/wallet`);
        break;
      case "wallet.update":
        navigate(`/dashboard/wallet`);
        break;
      default:
        console.log("Unknown action:", item.action);
    }
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (fetchNotifications) {
      refetchNotification();
    }
  }, [fetchNotifications, refetchNotification]);

  const onChangeNotificationViewed = async (id: number) => {
    await notificationViewed({ id })
      .unwrap()
      .then((data: any) => {
        refetch();
      })
      .catch((e) => {
        console.error("What is the error");
      });
  };
 
  const debouncedSetSearch = useCallback((value:string) => {
    debouncedFunction(value, setSearch);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearch(e.target.value);
    setDebounceSearch(e.target.value);
  };

  const getOrders = useCallback(async () => {
    if (!search) {
      return;
    }

    const filters = {
      startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDate: moment().add(1, "days").format("YYYY-MM-DD"),
      page: 1,
      orderStatus: "",
      recipientPhone: "",
      recipientEmail: "",
      recipientName: "",
      orderNo: search,
      requestType: "",
      disable: "sDate",
    };

    if (token) {
      await getAllOrders(filters)
        .unwrap()
        .then((data: any) => {
          if (data?.orders?.data.length === 0) {
            toast.error("No data exists with this search", {
              className: "toast-error",
              progressClassName: "Toastify__progress-bar--error",
            });
          } else if (data?.orders?.data.length > 0) {
            navigate(`/dashboard/shipments?search=${search}`);
            setSearch("");
            setDebounceSearch("");
          }
        })
        .catch((e) => {
          handleErrorToast(e);
        });
    }
  }, [search, token, getAllOrders, navigate]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getOrders();
    }
  };

  useEffect(() => {
    setModal(localStorage.getItem("set-modal") || "");
  }, [localStorage]);

  useEffect(() => {
    // Define the date range using moment
    // 1st of November to 28th
    const start = moment([now.year(), 11, 1]);
    const end = moment([now.year(), 11, 28, 23, 59, 59]);

    if (now.isBetween(start, end, undefined, "[]")) {
      localStorage.setItem("set-modal", "yes");
    }
  }, [now]);

  useEffect(() => {
    // 29th of November to 1st of december
    const start = moment([now.year(), 10, 29]);
    const end = moment([now.year(), 11, 1, 23, 59, 59]);

    if (now.isBetween(start, end, undefined, "[]")) {
      localStorage.setItem("set-modal-two", "yes");
    }
  }, [now]);

  return (
    <div className="">
      <div className="border-b-[2px] h-[75px] border-[#E4EBF5] px-4 md:px-8   bg-[#fff] py-4 sticky top-0 z-10">
        <div className="flex justify-between items-center">
          <div className="w-[600px] flex justify-between items-center">
            <div className=" w-[40%]">
              <img src="/images/FezLogo.svg" alt="fez-logo" className="" />
            </div>
            <div className=" items-start w-[46%] whitespace-nowrap text-[16px] font-onestMedium  md:flex hidden  space-x-2">
              <p className=" ">{matchedTitle?.title}</p>
              {routeId !== "" && (
                <p className="flex items-center space-x-2">
                  {" "}
                  <span className="mr-2">
                    <TbMathGreater />{" "}
                  </span>
                  <span className="text-[#289D17] ">{routeId}</span>
                </p>
              )}
            </div>
          </div>
          <div className="hidden bmd:flex gap-8 items-center">
            <div className=" w-[279px] flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] ">
              <img
                className="w-[14px] h-[14px]"
                src="/images/search-icon.svg"
                alt="search-icon"
              />
              <input
                className="text-[13px] py-[10px] w-[279px] outline-none"
                type="text"
                value={debounceSearch}
                placeholder="Search by order ID"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e)
                }
                onKeyDown={handleKeyDown}
              />
            </div>
            <div
              onClick={() => {
                setFetchNotifications(true);
                setOpen(!open);
              }}
              className="relative cursor-pointer"
            >
              <img src="/images/notification.svg" alt="notification-icon" />
              {unReadCount > 0 && (
                <small className="bg-[#F64108] text-[10px]  px-1 text-white absolute -top-0.5 -right-1 rounded-[5px]">
                  {unReadCount}
                </small>
              )}
            </div>
          </div>
          <div className="bmd:hidden flex items-center gap-2 mr-2">
            <img
              onClick={onClick}
              className="block"
              src="/images/menu.svg"
              alt=""
            />
            <div
              onClick={() => {
                setOpen(!open);
                setFetchNotifications(true);
              }}
              className="relative shrink-0 cursor-pointer mr-5"
            >
              <img src="/images/notification.svg" alt="notification-icon" />
              {unReadCount > 0 && (
                <small className="bg-[#F64108] text-[10px]  px-1 text-white absolute -top-1 -right-1 rounded-[5px]">
                  {unReadCount}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationModal
        open={open}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[572px]"
      >
        <div className="w-full px-6 h-[100vh] pb-8 bg-white flex flex-col relative">
          <div className="flex gap-4 items-center mt-6 pb-3 border-b border-[#E4EBF5]">
            <p className="text-lg  font-onestBold text-[#0A5001] ">
              Notifications
            </p>
            {unReadCount?.length > 0 && (
              <div className="flex">
                {" "}
                <Button
                  title={`${unReadCount} New`}
                  className="!bg-[#F64108] !px-[8px] !py-1 !rounded-[4px]"
                />
              </div>
            )}
          </div>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => {
              setFetchNotifications(false);
              setOpen(false);
            }}
            src="/images/close.svg"
            alt=""
          />
          {isLoading ? (
            <div className="h-[80vh] py-20">
              <Loader />
            </div>
          ) : (
            <div className="overflow-y-auto h-[80vh]">
              {notificationData?.notifications?.data?.length > 0 && (
                <>
                  {notificationData?.notifications?.data?.map((item: any) => {
                    return (
                      <div
                        key={item.id}
                        onClick={() => {
                          setOpen(!open);
                          onChangeNotificationViewed(item.id);
                          handleNavigation(item);
                          setFetchNotifications(false);
                        }}
                        className={`  ${
                          item.clicked === 1 ? "bg-[#fff]" : ""
                        } md:flex md:flex-row justify-between gap-3 py-6 border-b-[1px] cursor-pointer items-center border-[#e4ebf5]`}
                      >
                        <div className="flex gap-4 sm:w-[80%] items-center">
                          <img src="/images/notify-mark.svg" alt="" />
                          <div>
                            <h1
                              className={`${
                                item.clicked === 1
                                  ? "text-[#b8b8b8]"
                                  : "text-[#161718]"
                              } text-base font-onestBold font-[600]  tracking-wide`}
                            >
                              {item.message}
                            </h1>
                          </div>
                        </div>
                        <p
                          className={`${
                            item.clicked === 1
                              ? "text-[#b8b8b8]"
                              : "text-[#929DAE]"
                          }  font-[500] sm:w-[20%] ml-14 mt-3 md:mt-0 md:ml-0 font-onestMedium text-sm`}
                        >
                          {moment(item?.created_at).format("MMM DD, YYYY")}
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          )}

          <div className="flex flex-col mt-14">
            <Button
              disabled={checkNotificationData?.length === 0}
              title="See all notifications"
              onClick={() => {
                navigate("/dashboard/notification");
                setOpen(false);
                setFetchNotifications(false);
              }}
              className={`${
                checkNotificationData?.length > 0
                  ? "!bg-transparent border-[1px] border-[#289D17] !text-[#464748]"
                  : "bg-[#e6e8e6] text-[#fff]"
              } w-full py-3 rounded-lg !font-[500] !text-base`}
            />
          </div>
        </div>
      </NotificationModal>
      <ModalContainer
        // open={modal === "yes"}
        open={false}
        showCloseIcon={false}
        noPadding
        tailwindClassName="max-w-[864px]"
      >
        <div className="w-full flex md:flex-row flex-col  rounded-[12px]">
          <div className="w-full md:w-[50%] relative">
            <div
              className="justify-end md:hidden absolute cursor-pointer left-0 w-full top-1 right-3 flex"
              onClick={() => {
                localStorage.setItem("set-modal", "no");
                setModal("no");
              }}
            >
              <div className="h-6 w-6 mb-2 rounded-full bg-[#F2F2F2] flex justify-center items-center">
                <IoClose className="text-[#9CA6A2] text-lg" />
              </div>
            </div>
            <img
              src="/images/Banner1.svg"
              alt="mobile-banner"
              className="rounded-tl-[12px] md:hidden block object-cover  md:rounded-tr-0 rounded-tr-[12px]  w-full rounded-bl-0 md:rounded-bl-[12px]"
            />
            <img
              src="/images/Banner.svg"
              alt="web-banner"
              className="rounded-tl-[12px]   md:block hidden object-cover    w-full rounded-bl-0 md:rounded-bl-[12px]"
            />
          </div>

          <div className="flex flex-col md:mb-0 mb-5 w-full md:w-[50%] space-y-3 px-4 pt-4">
            <div>
              <div
                className="justify-end md:flex hidden cursor-pointer"
                onClick={() => {
                  localStorage.setItem("set-modal", "no");
                  setModal("no");
                }}
              >
                <div className="h-6 w-6 mb-2 rounded-full bg-[#F2F2F2] flex justify-center items-center">
                  <IoClose className="text-[#9CA6A2] text-lg" />
                </div>
              </div>
              <p className="text-lg leading-[28px] font-onestBold text-[#00243D] text-center">
              Holiday Shipping
              </p>
              <p className="text-lg leading-[28px] font-onestBold text-[#00243D] text-center">
                Just Got Better!
              </p>
            </div>
            <p className="text-sm  text-center font-onestRegular text-[#00243D]">
            Get 10% off on all local and import deliveries this December with the code  <span className="font-onestBold">FEZEMBER10</span>{" "}
          
            </p>
            <div className="space-y-2.5">
              <Button
                title={`Ship Now`}
                onClick={() => {
                  localStorage.setItem("set-modal", "no");
                  setModal("no");
                  navigate(`/dashboard/ship-now`);
                }}
                className="!py-2 !rounded-[4px]"
              />
              <Button
                onClick={() =>
                  (window.location.href = "https://www.fezdelivery.co/festive-discounts")
                }
                title={`Learn More`}
                className="!py-2 !bg-transparent !text-[#00243D] border border-[#D0D7E2] !rounded-[4px]"
              />
              <p className="md:text-xs text-[12px] text-[#4D6677] -pt-2 text-center">
              Offer valid from 1st - 31st of December
              </p>
            </div>
          </div>
        </div>
      </ModalContainer>
    
    </div>
  );
};

export default TopNav;
