import { Button } from "components/Button";
import { DropDownSelect } from "components/common/form/Dropdown";
import { FiEye } from "react-icons/fi";
import ModalContainer from "components/Modal";
import DatePicker from "react-datepicker";
import React from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { filterTerms } from "constant/data";
import PagePagination from "components/Pagination";
import { TableLoader } from "components/TableLoader";
import { formatMoney } from "helpers/formatMoney";
import EmptyShipmentTable from "pages/dashboard/shipment/EmptyShipmentTable";

interface Props {
  filterType: any;
  selectedTransactionPeriod: string;
  onTransactionApplyDate: () => void;
  onClearTransactionFilterDate: () => void;
  handleTransactionEndDateChange: (date: Date | null) => void;
  transactionFormattedEndDate: Date | null;
  handleTransactionDateChange: (date: Date | null) => void;
  handleTransactionDateRangeType: (option: any) => void;
  transactionFormattedDate: Date | null;
  toggleTransactionDateRangeType: any;
  isTransactionDateRangeType: boolean;
  setSelectedTransactionPeriod: React.Dispatch<React.SetStateAction<string>>;
  showTransactionFilterModal: boolean;
  filterTransactionQuery: any;
  isFilterTransactionType: boolean;
  toggleTransactionFilterType: any;
  debounceTransactionSearch: string;
  handleTransactionFilterType: (option: any) => void;
  handleTransactionInputChange: (e: any) => void;
  setTransactionPage: React.Dispatch<React.SetStateAction<number>>;
  transactionPage: number;
  transactionData: any;
  isTransactionLoading: boolean;
  setShowTransactionFilterModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFilterTransactionQuery: React.Dispatch<React.SetStateAction<any>>;
}

const PaymentHistory = ({
  handleTransactionDateChange,
  toggleTransactionDateRangeType,
  handleTransactionDateRangeType,
  transactionFormattedDate,
  onTransactionApplyDate,
  selectedTransactionPeriod,
  onClearTransactionFilterDate,
  transactionFormattedEndDate,
  filterType,
  handleTransactionEndDateChange,
  isTransactionDateRangeType,
  setSelectedTransactionPeriod,
  showTransactionFilterModal,
  filterTransactionQuery,
  isFilterTransactionType,
  toggleTransactionFilterType,
  handleTransactionFilterType,
  setTransactionPage,
  transactionPage,
  setFilterTransactionQuery,
  setShowTransactionFilterModal,
  handleTransactionInputChange,
  debounceTransactionSearch,
  transactionData,
  isTransactionLoading,
}: Props) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className=" md:overflow-x-auto bg-[#F9F9FB] rounded-[8px] border-[#E4EBF5] border pb-[50px] h-fit p-4 mt-10">
        <div className="flex gap-3">
          <div className="w-full mb-4 md:w-[279px] bg-white flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] ">
            <img
              className="w-[14px] h-[14px]"
              src="/images/search-icon.svg"
              alt="search-icon"
            />
            <input
              className="text-[13px] py-[10px] w-[250px] outline-none"
              type="text"
              placeholder="Search by Invoice ID"
              onChange={(e) => {
                handleTransactionInputChange(e);
              }}
              value={debounceTransactionSearch}
            />
          </div>
          <div className="flex items-center gap-1 bg-white border-[1px] rounded-[9px] h-fit px-4 py-2.5  border-[#E4EBF5]">
            <div
              className="relative "
              onClick={() => {
                setShowTransactionFilterModal(true);
              }}
            >
              <div className="items-center flex gap-1  rounded-[9px]  cursor-pointer">
                <img className="" src="/images/filter.svg" alt="" />
                <p className="text-[13px] text-[#929DAE] w-fit px-2 outline-none">
                  Filter by:{" "}
                  {[
                    selectedTransactionPeriod !== "custom"
                      ? `${selectedTransactionPeriod}`
                      : "",
                    filterTransactionQuery?.name !== ""
                      ? filterTransactionQuery?.name
                      : "",
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </p>
              </div>
            </div>
            <span
              onClick={onClearTransactionFilterDate}
              className="cursor-pointer"
            >
              <MdOutlineCancel className="text-[#7e7f80]" />
            </span>
          </div>
        </div>
        {isTransactionLoading ? (
          <TableLoader rowLength={17} />
        ) : (
          <>
            {transactionData?.data?.data?.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="hidden md:table w-full whitespace-nowrap text-left p-4 border-spacing-2 rounded-2xl md:w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                  <thead className="bg-white !mx-12">
                    <tr className="border-b-[1px]   border-[#E4EBF5]">
                    
                      <th className="py-4 text-left pl-4 pr-6 text-xs text-[#929DAE] font-onestRegular">
                        Client ID
                      </th>
                      <th className="py-4 text-left pl-5 pr-6 text-xs text-[#929DAE] font-onestRegular">
                        Invoice ID
                      </th>

                      <th className="py-4 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                        No of orders
                      </th>
                      <th className="py-4 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                        Total cost of order
                      </th>
                      <th className="py-4 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                        VAT
                      </th>
                      <th className="py-4 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                        Total amount
                      </th>
                      <th className="py-4 text-left  text-xs  pl-4 pr-4 text-[#929DAE] font-onestRegular">
                        Amount paid
                      </th>
                      <th className="py-4 text-left  text-xs z-[0]  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                        Payment status
                      </th>

                      <th className="sticky  z-[10]  right-0  text-xs text-[#929DAE] font-onestRegular">
                        <div className="pl-4 border-l py-5 bg-white">
                          Action
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionData?.data?.data?.map((transaction: any) => (
                      <tr
                        key={transaction.id}
                        className="bg-[#fff] py-8 border-b-[1px] font-onestRegular border-[#E4EBF5]"
                      >
                       
                        <td className="py-4 pl-4  pr-6 text-sm text-[#0E1728] ">
                          {transaction?.invoice?.clientId}
                        </td>
                        <td className="pl-4 pr-6 text-sm text-[#0E1728]">
                          {transaction?.invoiceNumber}
                        </td>
                        <td className="py-3 pl-6 w-[50px] pr-6 text-sm text-[#0E1728] font-onestRegular">
                          {formatMoney().format(
                            transaction?.invoice?.orderCount
                          )}
                        </td>
                        <td className="text-sm pl-4 pr-10">
                          <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                            ₦
                            {formatMoney().format(
                              transaction?.invoice?.amount
                            )}
                          </span>
                        </td>
                        <td className="text-sm pl-4 pr-10">
                          <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                            ₦{formatMoney().format(transaction?.invoice?.vat)}
                          </span>
                        </td>
                        <td className="text-sm pl-4 pr-10">
                          <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                            ₦{formatMoney().format(transaction?.invoice?.totalAmount)}
                          </span>
                        </td>
                        <td className="text-sm pl-4 pr-10">
                          <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                            ₦
                            {formatMoney().format(
                              transaction?.invoice?.paidAmount
                            )}
                          </span>
                        </td>
                        <td className="text-sm pl-4 pr-10">
                          <span
                            className={`text-sm text-[#65513F] ${
                              transaction?.paymentStatus === "paid"
                                ? "bg-[#D4F3D5] text-[#289D17]"
                                : transaction?.paymentStatus === "partly_paid"
                                ? "bg-[#F8E4D1] text-[#65513F]"
                                : "bg-[#F8D1D1] text-[#E64500]"
                            } px-2 py-1 rounded-md font-[500] font-onestMedium`}
                          >
                            {transaction?.paymentStatus === "partly_paid"
                              ? "Partially Paid"
                              : transaction?.paymentStatus === "paid"
                              ? "Paid"
                              : "Unpaid"}
                          </span>
                        </td>

                        <td className="pr-1.5 text-center pl-2 sticky z-[10] border-l  right-0 py-4 flex gap-0.5  justify-center cursor-pointer bg-white">
                          <Button
                            icon={<FiEye className="text-[#45C734]" />}
                            title={"View"}
                            iconLeft={true}
                            onClick={() =>
                          
                              navigate(
                          `/dashboard/invoice/${transaction?.invoice?.invoiceNumber}`
                              )
                            }
                            className="!pl-2 !bg-transparent !text-[#45C734] !pr-2 !pt-1 !pb-1 !rounded-[4px]"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <EmptyShipmentTable
                image="/images/invoice.svg"
                title="You do not have any invoice yet"
                content="When you have invoice, it will show here"
              />
            )}
          </>
        )}
        {transactionData?.data?.data?.length > 0  &&transactionData?.data?.meta?.total > transactionData?.data?.meta?.per_page &&(
          <div className="mt-3">
            <PagePagination
              totalPages={transactionData?.data?.meta?.last_page}
              page={transactionPage}
              handlePageClick={(event) => {
                setTransactionPage(event.selected);
              }}
            />
          </div>
        )}
      </div>
      <ModalContainer
        open={showTransactionFilterModal}
        showCloseIcon={false}
        tailwindClassName={`h-[450px] w-[300px] sm:w-[350px] rounded-md`}
      >
        <div className="p-6 w-full pb-4 h-full">
          <DropDownSelect
            title="Payment status"
            lists={filterType}
            value={filterTransactionQuery?.name}
            setQuery={setFilterTransactionQuery}
            isOpen={isFilterTransactionType}
            toggleDropdown={toggleTransactionFilterType}
            handleOptionClick={handleTransactionFilterType}
            placeholder="Select payment status"
            name="paymentStatus"
          />

          <div className="mt-10">
            <DropDownSelect
              title="Date range"
              lists={filterTerms}
              value={selectedTransactionPeriod}
              setQuery={setSelectedTransactionPeriod}
              isOpen={isTransactionDateRangeType}
              toggleDropdown={toggleTransactionDateRangeType}
              handleOptionClick={handleTransactionDateRangeType}
              placeholder="-Select a date range-"
              name="deliveryType"
            />
          </div>

          {selectedTransactionPeriod === "Custom" && (
            <div className="mt-10">
              <p className="text-sm pb-4 font-onestBold text-[#929DAE] ">
                Custom date range
              </p>
              <div className="grid grid-cols-2 gap-6">
                <div className="">
                  <DatePicker
                    placeholderText={"Start date"}
                    selected={transactionFormattedDate}
                    onChange={handleTransactionDateChange}
                    className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
                  />
                </div>
                <div className="">
                  <DatePicker
                    placeholderText={"End date"}
                    selected={transactionFormattedEndDate}
                    onChange={handleTransactionEndDateChange}
                    className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className={` pt-[110px] flex justify-between gap-3 items-center pb-8 `}
          >
            <Button
              title="Clear"
              onClick={onClearTransactionFilterDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#464748]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12  !text-[#fff]"
              onClick={() => {
                onTransactionApplyDate();
              }}
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default PaymentHistory;
